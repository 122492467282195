import React from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import { Grid } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Editor from "../Editor";
import { newInstrument } from "../../../actions/instrumentActions";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { useEffect } from "react";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const styles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function InstrumentForm() {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [picture, setPicture] = React.useState([]);
  //const [open, setOpen] = React.useState(false);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const instruments = useSelector((state) => state.instruments);
  const dispatch = useDispatch();

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const reset = () => {
    setName("");
    setDescription("");
    setPicture([]);
    setOpenPicture(false);
    setLoading(false);
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.name = name ? "" : "لطفا نام ساز را وارد کنید";
    temp.description = description ? "" : "لطفا توضیحات ساز را وارد کنید";
    temp.picture =
      picture.length > 0
        ? ""
        : setNotify({
            isOpen: true,
            message: "لطفا یک عکس برای این ساز انتخاب کنید",
            type: "error",
          });

    setErrors({ ...temp });
    const errors_ = Object.values(temp).filter((x) => x !== "");
    if (errors_.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormDataa();
    formData.append("picture", JSON.stringify(picture[0]));
    formData.append("name", name);
    formData.append("description", description);
    dispatch(newInstrument(formData));
    setLoading(true);
  };

  useEffect(() => {
    if (instruments.new) {
      instruments.new = false;
      setNotify({
        isOpen: true,
        message: "ساز  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [instruments]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      setLoading(false);
      errors.errors = null;
      let clearError = setTimeout(() => {
        clearTimeout(clearError);
        setErrors({});
      }, 4000);
    }
  }, [errors]);

  const content = (
    <>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="name"
              label="نام ساز"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={_errors.name}
            />
            <Editor
              value={description}
              onEditorChange={(e) => setDescription(e)}
              placeholder="توضیحات مربوط به این ساز"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Controls.UploadImage
              text="انتخاب تصویر"
              name="uploadImage"
              label="آپلود تصویر"
              dialogTitle="آپلود تصویر"
              value={picture}
              //onChange={this.onChange}
              acceptedFiles={["image/*"]}
              fileObjects={picture}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPicture}
              onClose={() => setOpenPicture(!openPicture)}
              onSave={() => setOpenPicture(!openPicture)}
              onClick={() => setOpenPicture(!openPicture)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObj) => {
                setPicture(picture.concat(newFileObj[0]));
              }}
              onDelete={(deleteFileObj) => {
                const index = picture.indexOf(deleteFileObj);
                setPicture(
                  picture.filter((pic) => picture.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
            />
          </Grid>
          <Grid item>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره این ساز اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره این ساز"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
            <Controls.Button
              text="پاک کردن همه مقادیر"
              color="secondary"
              onClick={reset}
              startIcon={<Controls.Icons.DeleteIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
    </>
  );
  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return <>{loading ? _loading : content}</>;
}

export default InstrumentForm;
