import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "../../../components/dashboard related/useForms";
import DeleteIcon from "@material-ui/icons/Delete";
import Controls from "../../../components/dashboard related/controls/Controls";
import { Card, Grid, Paper } from "@material-ui/core";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import ZoomImage from "../../../shared/components/ZoomImage";
import {
  newTextAndHeader,
  newHeader,
  getAllTextAndHeaderPublic,
} from "../../../actions/adminActions";
import Editor from "../Editor";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function TextAndheader() {
  const tags = [
    "لندینگ",
    "آزمون",
    "فستیوال",
    "کارگاه",
    "اساتید",
    "خانه سنتور",
    "دپارتمان",
    "مدیریت",
    "دیپلم",
    "اخبار",
    "گالری",
  ];

  const landingTags = [
    "معرفی آزمون",
    "معرفی کارگاه",
    "معرفی فستیوال",
    "معرفی دیپلم",
  ];

  const departmentTags = [
    "اساتید دپارتمان",
    "سایر فعالیت‌های دپارتمان",
    "آزمون‌های دپارتمان",
    "مدیریت دپارتمان",
    "کارگاه‌های سازشناسی دپارتمان",
    "دوره‌های تربیت مربی دپارتمان",
  ];

  const santourHouseTags = [
    "آثار استاد سهامی",
    "آثار هنرجویان",
    "نت‌های منتشر شده",
  ];

  const [textHeaders, setTextHeaders] = React.useState([]);
  const [slideHeaders, setSlideHeaders] = React.useState([]);
  const [tag, setTag] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [picture, setPicture] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const auth = useSelector((state) => state.auth);
  const _errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTextAndHeaderPublic());
  }, []);

  useEffect(() => {
    if (auth.text_headers) {
      setTextHeaders(auth.text_headers.textHeaders.slice());
      setSlideHeaders(auth.text_headers.slideHeaders.slice());
      auth.text_headers = null;
      setLoading(false);
    }

    if (auth.added) {
      auth.added = false;
      setNotify({
        isOpen: true,
        message: "با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (_errors.errors) {
      setErrors(_errors.errors);
      let err =
        typeof _errors.errors.data.error !== "undefined"
          ? _errors.errors.data.error
          : _errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      _errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [_errors]);

  const onContentStateChange = (content) => {
    setDescription(content);
  };

  const reset = () => {
    setErrors({});
    setTag("");
    setOpen(false);
    setLoading(false);
    setPicture([]);
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
  };

  const setOpenDialog = () => {
    setOpen(!open);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const formData = new FormData();
    formData.append("tag", tag);
    formData.append("description", description);
    if (tag === "لندینگ" || tag === "خانه سنتور") {
      formData.append("pictures", JSON.stringify(picture));
      if (
        slideHeaders.filter((t) => t.tag === tag)[0] &&
        slideHeaders.filter((t) => t.tag === tag)[0].pictures.length !== 0
      ) {
        formData.append(
          "currentPictures",
          JSON.stringify(slideHeaders.filter((t) => t.tag === tag)[0].pictures)
        );
      } else {
        formData.append("currentPictures", "empty");
      }

      dispatch(newHeader(formData));
    } else {
      formData.append("picture", JSON.stringify(picture));
      if (
        textHeaders.filter((t) => t.tag === tag)[0] &&
        textHeaders.filter((t) => t.tag === tag)[0].picture
      ) {
        formData.append(
          "currentPicture",
          JSON.stringify(textHeaders.filter((t) => t.tag === tag)[0].picture)
        );
      } else {
        formData.append("currentPictures", "empty");
      }
      dispatch(newTextAndHeader(formData));
    }

    setLoading(true);
  };

  const validate = () => {
    let temp = {};
    temp.tag = tag ? "" : "لطفا صفحه را انتخاب کنید";
    temp.description = description
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن توضیحات را وارد کنید",
          type: "error",
        });
    if (tag === "لندینگ" || tag === "خانه سنتور") {
      temp.picture =
        picture.length > 0 ||
        slideHeaders.filter((t) => t.tag === tag)[0].pictures.length > 0
          ? ""
          : setNotify({
              isOpen: true,
              message: "لطفا  تصاویر هدر را برای این صفحه انتخاب کنید",
              type: "error",
            });
    } else {
      temp.picture =
        picture.length > 0 ||
        (textHeaders.filter((t) => t.tag === tag).length !== 0 &&
          textHeaders.filter((t) => t.tag === tag)[0].picture)
          ? ""
          : setNotify({
              isOpen: true,
              message: "لطفا  یک تصویر هدر برای این صفحه انتخاب کنید",
              type: "error",
            });
    }

    setErrors({ ...temp });

    const _errors = Object.values(temp).filter((x) => x !== "");
    if (_errors.length === 0) {
      return true;
    }
    return false;
  };

  const setValues = (tag) => {
    try {
      let description = "";
      if (tag === "لندینگ" || tag === "خانه سنتور") {
        description = slideHeaders.filter((t) => t.tag === tag)[0].description;
      } else {
        description = textHeaders.filter((t) => t.tag === tag)[0].description;
      }
      setDescription(description);
    } catch (error) {
      setDescription("");
    }
  };

  const classes = useStyles();

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  const content = (
    <>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: "8px",
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            <Controls.SelectInstrument
              name="tag"
              label="انتخاب صفحه اصلی"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setPicture([]);
                setValues(e.target.value);
              }}
              options={tags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="صفحات مربوط به خانه سنتور"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setPicture([]);
                setValues(e.target.value);
              }}
              options={santourHouseTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="بخش‌های مختلف صفحه لندینگ"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setPicture([]);
                setValues(e.target.value);
              }}
              options={landingTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="بخش‌های مختلف صفحه دپارتمان"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setPicture([]);
                setValues(e.target.value);
              }}
              options={departmentTags}
              error={errors.tag}
            />
          </Grid>

          {tag.length !== 0 &&
            (tag === "لندینگ" || tag === "خانه سنتور" ? (
              <>
                {slideHeaders.filter((t) => t.tag === tag)[0] && (
                  <>
                    <div style={{ marginTop: "64px" }} />
                    {slideHeaders
                      .filter((t) => t.tag === tag)[0]
                      .pictures.map((x, i) => (
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          md={2}
                          key={i}
                          style={{ marginRight: "4px" }}
                        >
                          <ZoomImage alt="" src={x.address} gallery={true} />
                          <Controls.ActionButton
                            onClick={() => {
                              let pics = [
                                ...slideHeaders.filter((t) => t.tag === tag)[0]
                                  .pictures,
                              ];
                              pics.splice(i, 1);
                              slideHeaders
                                .filter((t) => t.tag === tag)[0]
                                .pictures.splice(i, 1);
                              setSlideHeaders(slideHeaders.slice());
                            }}
                          >
                            <DeleteIcon color="secondary" />
                          </Controls.ActionButton>
                        </Grid>
                      ))}
                    <div style={{ marginBottom: "64px" }} />
                  </>
                )}
              </>
            ) : (
              <>
                {textHeaders.filter((t) => t.tag === tag)[0] &&
                  textHeaders.filter((t) => t.tag === tag)[0].picture && (
                    <>
                      <Grid item xs={4} sm={4} md={4} />
                      <Grid item xs={4} sm={4} md={4}>
                        <Card
                          style={{ marginBottom: "64px", marginTop: "64px" }}
                        >
                          <ZoomImage
                            alt=""
                            src={
                              textHeaders.filter((t) => t.tag === tag)[0]
                                .picture.address
                            }
                            gallery={true}
                          />
                          <Controls.ActionButton
                            onClick={() => {
                              textHeaders.filter(
                                (t) => t.tag === tag
                              )[0].picture = null;
                              setTextHeaders(textHeaders.slice());
                            }}
                          >
                            <DeleteIcon color="secondary" />
                          </Controls.ActionButton>
                        </Card>
                      </Grid>
                    </>
                  )}
              </>
            ))}

          <Grid item xs={12} md={10}>
            <Editor value={description} onEditorChange={onContentStateChange} />
          </Grid>

          <Grid item xs={12} md={10}>
            <div style={{ marginBottom: "128px" }} />
          </Grid>
          <Grid item xs={12} md={10}>
            {tag === "لندینگ" || tag === "خانه سنتور" ? (
              <Controls.UploadImage
                text="انتخاب تصویر هدر"
                name="uploadImage"
                label="آپلود تصویر هدر"
                dialogTitle="آپلود تصویر هدر"
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
                acceptedFiles={["image/*"]}
                fileObjects={picture}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={open}
                onClose={() => setOpenDialog(!open)}
                onSave={() => setOpenDialog(!open)}
                onClick={() => setOpenDialog(!open)}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  setPicture(() => picture.concat(newFileObj));
                }}
                onDelete={(deleteFileObj) => {
                  const index = picture.indexOf(deleteFileObj);
                  setPicture(() =>
                    picture.filter((pic) => picture.indexOf(pic) !== index)
                  );
                }}
                filesLimit={10}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
            ) : (
              <Controls.UploadImage
                text="انتخاب تصویر هدر"
                name="uploadImage"
                label="آپلود تصویر هدر"
                dialogTitle="آپلود تصویر هدر"
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
                acceptedFiles={["image/*"]}
                fileObjects={picture}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={open}
                onClose={() => setOpenDialog(!open)}
                onSave={() => setOpenDialog(!open)}
                onClick={() => setOpenDialog(!open)}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  setPicture(() => picture.concat(newFileObj[0]));
                }}
                onDelete={(deleteFileObj) => {
                  const index = picture.indexOf(deleteFileObj);
                  setPicture(() =>
                    picture.filter((pic) => picture.indexOf(pic) !== index)
                  );
                }}
                filesLimit={1}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
            )}
          </Grid>

          <Grid item>
            <Controls.Button
              onClick={() => {
                if (validate()) {
                  setConfirmDialog({
                    isOpen: true,
                    title: "آیا از ذخیره تغییرات اطمینان دارید؟",
                    subTitle: "",
                  });
                }
              }}
              text="ذخیره تغییرات"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
            <Controls.Button
              text="پاک کردن همه مقادیر"
              color="secondary"
              onClick={reset}
              startIcon={<Controls.Icons.DeleteIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirm={onSubmit}
      />
    </>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      <Paper className={classes.paper}>{loading ? _loading : content}</Paper>
    </Grid>
  );
}

export default TextAndheader;
