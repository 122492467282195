import types from "../actions/types";

const initialState = {
  managers: [],
  new: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_MANAGERS:
      return {
        ...state,
        managers: action.payload,
      };
    case types.DELETE_A_MANAGER:
      return {
        ...state,
        deleteAnItem: true,
        _id: action.payload,
      };
    case types.NEW_MANAGER:
      return {
        ...state,
        new: true,
      };
    default:
      return state;
  }
}
