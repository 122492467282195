import React, { useEffect } from "react";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import { getAllInstruments } from "../../../actions/instrumentActions";
import { editAzInstrument, getAzmoonById } from "../../../actions/azActions";
import { useLocation } from "react-router-dom";

const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function EditAzmoonConcert() {
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [santourHouse, setSantourHouse] = React.useState(false);
  const [department, setDepartment] = React.useState(false);
  const [_id, setId] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [videoClip, setVideoClip] = React.useState("");
  const [instrument, setInstrument] = React.useState("");
  const [instrumentsArray, setInstrumentsArray] = React.useState([]);
  const [topStudents, setTopStudents] = React.useState([]);
  const [newTopStudents, setNewTopStudents] = React.useState([]);
  const [results, setResults] = React.useState([]);
  const [newResults, setNewResults] = React.useState([]);
  const [poster, setPoster] = React.useState([]);
  const [newPoster, setNewPoster] = React.useState([]);
  const [pictures, setPictures] = React.useState([]);
  const [newPictures, setNewPictures] = React.useState([]);
  const [openPictures, setOpenPictures] = React.useState([]);
  const [_openPictures, set_OpenPictures] = React.useState([]);
  const [openNewPictures, setOpenNewPictures] = React.useState(false);
  const [openNewResults, setOpenNewResults] = React.useState(false);
  const [openNewPoster, setOpenNewPoster] = React.useState(false);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const azAll = useSelector((state) => state.azAll);
  const instruments = useSelector((state) => state.instruments);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(getAllInstruments());
    let number = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    setNumber(number);
    let id = location.pathname.split("/");
    id = id[3];
    setId(id);
    dispatch(getAzmoonById({ azmoon_id: id }));
  }, []);

  useEffect(() => {
    if (instruments.instruments) {
      const arrayOfInstruments = instruments.instruments.slice();
      const arrayOfNames = [];
      for (let i = 0; i < arrayOfInstruments.length; i++) {
        arrayOfNames.push(arrayOfInstruments[i].name);
      }
      setInstrumentsArray(arrayOfNames);
    }
  }, [instruments]);

  useEffect(() => {
    if (azAll.azmoon) {
      const item = azAll.azmoon.items[number];

      setInstrument(item.instrument);
      setVideoClip(item.videoClip);
      setSantourHouse(item.santourHouse);
      setDepartment(item.department);

      var _pictures = [];
      var _results = [];
      var _topStudents = [];
      var _poster = [];
      _poster.push(item.poster);
      setPoster(_poster);
      for (let i = 0; i < item.pictures.length; i++) {
        const pic = { address: item.pictures[i].address };
        _pictures.push(pic);
      }
      if (_pictures.length !== 0) setPictures(_pictures);
      for (let i = 0; i < item.results.length; i++) {
        const pic = { address: item.results[i].address };
        _results.push(pic);
      }
      if (_results.length !== 0) setResults(_results);
      for (let i = 0; i < item.topStudents.length; i++) {
        var pictureArray = [];
        // pictureArray.push(item.topStudents[i].picture);
        item.topStudents[i].newPicture = [...pictureArray];
        _topStudents.push(item.topStudents[i]);
      }
      if (_topStudents.length !== 0) setTopStudents(_topStudents);
      azAll.azmoon = null;
      setLoading(false);
    }

    if (azAll.addedNewItem) {
      azAll.addedNewItem = false;
      setNotify({
        isOpen: true,
        message: "آزمون-کنسرت  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [azAll]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let errorTimeout = setTimeout(() => {
        setErrors({});
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.instrument = instrument ? "" : "لطفا ساز آزمون-کنسرت را وارد کنید";
    temp.videoClip = videoClip ? "" : "لطفا آدرس  فیلم این آزمون را وارد کنید";
    temp.poster =
      poster.length === 0 && newPoster.length === 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک عکس به عنوان پوستر این آزمون-کنسرت انتخاب کنید",
            type: "error",
          })
        : "";
    setErrors({ ...temp });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();

    formData.append("instrument", instrument);
    formData.append("videoClip", videoClip);
    formData.append(
      "poster",
      newPoster.length > 0 ? JSON.stringify(newPoster[0]) : "empty"
    );
    formData.append(
      "currentPoster",
      poster.length > 0 ? JSON.stringify(poster[0]) : "empty"
    );
    formData.append(
      "topStudents",
      topStudents.length > 0 ? JSON.stringify(topStudents) : "empty"
    );
    formData.append(
      "newTopStudents",
      newTopStudents.length > 0 ? JSON.stringify(newTopStudents) : "empty"
    );
    if (newPictures.length > 0) {
      for (let i = 0; i < newPictures.length; i++) {
        formData.append("newPictures", JSON.stringify(newPictures[i]));
      }
    } else {
      formData.append("newPictures", "empty");
    }

    formData.append(
      "currentPictures",
      pictures.length > 0 ? JSON.stringify(pictures) : "empty"
    );
    if (newResults.length > 0) {
      for (let i = 0; i < newResults.length; i++) {
        formData.append("newResults", JSON.stringify(newResults[i]));
      }
    } else {
      formData.append("newResults", "empty");
    }
    formData.append(
      "currentResults",
      results.length > 0 ? JSON.stringify(results) : "empty"
    );
    santourHouse
      ? formData.append("santourHouse", "santourHouse")
      : formData.append("santourHouse", "other");

    department
      ? formData.append("department", "department")
      : formData.append("department", "other");
    formData.append("number", number);
    formData.append("azmoon_id", _id);
    dispatch(editAzInstrument(formData));
    //reset();
    setLoading(true);
  };

  const handleInputChange = (e, index, target) => {
    const { value } = e.target;
    const list = [...topStudents];
    target === "name"
      ? (list[index].name = value)
      : (list[index].videoUrl = value);
    setTopStudents(list);
  };

  const _handleInputChange = (e, index, target) => {
    const { value } = e.target;
    const list = [...newTopStudents];
    target === "name"
      ? (list[index].name = value)
      : (list[index].videoUrl = value);
    setNewTopStudents(list);
  };

  const handleRemoveClick = (index) => {
    if (topStudents.length > 0) {
      const list = [...topStudents];
      list.splice(index, 1);
      setTopStudents(list);
    }
  };

  const _handleRemoveClick = (index) => {
    if (newTopStudents.length > 0) {
      const list = [...newTopStudents];
      list.splice(index, 1);
      setNewTopStudents(list);
    }
  };

  const handleAddClick = () => {
    setNewTopStudents([
      ...newTopStudents,
      { name: "", videoUrl: "", picture: [] },
    ]);
  };

  // const setOpenPicture = (i) => {
  //   const list = [...openPictures];
  //   list[i] = !list[i];
  //   setOpenPictures(list);
  // };

  const classes = useStyles();

  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
              }}
            >
              {`آزمون-کنسرت ساز ${instrument}`}
            </h1>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              پوستر
            </h3>
          </Grid>
          {poster.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(poster[0].address);
                      setOpen(true);
                    }}
                    src={poster[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setPoster([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Controls.UploadImage
              text="انتخاب پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر جدید"
              dialogTitle="آپلود پوستر جدید"
              value={newPoster}
              acceptedFiles={["image/*"]}
              fileObjects={newPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openNewPoster}
              onClose={() => setOpenNewPoster(!openNewPoster)}
              onSave={() => setOpenNewPoster(!openNewPoster)}
              onClick={() => setOpenNewPoster(!openNewPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPoster(newPoster.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPoster.indexOf(deleteFileObj);
                setNewPoster(
                  newPoster.filter((pic) => newPoster.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              اطلاعات کلی
            </h3>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="videoClip"
              label="ویدئو این کنسرت"
              value={videoClip}
              onChange={(e) => setVideoClip(e.target.value)}
              error={_errors.videoClip}
            />
            {instrumentsArray.length !== 0 && (
              <Controls.SelectInstrument
                name="instrument"
                label="انتخاب ساز"
                value={instrument}
                onChange={(e) => setInstrument(e.target.value)}
                options={instrumentsArray}
                error={_errors.instrument}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={department}
                  checked={santourHouse}
                  onChange={() => setSantourHouse(!santourHouse)}
                  name="santourHouse"
                />
              }
              label="از کنسرت‌های خانه سنتور"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={santourHouse}
                  checked={department}
                  onChange={() => setDepartment(!department)}
                  name="department"
                />
              }
              label="از کنسرت‌های دپارتمان کودک و نوجوان"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              هنرجویان برتر
            </h3>
          </Grid>
          {topStudents.length !== 0 &&
            topStudents.map((x, i) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: "64px" }}
                key={i}
              >
                {" "}
                <Grid item xs={12} sm={12} md={10}>
                  <Controls.Input
                    variant="outlined"
                    name="topStudents"
                    label="نام هنرجو برتر"
                    value={x.name}
                    onChange={(e) => handleInputChange(e, i, "name")}
                    error={errors.topStudents}
                  />
                  <Controls.Input
                    variant="outlined"
                    name="topStudents"
                    label="آدرس ویدئو اجرای هنرجو برتر(در صورت وجود)"
                    value={x.videoUrl}
                    onChange={(e) => handleInputChange(e, i, "videoUrl")}
                    //error={errors.topStudents[i]}
                  />
                </Grid>
                {topStudents[i].picture && (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    style={{ marginRight: "8px" }}
                  >
                    <Card>
                      <Image
                        onClick={() => {
                          setAddress(topStudents[i].picture.address);
                          setOpen(true);
                        }}
                        src={topStudents[i].picture.address}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          const list = [...topStudents];
                          list[i].picture.address = "";
                          setTopStudents(list);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </Controls.ActionButton>
                    </Card>
                  </Grid>
                )}
                <Grid item xs={12} md={10}>
                  <Controls.UploadImage
                    text="انتخاب تصویر هنرجو"
                    name="uploadImage"
                    label="آپلود تصویر هنرجو"
                    dialogTitle="آپلود تصویر هنرجو"
                    value={topStudents[i].newPicture}
                    //onChange={onChange}
                    acceptedFiles={["image/*"]}
                    fileObjects={topStudents[i].newPicture}
                    cancelButtonText="انصراف"
                    submitButtonText="تایید"
                    maxFileSize={50000000}
                    open={openPictures[i]}
                    onClose={() => {
                      const list = [...openPictures];
                      list[i] = !list[i];
                      setOpenPictures(list);
                    }}
                    onSave={() => {
                      const list = [...openPictures];
                      list[i] = !list[i];
                      setOpenPictures(list);
                    }}
                    onClick={() => {
                      const list = [...openPictures];
                      list[i] = !list[i];
                      setOpenPictures(list);
                    }}
                    startIcon={<Controls.Icons.CloudUploadIcon />}
                    onAdd={(newFileObjs) => {
                      var list = [...topStudents];
                      list[i].newPicture.push(newFileObjs[0]);
                      setTopStudents(list);
                    }}
                    onDelete={(deleteFileObj) => {
                      const index =
                        topStudents[i].newPicture.indexOf(deleteFileObj);
                      var list = [...topStudents];
                      list[i].newPicture = list[i].newPicture.filter(
                        (pic) => list[i].newPicture.indexOf(pic) !== index
                      );
                      setTopStudents(list);
                    }}
                    filesLimit={1}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    clearOnUnmount={false}
                    //error={errors.topStudents[i]}
                  />
                  {topStudents.length > 0 && (
                    <Controls.Button
                      text="حذف"
                      color="secondary"
                      onClick={() => handleRemoveClick(i)}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          {newTopStudents.length !== 0 &&
            newTopStudents.map((x, i) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: "64px" }}
                key={i}
              >
                {" "}
                <Grid item xs={12} sm={12} md={10}>
                  <Controls.Input
                    variant="outlined"
                    name="newTopStudents"
                    label="نام هنرجو برتر"
                    value={x.name}
                    onChange={(e) => _handleInputChange(e, i, "name")}
                    error={errors.newTopStudents}
                  />
                  <Controls.Input
                    variant="outlined"
                    name="topStudents"
                    label="آدرس ویدئو اجرای هنرجو برتر(در صورت وجود)"
                    value={x.videoUrl}
                    onChange={(e) => _handleInputChange(e, i, "videoUrl")}
                    //error={errors.topStudents[i]}
                  />
                </Grid>
                {newTopStudents[i].picture.address && (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    style={{ marginRight: "8px" }}
                  >
                    <Card>
                      <Image
                        onClick={() => {
                          setAddress(newTopStudents[i].picture.address);
                          setOpen(true);
                        }}
                        src={newTopStudents[i].picture.address}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          const list = [...newTopStudents];
                          list[i].picture.address = "";
                          setNewTopStudents(list);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </Controls.ActionButton>
                    </Card>
                  </Grid>
                )}
                <Grid item xs={12} md={10}>
                  <Controls.UploadImage
                    text="انتخاب تصویر هنرجو"
                    name="uploadImage"
                    label="آپلود تصویر هنرجو"
                    dialogTitle="آپلود تصویر هنرجو"
                    value={newTopStudents[i].picture}
                    //onChange={onChange}
                    acceptedFiles={["image/*"]}
                    fileObjects={newTopStudents[i].picture}
                    cancelButtonText="انصراف"
                    submitButtonText="تایید"
                    maxFileSize={50000000}
                    open={_openPictures[i]}
                    onClose={() => {
                      const list = [..._openPictures];
                      list[i] = !list[i];
                      set_OpenPictures(list);
                    }}
                    onSave={() => {
                      const list = [..._openPictures];
                      list[i] = !list[i];
                      set_OpenPictures(list);
                    }}
                    onClick={() => {
                      const list = [..._openPictures];
                      list[i] = !list[i];
                      set_OpenPictures(list);
                    }}
                    startIcon={<Controls.Icons.CloudUploadIcon />}
                    onAdd={(newFileObjs) => {
                      var list = [...newTopStudents];
                      list[i].picture.push(newFileObjs[0]);
                      setNewTopStudents(list);
                    }}
                    onDelete={(deleteFileObj) => {
                      const index =
                        newTopStudents[i].picture.indexOf(deleteFileObj);
                      var list = [...newTopStudents];
                      list[i].picture = list[i].picture.filter(
                        (pic) => list[i].picture.indexOf(pic) !== index
                      );
                      setNewTopStudents(list);
                    }}
                    filesLimit={1}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    clearOnUnmount={false}
                    //error={errors.newTopStudents[i]}
                  />
                  {newTopStudents.length > 0 && (
                    <Controls.Button
                      text="حذف"
                      color="secondary"
                      onClick={() => _handleRemoveClick(i)}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12} md={10}>
            <Tooltip
              title="اضافه کردن یک هنرجوی برتر "
              aria-label="add"
              onClick={handleAddClick}
              placement="top"
            >
              <Fab color="primary">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              تصاویر
            </h3>
          </Grid>
          {pictures.length !== 0 && (
            <>
              {pictures.map((x, i) => (
                <Grid
                  item
                  xs={2}
                  sm={1}
                  md={1}
                  style={{ marginRight: "8px" }}
                  key={i}
                >
                  <Card>
                    <Image
                      onClick={() => {
                        setAddress(x.address);
                        setOpen(true);
                      }}
                      src={x.address}
                    />
                    <Controls.ActionButton
                      onClick={() => {
                        if (pictures.length > 0) {
                          const list = [...pictures];
                          list.splice(i, 1);
                          setPictures(list);
                        }
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Card>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12} md={12}>
            <Controls.UploadImage
              text="انتخاب تصاویر جدید"
              name="newPictures"
              label="آپلود تصاویر جدید"
              dialogTitle="آپلود تصاویر جدید"
              value={newPictures}
              acceptedFiles={["image/*"]}
              fileObjects={newPictures}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openNewPictures}
              onClose={() => setOpenNewPictures(!openNewPictures)}
              onSave={() => setOpenNewPictures(!openNewPictures)}
              onClick={() => setOpenNewPictures(!openNewPictures)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPictures(newPictures.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPictures.indexOf(deleteFileObj);
                setNewPictures(
                  newPictures.filter(
                    (pic) => newPictures.indexOf(pic) !== index
                  )
                );
              }}
              filesLimit={100}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              error={errors.newPictures}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              نتایج
            </h3>
          </Grid>
          {results.length !== 0 && (
            <>
              {results.map((x, i) => (
                <Grid
                  item
                  xs={2}
                  sm={1}
                  md={1}
                  style={{ marginRight: "8px" }}
                  key={i}
                >
                  <Card>
                    <Image
                      onClick={() => {
                        setAddress(x.address);
                        setOpen(true);
                      }}
                      src={x.address}
                    />
                    <Controls.ActionButton
                      onClick={() => {
                        if (results.length > 0) {
                          const list = [...results];
                          list.splice(i, 1);
                          setResults(list);
                        }
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Card>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12} md={12}>
            <Controls.UploadImage
              text="انتخاب نتایج جدید"
              name="newResults"
              label="آپلود نتایج جدید"
              dialogTitle="آپلود نتایج جدید"
              value={newResults}
              acceptedFiles={["image/*"]}
              fileObjects={newResults}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openNewResults}
              onClose={() => setOpenNewResults(!openNewResults)}
              onSave={() => setOpenNewResults(!openNewResults)}
              onClick={() => setOpenNewResults(!openNewResults)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewResults(newResults.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newResults.indexOf(deleteFileObj);
                setNewResults(
                  newResults.filter((pic) => newResults.indexOf(pic) !== index)
                );
              }}
              filesLimit={100}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "128px" }}>
            <Tooltip
              title="تایید"
              aria-label="add"
              onClick={(e) =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا این آزمون-کنسرت ذخیره شود؟",
                  subTitle: "",
                })
              }
              placement="top"
            >
              <Fab color="primary" className={classes.sendButton}>
                <SendIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default EditAzmoonConcert;
