import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store from "./store";
import DashboardMain from "./pages/dashboard/DashboardMain";
import Login from "./pages/login/Login";
import MainPage from "./pages/MainPage";
import "react-multi-carousel/lib/styles.css";
import "./App.css";
import AOS from "aos";
AOS.init({
  disable: false,
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 200,
  delay: 50,
  duration: 1000,
  easing: "ease",
  once: true,
  mirror: false,
  anchorPlacement: "top-bottom",
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: "Vazir",
  },
  shape: {
    borderRadius: 10,
  },
});

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route path="/admin-dashboard">
              <DashboardMain />
            </Route>
            <Route path="/admin-login">
              <Login />
            </Route>
            <Route>
              <MainPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
