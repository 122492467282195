import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ListIcon from "@material-ui/icons/List";

const Icons = {
  DeleteIcon,
  CloudUploadIcon,
  SaveIcon,
  ExpandLess,
  ExpandMore,
  ListItemIcon,
  ListItemText,
  AddBoxIcon,
  ListIcon,
};

export default Icons;
