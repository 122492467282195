import React, { useEffect } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Fab, Grid, Tooltip, Paper } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { editDiploma, getDiplomaPublic } from "../../../actions/diplomaActions";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";

const FormData = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function DiplomaEdit() {
  const [imageId, setImageId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openDiploma, setOpenDiploma] = React.useState(false);
  const [pictures, setPictures] = React.useState([]);
  const [diplomas, setDiplomas] = React.useState([]);
  const [newPictures, setNewPictures] = React.useState([]);
  const [newDiplomas, setNewDiplomas] = React.useState([]);
  const [videos, setVideos] = React.useState([""]);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [openDiplomaPicture, setOpenDiplomaPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const diplomaReducer = useSelector((state) => state.diplomaReducer);
  const dispatch = useDispatch();

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const reset = () => {
    setNewDiplomas([]);
    setNewPictures([]);
    setVideos([""]);
    setOpenPicture(false);
    setOpenDiplomaPicture(false);
    setLoading(false);
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.pictures =
      !pictures.length > 0 && !diplomas.length > 0 && !videos.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا حداقل برای یک فیلد مقداری را انتخاب کنید",
            type: "error",
          })
        : "";

    setErrors({ ...temp });
    const errors_ = Object.values(temp).filter((x) => x !== "");
    if (errors_.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();
    formData.append(
      "pictures",
      newPictures.length > 0 ? JSON.stringify(newPictures) : []
    );
    formData.append(
      "diplomas",
      newDiplomas.length > 0 ? JSON.stringify(newDiplomas) : []
    );
    formData.append(
      "videoClips",
      videos.length > 0 && videos[0].length !== 0 ? JSON.stringify(videos) : []
    );
    formData.append(
      "currentPictures",
      pictures.length > 0 ? JSON.stringify(pictures) : []
    );
    formData.append(
      "currentDiplomas",
      diplomas.length > 0 ? JSON.stringify(diplomas) : []
    );
    dispatch(editDiploma(formData));
    reset();
    setLoading(true);
  };

  useEffect(() => {
    dispatch(getDiplomaPublic());
  }, []);

  useEffect(() => {
    if (diplomaReducer.diploma) {
      const diploma = diplomaReducer.diploma;
      var _pictures = [];
      var _diplomas = [];
      var _videos = [];

      for (let i = 0; i < diploma.pictures.length; i++) {
        const pic = { address: diploma.pictures[i].address };
        _pictures.push(pic);
      }
      setPictures(_pictures);
      for (let i = 0; i < diploma.diplomas.length; i++) {
        const pic = { address: diploma.diplomas[i].address };
        _diplomas.push(pic);
      }
      setDiplomas(_diplomas);
      for (let i = 0; i < diploma.videoClips.length; i++) {
        _videos.push(diploma.videoClips[i]);
      }
      if (_videos.length !== 0) setVideos(_videos);
      diplomaReducer.diploma = null;
      setLoading(false);
    }

    if (diplomaReducer.new) {
      setNotify({
        isOpen: true,
        message: "اطلاعات  با موفقیت ذخیره شد",
        type: "success",
      });
      diplomaReducer.new = false;
      setLoading(false);
    }
  }, [diplomaReducer]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let errorTimeout = setTimeout(() => {
        setErrors({});
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }, [errors]);

  const classes = useStyles();

  const content = (
    <>
      <Paper className={classes.paper}>
        <Form
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              {videos.length !== 0 &&
                videos.map((x, i) => (
                  <React.Fragment key={i}>
                    <Controls.Input
                      variant="outlined"
                      name="videos"
                      label="آدرس آپارات ویدئو"
                      value={x}
                      onChange={(e) => {
                        const { value } = e.target;
                        const list = [...videos];
                        list[i] = value;
                        setVideos(list);
                      }}
                      error={_errors.videos}
                    />
                    {videos.length > 0 && (
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          if (videos.length > 0) {
                            const list = [...videos];
                            list.splice(i, 1);
                            setVideos(list);
                          }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    )}
                  </React.Fragment>
                ))}
              <Grid>
                <Tooltip
                  title="اضافه کردن یک ویدئو جدید"
                  aria-label="add"
                  onClick={() => {
                    setVideos([...videos, ""]);
                  }}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>

            {pictures.length !== 0 && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "GrayText",
                      fontSize: "20px",
                      direction: "rtl",
                      textAlign: "center",
                    }}
                  >
                    تصاویر
                  </h1>
                  <br />
                </Grid>
                {pictures.map((x, i) => (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    style={{ marginRight: "8px" }}
                    key={i}
                  >
                    <Card>
                      <Image
                        onClick={() => {
                          setImageId(i);
                          setOpen(true);
                        }}
                        src={x.address}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          if (pictures.length > 0) {
                            const list = [...pictures];
                            list.splice(i, 1);
                            setPictures(list);
                          }
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </Controls.ActionButton>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12} md={12}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            {diplomas.length !== 0 && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "GrayText",
                      fontSize: "20px",
                      direction: "rtl",
                      textAlign: "center",
                    }}
                  >
                    دیپلم‌ها
                  </h1>
                  <br />
                </Grid>
                {diplomas.map((x, i) => (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    style={{ marginRight: "8px" }}
                    key={i}
                  >
                    <Card>
                      <Image
                        onClick={() => {
                          setImageId(i);
                          setOpenDiploma(true);
                        }}
                        src={x.address}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          if (diplomas.length > 0) {
                            const list = [...diplomas];
                            list.splice(i, 1);
                            setDiplomas(list);
                          }
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </Controls.ActionButton>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12} md={10}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.UploadImage
                text="اضافه کردن تصویر جدید"
                name="uploadImage"
                label="آپلود تصاویر"
                dialogTitle="آپلود تصاویر"
                value={newPictures}
                //onChange={(e) => {}}
                acceptedFiles={["image/*"]}
                fileObjects={newPictures}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={openPicture}
                onClose={() => setOpenPicture(!openPicture)}
                onSave={() => setOpenPicture(!openPicture)}
                onClick={() => setOpenPicture(!openPicture)}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  setNewPictures(newPictures.concat(newFileObj));
                }}
                onDelete={(deleteFileObj) => {
                  const index = newPictures.indexOf(deleteFileObj);
                  setNewPictures(
                    newPictures.filter(
                      (pic) => newPictures.indexOf(pic) !== index
                    )
                  );
                }}
                filesLimit={100}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
              <Controls.UploadImage
                text="اضافه کردن تصویر دیپلم جدید"
                name="uploadDiplomas"
                label="آپلود تصاویر دیپلم‌ها"
                dialogTitle="آپلود تصاویر دیپلم‌ها"
                value={newDiplomas}
                //onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={newDiplomas}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={openDiplomaPicture}
                onClose={() => setOpenDiplomaPicture(!openDiplomaPicture)}
                onSave={() => setOpenDiplomaPicture(!openDiplomaPicture)}
                onClick={() => setOpenDiplomaPicture(!openDiplomaPicture)}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  setNewDiplomas(newDiplomas.concat(newFileObj));
                }}
                onDelete={(deleteFileObj) => {
                  const index = newDiplomas.indexOf(deleteFileObj);
                  setNewDiplomas(
                    newDiplomas.filter(
                      (pic) => newDiplomas.indexOf(pic) !== index
                    )
                  );
                }}
                filesLimit={100}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
            </Grid>
            <Grid item>
              <Controls.Button
                onClick={() =>
                  setConfirmDialogue({
                    isOpen: true,
                    title: "آیا از ذخیره اطلاعات اطمینان دارید؟",
                    subTitle: "",
                  })
                }
                text="ذخیره اطلاعات"
                color="secondary"
                startIcon={<Controls.Icons.SaveIcon />}
              />
            </Grid>
          </Grid>
        </Form>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialogue
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialogue}
          onConfirm={onSubmit}
        />
        {open && (
          <Lightbox
            medium={pictures[imageId].address}
            onClose={() => setOpen(false)}
          />
        )}
        {openDiploma && (
          <Lightbox
            medium={diplomas[imageId].address}
            onClose={() => setOpenDiploma(false)}
          />
        )}
      </Paper>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default DiplomaEdit;
