import React from "react";
import { Switch, Route } from "react-router-dom";
import Appbar from "./Homepage/Sections/AppBar/AppBar";
import Footer from "../components/Main Website/Footer/Footer";
import Links from "./Homepage/Sections/AppBar/Links";
import HomePage from "./Homepage/Homepage";
import AzConcert from "./Az Concert/AzConcert";
import Festivals from "./Festival/Festivals";
import Instruments from "./Instrument/Instruments";
import Instructor from "./Instructor/Instructor";
import InstituteManager from "./Management/InstituteManager";
import DepartmentManager from "./Management/DepartmentManager";
import SantourHouse from "./SantourHouse/Santourhouse";
import SahamiWorks from "./SantourHouse/SahamiWorks";
import StudentsWorks from "./SantourHouse/StudentsWorks";
import Notes from "./SantourHouse/Notes";
import Department from "./Department/Department";
import AzConcerts from "./Department/AzConcerts";
import AzConcertInstruments from "./Department/AzConcertInstruments";
import Diploma from "./Diploma/Diploma";
import AllDiplomas from "./Diploma/AllDiplomas";
import InstructorItem from "./Instructor/InstructorItem";
import InstrumentInstructors from "./Instructor/instrumentInstructors";
import Workshops from "./Workshop/Workshops";
import News from "./News/News";
import WorkshopItem from "./Workshop/WorkshopItem";
import FestivalItem from "./Festival/FestivalItem";
import NewsItemm from "./News/NewsItemm";
import AzConcertInstrument from "./Az Concert/AzConcertInstrument";
import AzConcertItem from "./Az Concert/AzConcertItem";
import Gallery from "./Gallery/Gallery";

export default function MainPage(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { ...rest } = props;
  return (
    <div>
      <Appbar
        color="white"
        brand={"آموزشگاه موسیقی نو"}
        rightLinks={
          <Links
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        {...rest}
      />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/آزمون" component={AzConcert} />
        <Route exact path="/کارگاه ها/:type" component={Workshops} />
        <Route exact path="/فستیوال" component={Festivals} />
        <Route exact path="/سازها" component={Instruments} />
        <Route exact path="/اساتید" component={Instructor} />
        <Route exact path="/مدیر آموزشگاه" component={InstituteManager} />
        <Route exact path="/مدیر دپارتمان" component={DepartmentManager} />
        <Route exact path="/خانه سنتور" component={SantourHouse} />
        <Route exact path="/آثار-استاد-سهامی" component={SahamiWorks} />
        <Route exact path="/آثار-هنرجویان-برتر" component={StudentsWorks} />
        <Route exact path="/نت ها" component={Notes} />
        <Route exact path="/دپارتمان" component={Department} />
        <Route exact path="/دپارتمان/آزمون" component={AzConcerts} />
        <Route exact path="/دیپلم" component={Diploma} />
        <Route exact path="/اخبار" component={News} />
        <Route exact path="/دیپلم/همه" component={AllDiplomas} />
        <Route exact path="/آزمون/ساز/:id" component={AzConcertInstrument} />
        <Route
          exact
          path="/آزمون/دپارتمان/ساز/:id"
          component={AzConcertInstruments}
        />
        <Route exact path="/آزمون/ساز/:id/:number" component={AzConcertItem} />
        <Route exact path="/اساتید/:id" component={InstructorItem} />
        <Route exact path="/کارگاه/:id" component={WorkshopItem} />
        <Route exact path="/فستیوال/:id" component={FestivalItem} />
        <Route exact path="/اخبار/:id" component={NewsItemm} />
        <Route
          exact
          path="/اساتید/سازها/:name"
          component={InstrumentInstructors}
        />
        <Route exact path="/گالری" component={Gallery} />
      </Switch>
      <Footer />
    </div>
  );
}
