import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const newManager = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/managers/new-manager`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_MANAGER,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editManager = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/managers/edit-manager`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_MANAGER,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllManagers = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/managers/get-all-managers`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const managers = result.data;
      dispatch({
        type: types.GET_ALL_MANAGERS,
        payload: managers,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllManagersPublic = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/managers/get-all-managers-public`)
    .then((result) => {
      const managers = result.data;
      dispatch({
        type: types.GET_ALL_MANAGERS,
        payload: managers,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteAManager = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/managers/delete-a-manager`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_A_MANAGER,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};
