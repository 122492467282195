import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
//Actions
import {
  getSantourHouse,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import Image from "material-ui-image";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import noteImage from "../../images/note3.jpg";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";
import { Button, CardContent, CardMedia, Typography } from "@material-ui/core";
import JsFileDownloader from "js-file-downloader";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

export default function Notes() {
  const [loading, setLoading] = React.useState(true);
  const [notes, setNotes] = React.useState([]);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(9);
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getAllTitlesPublic());
    dispatch(getTextAndHeaderPublic({ tag: "نت‌های منتشر شده" }));
    dispatch(getSantourHouse());
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      setDescription(textHeader.description);
      auth.text_header = null;
    }

    if (auth.santourHouse) {
      let santourHouse = auth.santourHouse;
      auth.santourHouse = null;

      if (santourHouse.notes) {
        if (santourHouse.notes.files) {
          let files = [];
          files = santourHouse.notes.files.slice();
          setNotes(files);
        }
      }
      //setHeaderUrl(santourHouse.notes.poster.address);
      setLoading(false);
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  const classes = useStyles();
  const content = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر بخش نت‌های منتشر شده")
              .length !== 0 && (
              <Typography variant="h4" className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر بخش نت‌های منتشر شده")[0]
                    .title
                }
              </Typography>
            )}
            <br />
          </GridItem>
          <GridItem GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {notes.length !== 0 &&
            notes
              .slice((page - 1) * itemPerPage, page * itemPerPage)
              .map((x, i) => (
                <GridItem key={i} xs={12} sm={12} md={3} data-aos="zoom-in-up">
                  <Card plain>
                    <CardMedia>
                      <Image
                        src={x.poster ? x.poster.address : noteImage}
                        className={classes.imgRounded}
                      />
                    </CardMedia>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        className={classes.cardTitle}
                      >
                        {x.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.cardTitle}
                      >
                        {x.artist}
                      </Typography>
                    </CardContent>
                    <Button
                      onClick={() => {
                        new JsFileDownloader({ url: x.address });
                      }}
                      color="primary"
                      className={classes.cardTitle}
                    >
                      دانلود
                    </Button>
                  </Card>
                </GridItem>
              ))}

          {notes.length !== 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <Pagination
                shape="rounded"
                count={Math.ceil(notes.length / itemPerPage)}
                page={page}
                size="large"
                className={classes.pagination}
                onChange={(e, page) => setPage(page)}
              />
            </GridItem>
          )}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={classes.section}
      >
        <NotFound />
      </div>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : notes.length === 0 ? notFound : content}
        </div>
      </div>
    </div>
  );
}
