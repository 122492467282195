import React from "react";

import GridContainer from "../../../../components/Main Website/Grid/GridContainer";
import GridItem from "../../../../components/Main Website/Grid/GridItem";
import Card from "../../../../components/Main Website/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Map as LeafMap, Marker, Popup, TileLayer } from "react-leaflet";
import styles from "../../../../assets/jss/views/landingPage";

const useStyles = makeStyles(styles);

const position = [29.621188, 52.48506];

export default function Map() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up">
          <h1 className={classes.title}>مکان آموزشگاه بر روی نقشه</h1>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
          <Card>
            <LeafMap
              attributionControl={false}
              center={position}
              zoom={15}
              className={classes.map}
              id="mapid"
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={position}>
                <Popup>
                  <h3 className={classes.title} style={{ color: "black" }}>
                    آموزشگاه موسیقی نو
                  </h3>
                </Popup>
              </Marker>
            </LeafMap>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
