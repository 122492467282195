import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//Actions
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getDiplomaPublic } from "../../actions/diplomaActions";

//import Footer from "components/Footer/Footer.js";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Carousel from "../../components/common/Carousel";
import VideoSection from "../../components/Main Website/Section/VideoSection";
import MyCarousel from "../../components/Main Website/Carousel/Carousel";
import Loading from "../../components/common/Loading";
import styles from "../../assets/jss/views/landingPage";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import ZoomImage from "../../shared/components/ZoomImage";
import parse from "html-react-parser";
import NotFound from "../../components/common/NotFound";
import { useEffect } from "react";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

function Diploma() {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openDiploma, setOpenDiploma] = React.useState(false);
  const [imageId, setImageId] = React.useState();
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [videos, setVideos] = React.useState([]);
  const [diplomas, setDiplomas] = React.useState([]);
  const [pictures, setPictures] = React.useState([]);
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const diplomaReducer = useSelector((state) => state.diplomaReducer);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getAllTitlesPublic());
    dispatch(getTextAndHeaderPublic({ tag: "دیپلم" }));
    dispatch(getDiplomaPublic());
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      setDescription(textHeader.description);
      auth.text_header = null;
    }
    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  useEffect(() => {
    if (diplomaReducer.diploma) {
      const diploma = diplomaReducer.diploma;
      diplomaReducer.diploma = null;
      var _pictures = [];
      var _diplomas = [];
      var _videos = [];
      if (diploma.pictures) {
        for (let i = 0; i < diploma.pictures.length; i++) {
          _pictures.push(diploma.pictures[i]);
        }
      }
      setPictures(_pictures);
      if (diploma.diplomas) {
        for (let i = 0; i < diploma.diplomas.length; i++) {
          _diplomas.push(diploma.diplomas[i]);
        }
      }
      setDiplomas(_diplomas);
      if (diploma.videoClips) {
        for (let i = 0; i < diploma.videoClips.length; i++) {
          const vid = { videoUrl: diploma.videoClips[i] };
          _videos.push(vid);
        }
      }
      setVideos(_videos);
      setLoading(false);
    }
  }, [diplomaReducer]);

  useEffect(() => {
    if (errors.errors) {
      setLoading(false);
    }
  }, [errors]);

  const classes = useStyles();

  const content = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه دیپلم").length !==
              0 && (
              <h1 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه دیپلم")[0]
                    .title
                }
              </h1>
            )}
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      {pictures.length !== 0 && (
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            {pictures.length >= 3 && (
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
                <Carousel
                  setImageId={setImageId}
                  setOpen={setOpen}
                  pictures={pictures}
                  type={true}
                />
              </GridItem>
            )}
            {pictures.length < 3 &&
              pictures.map((x, i) => (
                <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up" key={i}>
                  <ZoomImage
                    alt=""
                    src={x.address}
                    className={classes.imgRounded}
                    gallery={true}
                  />
                </GridItem>
              ))}
          </GridContainer>
        </div>
      )}
      {diplomas.length !== 0 && (
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
              {titles.filter((t) => t.tag === "زیر تیتر صفحه دیپلم").length !==
                0 && (
                <h2 className={classes.title}>
                  {
                    titles.filter((t) => t.tag === "زیر تیتر صفحه دیپلم")[0]
                      .title
                  }
                </h2>
              )}
              <br />
            </GridItem>
            {diplomas.length >= 3 && (
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
                <Carousel
                  setImageId={setImageId}
                  setOpen={setOpenDiploma}
                  pictures={diplomas}
                  type={true}
                />
              </GridItem>
            )}
            {diplomas.length < 3 &&
              diplomas.map((x, i) => (
                <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up" key={i}>
                  <ZoomImage
                    alt=""
                    src={x.address}
                    className={classes.imgRounded}
                    gallery={true}
                  />
                </GridItem>
              ))}
            <GridItem xs={12} sm={12} md={12} />
            <GridItem xs={5} sm={5} md={3} data-aos="zoom-in-up">
              <Link className={classes.title} to={`/دیپلم/همه`}>
                <Button color="primary"> دیدن همه</Button>
              </Link>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      )}
      {videos.length !== 0 && (
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            {videos.length >= 3 && (
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-down">
                <MyCarousel arrows={true}>
                  {videos.map((x, i) => (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      key={i}
                      data-aos="zoom-in-down"
                    >
                      <VideoSection src={x.videoUrl} />
                    </GridItem>
                  ))}
                </MyCarousel>
              </GridItem>
            )}
            {videos.length < 3 &&
              videos.map((x, i) => (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  key={i}
                  data-aos="zoom-in-down"
                >
                  <VideoSection src={x.videoUrl} />
                </GridItem>
              ))}
          </GridContainer>
        </div>
      )}
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه دیپلم").length !==
              0 && (
              <h1 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه دیپلم")[0]
                    .title
                }
              </h1>
            )}
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={classes.section}
          >
            <NotFound />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading
            ? _loading
            : diplomas.length === 0 &&
              videos.length === 0 &&
              pictures.length === 0
            ? notFound
            : content}
        </div>
      </div>
    </div>
  );
}

export default Diploma;
