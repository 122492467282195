import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { getDiploma } from "../../../actions/diplomaActions";
import Loading from "../../../components/common/Loading";
import Functional from "../AzConcerts/functional";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  pageContent: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1.5),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5),
    },
    padding: theme.spacing(3),
    width: "100%",
  },
  searchInput: {
    width: "75%",
  },
});
const useStyles = makeStyles(styles);

const headCells = [
  { id: "items", label: "تعداد تصاویر", disableSorting: true },
  { id: "picture", label: "تعداد دیپلم‌ها", disableSorting: true },
  { id: "date", label: "تاریخ ساخت", disableSorting: true },
  { id: "actions", label: "تنظیمات", disableSorting: true },
];

function DiplomaList() {
  const [loading, setLoading] = React.useState(true);
  const [diploma, setDiploma] = React.useState([]);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const diplomaReducer = useSelector((state) => state.diplomaReducer);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDiploma());
  }, []);

  useEffect(() => {
    if (diplomaReducer.diploma) {
      const _diploma = [];
      _diploma.push(diplomaReducer.diploma);
      setDiploma(_diploma);
      diplomaReducer.diploma = null;

      setLoading(false);
    }
  }, [diplomaReducer]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const classes = useStyles();

  const content = (
    <>
      <Functional
        records={diploma}
        headCells={headCells}
        pageContent={classes.pageContent}
        openInPopup={() => {}}
        openDialogue={() => {}}
        deleteDialog={{
          isOpen: true,
          title: "",
          subTitle: "",
        }}
        az={false}
        workshop={false}
        festival={false}
        instrument={false}
        diploma={true}
        news={false}
        editPageAddress="/admin-dashboard/edit-diploma"
        nameToEdit="diplomaEdit"
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={() => {}}
        onConfirm={() => {}}
      />
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default DiplomaList;
