import React, { Component } from "react";
//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
//Actions
import { getAllInstructorsPublic } from "../../actions/instructorActions";
import { getAllInstrumentsPublic } from "../../actions/instrumentActions";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import smoothScrollTop from "../../utils/SmoothScrollToTop";
import NotFound from "../../components/common/NotFound";
//Images
import Image from "material-ui-image";
//Styling
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import parse from "html-react-parser";

class Instructor extends Component {
  constructor() {
    super();
    this.state = {
      instruments: [],
      masters: [],
      errors: {},
      description: "",
      headerUrl: "",
      titles: [],
      loading: true,
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    smoothScrollTop();
    this.props.getAllInstructorsPublic();
    this.props.getAllInstrumentsPublic();
    this.props.getAllTitlesPublic();
    this.props.getTextAndHeaderPublic({ tag: "اساتید" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instruments.instruments.length !== 0) {
      this.setState({ instruments: nextProps.instruments.instruments });
      nextProps.instruments.instruments = [];
      this.setState({ loading: false });
    }

    if (nextProps.instructor.instructors.length !== 0) {
      const masters = nextProps.instructor.instructors.filter(
        (ins) => ins.master
      );
      nextProps.instructor.instructors = [];
      this.setState({ masters: masters });
      this.setState({ loading: false });
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }

    if (nextProps.auth.titles) {
      this.setState({
        titles: nextProps.auth.titles.slice(),
      });
      nextProps.auth.titles = null;
    }

    if (nextProps.errors.errors) {
      this.setState({ loading: false });
    }
  }

  onChangePage(e, page) {
    this.setState({ page });
  }

  render() {
    const { classes } = this.props;

    const content = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه اساتید"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه اساتید"
                    )[0].title
                  }
                </h1>
              )}
            </GridItem>
            <GridItem xs={false} sm={false} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={8} data-aos="fade-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
            <GridItem xs={false} sm={false} md={2}></GridItem>{" "}
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "زیر تیتر اول صفحه اساتید"
              ).length !== 0 &&
                this.state.masters.length !== 0 && (
                  <h2 className={classes.title}>
                    {
                      this.state.titles.filter(
                        (t) => t.tag === "زیر تیتر اول صفحه اساتید"
                      )[0].title
                    }
                  </h2>
                )}
            </GridItem>
            {this.state.masters.length !== 0 &&
              this.state.masters.map((x, i) => (
                <GridItem xs={12} sm={12} md={4} key={i} data-aos="fade-up">
                  <NavLink
                    to={`/اساتید/${x._id}`}
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    <Card plain>
                      <CardHeader>
                        <h3
                          className={classes.cardTitle}
                        >{` ${x.name}  -  ${x.instrumentName}`}</h3>
                      </CardHeader>
                      <CardBody>
                        <Image
                          src={x.picture.address}
                          className={classes.imgRounded}
                        />
                      </CardBody>
                    </Card>
                  </NavLink>
                </GridItem>
              ))}
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "زیر تیتر دوم صفحه اساتید"
              ).length !== 0 &&
                this.state.instruments.length !== 0 && (
                  <h2 className={classes.title}>
                    {
                      this.state.titles.filter(
                        (t) => t.tag === "زیر تیتر دوم صفحه اساتید"
                      )[0].title
                    }
                  </h2>
                )}
            </GridItem>
            {this.state.instruments.length !== 0 &&
              this.state.instruments.map((x, i) => (
                <GridItem xs={12} sm={12} md={4} key={i} data-aos="fade-up">
                  <NavLink
                    to={`/اساتید/سازها/${x.name}`}
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    <Card plain>
                      <CardHeader>
                        <h3
                          className={classes.cardTitle}
                        >{`اساتید ساز ${x.name}`}</h3>
                      </CardHeader>
                      <CardBody>
                        <Image
                          src={x.picture.address}
                          className={classes.imgRounded}
                        />
                      </CardBody>
                    </Card>
                  </NavLink>
                </GridItem>
              ))}
          </GridContainer>
        </div>
      </>
    );

    const _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    let notFound = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه اساتید"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه اساتید"
                    )[0].title
                  }
                </h1>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={8} data-aos="fade-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className={classes.section}
        >
          <NotFound />
        </div>
      </>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading
              ? _loading
              : this.state.masters.length === 0 &&
                this.state.instruments.length === 0
              ? notFound
              : content}
          </div>
        </div>
      </div>
    );
  }
}

Instructor.propTypes = {
  errors: PropTypes.object.isRequired,
  instructor: PropTypes.object.isRequired,
  instruments: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getAllInstructorsPublic: PropTypes.func.isRequired,
  getAllInstrumentsPublic: PropTypes.func.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
  getAllTitlesPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  instructor: state.instructor,
  instruments: state.instruments,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllInstructorsPublic,
  getAllInstrumentsPublic,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
})(withStyles(styles)(Instructor));
