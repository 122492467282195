import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { getAdmins, deleteAdmin } from "../../../actions/adminActions";

import Loading from "../../../components/common/Loading";
import Functional from "../AzConcerts/functional";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  pageContent: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1.5),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5),
    },
    padding: theme.spacing(3),
    width: "100%",
  },
  searchInput: {
    width: "75%",
  },
});
const useStyles = makeStyles(styles);

const headCells = [
  { id: "username", label: "نام کاربری", disableSorting: true },
  { id: "role", label: "نقش", disableSorting: true },
  { id: "date", label: "تاریخ ساخت", disableSorting: true },
  { id: "actions", label: "تنظیمات", disableSorting: true },
];

function AdminList() {
  const [loading, setLoading] = React.useState(true);
  const [openPopUp, setOpenPopUp] = React.useState(true);
  const [admins, setAdmins] = React.useState([]);
  const [adminToModify, setAdminToModify] = React.useState("");
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdmins());
  }, []);

  useEffect(() => {
    if (auth.admins) {
      const _admins = auth.admins.slice();
      setAdmins(_admins);
      auth.admins = null;

      setLoading(false);
    }

    if (auth.deleteAdmin) {
      setNotify({
        isOpen: true,
        message: "ادمین با موفقیت حذف شد",
        type: "info",
      });
      auth.deleteAdmin = false;
      deleteFromRecords(auth._id);
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let errorTimeout = setTimeout(() => {
        setErrors({});
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }, [errors]);

  const onDeleteAdmin = (e, recordForEdit) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const data = {
      admin_id: recordForEdit,
    };

    dispatch(deleteAdmin(data));
  };

  const setModifyAdminId = (_id) => {
    setOpenPopUp(!openPopUp);
    setAdminToModify(_id);
  };

  const setOpenDeleteDialogue = (_id, data) => {
    setConfirmDialog(data);
    setAdminToModify(_id);
  };

  const deleteFromRecords = (_id) => {
    var records = admins.filter((record) => record._id !== _id);
    setAdmins(records);
  };

  const classes = useStyles();
  const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"));
  if (currentAdmin) {
  }
  const content = (
    <>
      <Functional
        records={admins}
        headCells={headCells}
        pageContent={classes.pageContent}
        openInPopup={setModifyAdminId}
        openDialogue={setOpenDeleteDialogue}
        deleteDialog={{
          isOpen: true,
          title: "آیا ادمین حذف شود؟",
          subTitle: "امکان بازیابی ادمین وجود ندارد",
        }}
        az={false}
        workshop={false}
        festival={false}
        instrument={false}
        diploma={false}
        news={false}
        admins={true}
        editPageAddress="/admin-dashboard/edit-admin"
        nameToEdit="adminEdit"
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirm={(e) => onDeleteAdmin(e, adminToModify)}
      />
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default AdminList;
