import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const newInstrument = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/instruments/new-instrument`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_INSTRUMENT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editInstrument = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/instruments/edit-instrument`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_INSTRUMENT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllInstruments = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/instruments/get-all-instruments`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const instruments = result.data;
      dispatch({
        type: types.GET_ALL_INSTRUMENTS,
        payload: instruments,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllInstrumentsPublic = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/instruments/get-all-instruments-public`)
    .then((result) => {
      const instruments = result.data;
      dispatch({
        type: types.GET_ALL_INSTRUMENTS,
        payload: instruments,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteInstrument = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/instruments/delete-an-instrument`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_AN_INSTRUMENT,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};
