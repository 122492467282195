import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAWorkshopById } from "../../actions/workshopActions";
//Images
import ZoomImage from "../../shared/components/ZoomImage";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import { useLocation } from "react-router-dom";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

function WorkshopItem() {
  const [item, setItem] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const workshopReducer = useSelector((state) => state.workshopReducer);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    SmoothScrollTop();
    dispatch(getAWorkshopById({ workshop_id: id }));
    dispatch(getAllTitlesPublic());
    dispatch(getTextAndHeaderPublic({ tag: "کارگاه" }));
  }, []);

  useEffect(() => {
    if (workshopReducer.workshop) {
      let workshop = workshopReducer.workshop;
      setItem(workshop);
      workshopReducer.workshop = null;
      setLoading(false);
    }
  }, [workshopReducer]);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  const classes = useStyles();
  let content = item && (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            <h1 className={classes.title}>{item.title}</h1>
            <br />
          </GridItem>

          <GridItem xs={12} sm={12} md={4} data-aos="fade-up">
            <Card>
              <ZoomImage
                alt=""
                gallery={false}
                src={item.poster.address}
                className={classes.imgRounded}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} data-aos="fade-up">
            <div className={classes.sideParagraph}>
              {parse(`${item.description}`)}
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        {item.videoClip.length !== 0 && (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} data-aos="zoom-in-up">
              <Card plain>
                <CardBody>
                  <VideoPlayer src={item.videoClip} style={classes.video} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            {item.pictures.length !== 0 && (
              <>
                <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
                  {titles.filter((t) => t.tag === "زیر تیتر صفحه دوم کارگاه")
                    .length !== 0 && (
                    <h2 className={classes.title}>
                      {
                        titles.filter(
                          (t) => t.tag === "زیر تیتر صفحه دوم کارگاه"
                        )[0].title
                      }
                    </h2>
                  )}
                  <br />
                </GridItem>
                {item.pictures.map((x, i) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    key={i}
                    data-aos="zoom-in-up"
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card>
                      <ZoomImage
                        alt=""
                        src={x.address}
                        className={classes.imgRounded}
                        gallery={true}
                      />
                    </Card>
                  </GridItem>
                ))}
              </>
            )}
          </GridContainer>
        </div>
      </div>
    </>
  );

  let _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax image={headerUrl}></Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>{loading ? _loading : content}</div>
      </div>
    </div>
  );
}

export default WorkshopItem;
