import React, { useEffect } from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
//Actions
import {
  getAllTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAllManagersPublic } from "../../actions/managerActions";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import styles from "../../assets/jss/views/landingPage";
import Carousel from "../../components/Main Website/Carousel/Carousel";
import Section from "../../components/Main Website/Section/Section";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import NotFound from "../../components/common/NotFound";
import parse from "html-react-parser";

import { Lightbox } from "react-modal-image";

const useStyles = makeStyles(styles);

function Department() {
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [manager, setManager] = React.useState();
  const [text_headers, set_text_headers] = React.useState([]);
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const managers = useSelector((state) => state.managers);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getAllTitlesPublic());
    dispatch(getAllTextAndHeaderPublic());
    dispatch(getAllManagersPublic());
  }, []);

  useEffect(() => {
    if (managers.managers.length !== 0) {
      const departmentManager = managers.managers.filter(
        (manager) => manager.position === "Department Manager"
      );
      setManager(departmentManager[0]);
    }
  }, [managers]);

  useEffect(() => {
    if (auth.text_headers) {
      const textHeaders = auth.text_headers.textHeaders.slice();
      console.log({ textHeaders });
      var address = "";
      var description = "";
      if (textHeaders.filter((t) => t.tag === "دپارتمان").length !== 0) {
        address = textHeaders.filter((t) => t.tag === "دپارتمان")[0].picture
          .address;
      }
      if (textHeaders.filter((t) => t.tag === "دپارتمان").length !== 0) {
        description = textHeaders.filter((t) => t.tag === "دپارتمان")[0]
          .description;
      }

      setHeaderUrl(address);
      setDescription(description);
      set_text_headers(textHeaders);
      auth.text_headers = null;
      setLoading(false);
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      setLoading(false);
    }
  }, [errors]);

  const classes = useStyles();
  const content = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه دپارتمان")
              .length !== 0 && (
              <h1 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه دپارتمان")[0]
                    .title
                }
              </h1>
            )}
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={10} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {text_headers.length > 0 &&
            text_headers.filter((t) => t.tag === "کارگاه‌های سازشناسی دپارتمان")
              .length !== 0 &&
            text_headers.filter((t) => t.tag === "دوره‌های تربیت مربی دپارتمان")
              .length !== 0 &&
            text_headers.filter((t) => t.tag === "آزمون‌های دپارتمان")
              .length !== 0 &&
            text_headers.filter((t) => t.tag === "اساتید دپارتمان").length !==
              0 &&
            text_headers.filter((t) => t.tag === "سایر فعالیت‌های دپارتمان")
              .length !== 0 &&
            text_headers.filter((t) => t.tag === "مدیریت دپارتمان").length !==
              0 && (
              <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
                <Carousel arrows={true}>
                  {manager && (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.itemGrid}
                    >
                      <Section
                        title={
                          titles.filter((t) => t.tag === "تیتر بخش مدیریت")
                            .length !== 0
                            ? titles.filter(
                                (t) => t.tag === "تیتر بخش مدیریت"
                              )[0].title
                            : "مدیریت دپارتمان"
                        }
                        link="/مدیر دپارتمان"
                        description={
                          text_headers.filter(
                            (t) => t.tag === "مدیریت دپارتمان"
                          )[0].description
                        }
                        src={
                          text_headers.filter(
                            (t) => t.tag === "مدیریت دپارتمان"
                          )[0].picture.address
                        }
                        onClick={() => {
                          setAddress(
                            text_headers.filter(
                              (t) => t.tag === "مدیریت دپارتمان"
                            )[0].picture.address
                          );
                          setOpen(true);
                        }}
                      />
                    </GridItem>
                  )}

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش کارگاه‌های سازشناسی"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش کارگاه‌های سازشناسی"
                            )[0].title
                          : "کارگاه‌های سازشناسی کودکان"
                      }
                      link="/کارگاه ها/سازشناسی"
                      description={
                        text_headers.filter(
                          (t) => t.tag === "کارگاه‌های سازشناسی دپارتمان"
                        )[0].description
                      }
                      src={
                        text_headers.filter(
                          (t) => t.tag === "کارگاه‌های سازشناسی دپارتمان"
                        )[0].picture.address
                      }
                      onClick={() => {
                        setAddress(
                          text_headers.filter(
                            (t) => t.tag === "کارگاه‌های سازشناسی دپارتمان"
                          )[0].picture.address
                        );
                        setOpen(true);
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش دوره‌های تربیت مربی"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش دوره‌های تربیت مربی"
                            )[0].title
                          : "دوره‌های تربیت مربی موسیقی کودک"
                      }
                      link="/کارگاه ها/مربی"
                      description={
                        text_headers.filter(
                          (t) => t.tag === "دوره‌های تربیت مربی دپارتمان"
                        )[0].description
                      }
                      src={
                        text_headers.filter(
                          (t) => t.tag === "دوره‌های تربیت مربی دپارتمان"
                        )[0].picture.address
                      }
                      onClick={() => {
                        setAddress(
                          text_headers.filter(
                            (t) => t.tag === "دوره‌های تربیت مربی دپارتمان"
                          )[0].picture.address
                        );
                        setOpen(true);
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter((t) => t.tag === "تیتر بخش آزمون‌ها")
                          .length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش آزمون‌ها"
                            )[0].title
                          : "آزمون‌های دپارتمان کودک"
                      }
                      link="/دپارتمان/آزمون"
                      description={
                        text_headers.filter(
                          (t) => t.tag === "آزمون‌های دپارتمان"
                        )[0].description
                      }
                      src={
                        text_headers.filter(
                          (t) => t.tag === "آزمون‌های دپارتمان"
                        )[0].picture.address
                      }
                      onClick={() => {
                        setAddress(
                          text_headers.filter(
                            (t) => t.tag === "آزمون‌های دپارتمان"
                          )[0].picture.address
                        );
                        setOpen(true);
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter((t) => t.tag === "تیتر بخش اساتید")
                          .length !== 0
                          ? titles.filter((t) => t.tag === "تیتر بخش اساتید")[0]
                              .title
                          : "اساتید دپارتمان کودک"
                      }
                      link="/اساتید/سازها/دپارتمان"
                      description={
                        text_headers.filter(
                          (t) => t.tag === "اساتید دپارتمان"
                        )[0].description
                      }
                      src={
                        text_headers.filter(
                          (t) => t.tag === "اساتید دپارتمان"
                        )[0].picture.address
                      }
                      onClick={() => {
                        setAddress(
                          text_headers.filter(
                            (t) => t.tag === "اساتید دپارتمان"
                          )[0].picture.address
                        );
                        setOpen(true);
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش سایر فعالیت‌ها"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش سایر فعالیت‌ها"
                            )[0].title
                          : "سایر فعالیت‌ها دپارتمان کودک"
                      }
                      link="/کارگاه ها/سایر"
                      description={
                        text_headers.filter(
                          (t) => t.tag === "سایر فعالیت‌های دپارتمان"
                        )[0].description
                      }
                      src={
                        text_headers.filter(
                          (t) => t.tag === "سایر فعالیت‌های دپارتمان"
                        )[0].picture.address
                      }
                      onClick={() => {
                        setAddress(
                          text_headers.filter(
                            (t) => t.tag === "سایر فعالیت‌های دپارتمان"
                          )[0].picture.address
                        );
                        setOpen(true);
                      }}
                    />
                  </GridItem>
                </Carousel>
              </GridItem>
            )}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه دپارتمان")
              .length !== 0 && (
              <h1 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه دپارتمان")[0]
                    .title
                }
              </h1>
            )}
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={classes.section}
          >
            <NotFound />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading
            ? _loading
            : text_headers.length === 0 || !manager
            ? notFound
            : content}
        </div>
      </div>
    </div>
  );
}

export default Department;
