import React from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { createAdmin } from "../../../actions/adminActions";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { useEffect } from "react";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const styles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function AdminsForm() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState("activate");
  const [superAdmin, setSuperAdmin] = React.useState(false);
  const [activate, setActivate] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const reset = () => {
    setUsername("");
    setPassword("");
    setSuperAdmin(false);
    setActivate(false);
    setStatus("activate");
    setLoading(false);
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.username = username ? "" : "لطفا نام کاربری را وارد کنید";
    temp.password = password ? "" : "لطفا رمز عبور را وارد کنید";
    if (password.length < 8) {
      temp.password = "طول رمز عبور حداقل باید ۸ کاراکتر باشد";
    } else {
      temp.password = "";
    }
    setErrors({ ...temp });
    const errors_ = Object.values(temp).filter((x) => x !== "");
    if (errors_.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormDataa();
    formData.append("username", username);
    formData.append("password", password);
    if (superAdmin) {
      formData.append("superAdmin", true);
    } else {
      formData.append("superAdmin", false);
    }
    if (activate) {
      formData.append("status", "activate");
    } else {
      formData.append("status", "inactive");
    }
    dispatch(createAdmin(formData));
    reset();
    setLoading(true);
  };

  useEffect(() => {
    if (auth.newAdmin) {
      auth.newAdmin = false;
      setNotify({
        isOpen: true,
        message: "ادمین  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let errorTimeout = setTimeout(() => {
        setErrors({});
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }, [errors]);

  const content = (
    <>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="username"
              label="نام کاربری"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={_errors.username}
            />
            <Controls.Input
              variant="outlined"
              name="password"
              label="رمز عبور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={_errors.password}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={superAdmin}
                  onChange={() => setSuperAdmin(!superAdmin)}
                  name="superAdmin"
                />
              }
              label="ادمین ناظر"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activate}
                  onChange={() => setActivate(!activate)}
                  name="activate"
                />
              }
              label="فعال"
            />
          </Grid>
          <Grid item>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره این ادمین اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره این ادمین"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
            <Controls.Button
              text="پاک کردن همه مقادیر"
              color="secondary"
              onClick={reset}
              startIcon={<Controls.Icons.DeleteIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
    </>
  );
  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return <>{loading ? _loading : content}</>;
}

export default AdminsForm;
