import types from "../actions/types";

const initialState = {
  diploma: null,
  new: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_DIPLOMAS:
      return {
        ...state,
        diploma: action.payload,
      };
    case types.NEW_DIPLOMA:
      return {
        ...state,
        new: true,
      };
    default:
      return state;
  }
}
