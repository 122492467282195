import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getAllManagersPublic } from "../../actions/managerActions";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import parse from "html-react-parser";
import ZoomImage from "../../shared/components/ZoomImage";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import CardBody from "../../components/Main Website/Card/CardBody";
import NotFound from "../../components/common/NotFound";

class DepartmentManager extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      item: {},
      recieved: false,
      description: "",
      headerUrl: "",
      loading: true,
      titles: [],
    };
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    SmoothScrollTop();
    this.props.getAllManagersPublic();
    this.props.getAllTitlesPublic();
    this.props.getTextAndHeaderPublic({ tag: "مدیریت" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.managers.managers.length !== 0) {
      const departmentManager = nextProps.managers.managers.filter(
        (manager) => manager.position === "Department Manager"
      );
      this.setState({ item: departmentManager[0] });
      nextProps.managers.managers = [];
      if (departmentManager.length > 0) {
        this.setState({ recieved: true });
      }
      this.setState({ loading: false });
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }

    if (nextProps.auth.titles) {
      this.setState({
        titles: nextProps.auth.titles.slice(),
      });
      nextProps.auth.titles = null;
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
    }
  }

  setOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { classes } = this.props;
    const content = this.state.item && (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه مدیر دپارتمان"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه مدیر دپارتمان"
                    )[0].title
                  }
                </h1>
              )}
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
              <h2 className={classes.title}>{this.state.item.name}</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up">
              {this.state.item.name && (
                <Card plain>
                  <ZoomImage
                    src={
                      this.state.item.picture
                        ? this.state.item.picture.address
                        : ""
                    }
                    alt=""
                    className={classes.imgRounded}
                  />
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={6} data-aos="zoom-in-up">
              <div className={classes.sideParagraph}>
                {parse(`${this.state.item.description}`)}
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="center">
            {this.state.item.videoClips &&
              this.state.item.videoClips.length !== 0 &&
              this.state.item.videoClips.map((x, i) => (
                <GridItem xs={12} sm={12} md={10} key={i} data-aos="fade-up">
                  <Card plain>
                    <CardBody>
                      <VideoPlayer src={x.address} style={classes.video} />
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
          </GridContainer>
        </div>
      </>
    );

    let notFound = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه مدیر دپارتمان"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه مدیر دپارتمان"
                    )[0].title
                  }
                </h1>
              )}
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className={classes.section}
        >
          <NotFound />
        </div>
      </>
    );

    const _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading
              ? _loading
              : !this.state.recieved
              ? notFound
              : content}
          </div>
        </div>
      </div>
    );
  }
}

DepartmentManager.propTypes = {
  managers: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getAllManagersPublic: PropTypes.func.isRequired,
  getAllTitlesPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  managers: state.managers,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllManagersPublic,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
})(withStyles(styles)(DepartmentManager));
