const types = {
  GET_LANDING_DATA: "GET_LANDING_DATA",
  GET_FOOTER_DATA: "GET_FOOTER_DATA",
  SET_CURRENT_ADMIN: "SET_CURRENT_ADMIN",
  GET_ADMINS: "GET_ADMINS",
  GET_ADMIN: "GET_ADMIN",
  NEW_ADMIN: "NEW_ADMIN",
  DELETE_ADMIN: "DELETE_ADMIN",
  PROFILE_LOADING: "PROFILE_LOADING",
  GET_ERROR: "GET_ERROR",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_CURRENT_ADMIN: "CLEAR_CURRENT_ADMIN",
  NEW_AZ_CONCERT: "NEW_AZ_CONCERT",
  SAVING_AZ_CONCERT: "SAVING_AZ_CONCERT",
  NEW_ITEM: "NEW_ITEM",
  GET_ALL_AZ_CONCERTS: "GET_ALL_AZ_CONCERTS",
  GET_A_AZ_CONCERT: "GET_A_AZ_CONCERT",
  GET_ALL_INSTRUMENTS: "GET_ALL_INSTRUMENTS",
  NEW_INSTRUMENT: "NEW_INSTRUMENT",
  DELETE_AN_INSTRUMENT: "DELETE_AN_INSTRUMENT",
  GET_ALL_INSTRUMENT: "GET_ALL_INSTRUMENT",
  NEW_AZ_INSTRUMENT: "NEW_AZ_INSTRUMENT",
  DELETE_AZ_CONCERT: "DELETE_AZ_CONCERT",
  DELETE_AZMOON: "DELETE_AZMOON",
  NEW_WORKSHOP: "NEW_WORKSHOP",
  DELETE_A_WORKSHOP: "DELETE_A_WORKSHOP",
  GET_ALL_WORKSHOPS: "GET_ALL_WORKSHOPS",
  GET_A_WORKSHOPS: "GET_A_WORKSHOPS",
  NEW_FESTIVAL: "NEW_FESTIVAL",
  DELETE_A_FESTIVAL: "DELETE_A_FESTIVAL",
  GET_ALL_FESTIVALS: "GET_ALL_FESTIVALS",
  GET_A_FESTIVAL: "GET_A_FESTIVAL",
  SELECTED_RECORD: "SELECTED_RECORD",
  SELECTED_ITEM: "SELECTED_ITEM",
  RECEIVE_SELECTED_RECORD: "RECEIVE_SELECTED_RECORD",
  RECEIVE_SELECTED_ITEM: "RECEIVE_SELECTED_ITEM",
  DELETE_AN_INSTRUCTOR: "DELETE_AN_INSTRUCTOR",
  GET_ALL_INSTRUCTOR: "GET_ALL_INSTRUCTOR",
  GET_A_INSTRUCTOR: "GET_A_INSTRUCTOR",
  NEW_AZ_INSTRUCTOR: "NEW_AZ_INSTRUCTOR",
  DELETE_A_MANAGER: "DELETE_A_MANAGER",
  GET_ALL_MANAGERS: "GET_ALL_MANAGERS",
  NEW_MANAGER: "NEW_MANAGER",
  NEW_TEXT_HEADER: "NEW_TEXT_HEADER",
  NEW_TITLE: "NEW_TITLE",
  GET_TEXT_HEADER: "GET_TEXT_HEADER",
  GET_SLIDER_HEADER: "GET_SLIDER_HEADER",
  GET_ALL_TEXT_HEADER: "GET_ALL_TEXT_HEADER",
  GET_ALL_TITLES: "GET_ALL_TITLES",
  NEW_DIPLOMA: "NEW_DIPLOMA",
  GET_DIPLOMAS: "GET_DIPLOMAS",
  NEW_NEWS: "NEW_NEWS",
  GET_NEWS: "GET_NEWS",
  GET_A_NEWS: "GET_A_NEWS",
  DELETE_NEWS: "DELETE_NEWS",
  GET_PICTURES: "GET_PICTURES",
  GET_SANTOURHOUSE: "GET_SANTOURHOUSE",
  SET_SANTOURHOUSE: "SET_SANTOURHOUSE",
  GET_DEPARTMENT: "GET_DEPARTMENT",
  SET_DEPARTMENT: "SET_DEPARTMENT",
  DELETE_A_PICTURE: "DELETE_A_PICTURE",
};

export default types;
