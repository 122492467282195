import React, { useEffect } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, Paper } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import {
  editAzmoon,
  deleteAzmoon,
  getAzmoonById,
} from "../../../actions/azActions";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import DeleteConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import { Link, useLocation } from "react-router-dom";

const FormData = require("form-data");

const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function AzConcertEdit() {
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [_id, setId] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [videoClip, setVideoClip] = React.useState("");
  const [poster, setPoster] = React.useState([]);
  const [newPoster, setNewPoster] = React.useState([]);
  const [openPoster, setOpenPoster] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const azCon = useSelector((state) => state.azCon);
  const azAll = useSelector((state) => state.azAll);
  const dispatch = useDispatch();

  const reset = () => {
    setLoading(false);
    setNewPoster([]);
    setOpenPoster(false);
    setOpen(false);
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setDeleteConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };
  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    dispatch(getAzmoonById({ azmoon_id: id }));
  }, []);

  useEffect(() => {
    if (azCon.new) {
      azCon.new = false;
      setNotify({
        isOpen: true,
        message: "آزمون-کنسرت  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [azCon]);

  useEffect(() => {
    if (azAll.azmoon) {
      const _poster = [];
      const pic = { address: azAll.azmoon.poster.address };
      _poster.push(pic);
      const _videoClip = azAll.azmoon.videoClip;
      const _title = azAll.azmoon.title;
      const id = azAll.azmoon._id;

      setTitle(_title);
      setVideoClip(_videoClip);
      setPoster(_poster);
      setId(id);
      var itemsArray = [];
      for (let i = 0; i < azAll.azmoon.items.length; i++) {
        itemsArray.push(azAll.azmoon.items[i]);
      }
      setItems(itemsArray);
      azAll.azmoon = null;
      setLoading(false);
    }

    if (azAll.deleteAnAzmoon) {
      azAll.deleteAnAzmoon = false;
      setNotify({
        isOpen: true,
        message: "آزمون با موفقیت حذف شد",
        type: "info",
      });
      items.splice(number, 1);
      setLoading(false);
    }
  }, [azAll]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let errorTimeout = setTimeout(() => {
        setErrors({});
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const setDeleteConfirmDialogue = (data) => {
    setDeleteConfirmDialog(data);
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.title = title ? "" : "لطفا عنوان آزمون-کنسرت را وارد کنید";

    temp.videoClip = videoClip ? "" : "لطفا آدرس  فیلم این آزمون را وارد کنید";

    temp.poster =
      poster.length === 0 && newPoster.length === 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک عکس به عنوان پوستر این آزمون-کنسرت انتخاب کنید",
            type: "error",
          })
        : "";

    setErrors({ ...temp });

    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();
    formData.append(
      "poster",
      newPoster.length > 0 ? JSON.stringify(newPoster[0]) : "empty"
    );
    formData.append("title", title);
    formData.append("videoClip", videoClip);
    formData.append(
      "currentPoster",
      poster.length > 0 ? JSON.stringify(poster[0]) : "empty"
    );
    formData.append("azmoon_id", _id);
    dispatch(editAzmoon(formData));
    reset();
    setLoading(true);
  };

  const _deleteAzmoon = () => {
    setDeleteConfirmDialog({ ...deleteConfirmDialog, isOpen: false });
    dispatch(deleteAzmoon({ azConcert_id: _id, azConcert_number: number }));
    reset();
    setLoading(true);
  };

  const classes = useStyles();

  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
              }}
            >
              آزمون-کنسرت
            </h1>
          </Grid>
          <Grid item xs={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="title"
              label="عنوان آزمون کنسرت"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={_errors.title}
            />
            <Controls.Input
              variant="outlined"
              name="videoClip"
              label="آدرس آپارات ویدئو"
              value={videoClip}
              onChange={(e) => setTitle(e.target.value)}
              error={_errors.videoClip}
            />
          </Grid>
          {poster.length !== 0 && (
            <Grid item xs={2} sm={1} md={1} style={{ marginRight: "8px" }}>
              <Card>
                <Image
                  onClick={() => {
                    setAddress(poster[0].address);
                    setOpen(true);
                  }}
                  src={poster[0].address}
                />
                <Controls.ActionButton
                  onClick={() => {
                    setPoster([]);
                  }}
                >
                  <DeleteIcon color="secondary" />
                </Controls.ActionButton>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} md={10}>
            <div style={{ marginBottom: "16px" }} />
          </Grid>
          <Grid item xs={12} md={10}>
            <Controls.UploadImage
              text="اضافه کردن پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر"
              dialogTitle="آپلود پوستر"
              value={newPoster}
              //onChange={(e) => {}}
              acceptedFiles={["image/*"]}
              fileObjects={newPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPoster}
              onClose={() => setOpenPoster(!openPoster)}
              onSave={() => setOpenPoster(!openPoster)}
              onClick={() => setOpenPoster(!openPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObj) => {
                setNewPoster(newPoster.concat(newFileObj));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPoster.indexOf(deleteFileObj);
                setNewPoster(
                  newPoster.filter((pic) => newPoster.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
            />
          </Grid>
          <Grid item>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره اطلاعات اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره اطلاعات"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
          </Grid>
          {items.length !== 0 && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  آزمون-کنسرت سازهای مختلف
                </h1>
                <br />
              </Grid>
              {items.map((x, i) => (
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  style={{ marginLeft: "8px" }}
                  key={i}
                >
                  <Card>
                    <Controls.ActionButton>
                      <Link
                        to={`/admin-dashboard/azmoon-concert-edit/${_id}/${i}`}
                        style={{ textDecoration: "none", color: "steelblue" }}
                      >{`آزمون ساز ${x.instrument}`}</Link>
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      onClick={() => {
                        setDeleteConfirmDialogue({
                          isOpen: true,
                          title: "آیا از حذف این آزمون اطمینان دارید؟",
                          subTitle: "امکان بازیابی وجود ندارد",
                        });
                        setNumber(i);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      <DeleteConfirmDialogue
        confirmDialog={deleteConfirmDialog}
        setConfirmDialog={setDeleteConfirmDialogue}
        onConfirm={_deleteAzmoon}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default AzConcertEdit;
