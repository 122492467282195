import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import NewsForm from "./NewsForm";

const styles = (theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

const useStyles = makeStyles(styles);

function News() {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={8} lg={10}>
      <Paper className={classes.paper}>
        <NewsForm />
      </Paper>
    </Grid>
  );
}

export default News;
