import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const updateDiploma = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/diplomas/update-diploma`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_DIPLOMA,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editDiploma = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/diplomas/edit-diploma`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_DIPLOMA,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getDiploma = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/diplomas/get-diploma-content`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const diploma = result.data;
      dispatch({
        type: types.GET_DIPLOMAS,
        payload: diploma,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getDiplomaPublic = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/diplomas/get-diploma-content-public`)
    .then((result) => {
      const diploma = result.data;
      dispatch({
        type: types.GET_DIPLOMAS,
        payload: diploma,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};
