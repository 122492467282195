import React, { useEffect } from "react";
import { editManager, getAllManagers } from "../../../actions/managerActions";
import { useSelector, useDispatch } from "react-redux";
import { Fab, Grid, Paper, Tooltip } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Form } from "../../../components/dashboard related/useForms";
import Loading from "../../../components/common/Loading";
import Card from "../../../components/Main Website/Card/Card";
import Controls from "../../../components/dashboard related/controls/Controls";
import Editor from "../Editor";
import Image from "material-ui-image";
import { Lightbox } from "react-modal-image";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { useLocation } from "react-router-dom";

const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

export default function ManagerEdit() {
  const [address, setAddress] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [snippet, setSnippet] = React.useState("");
  const [managerId, setManagerId] = React.useState();
  const [position, setPosition] = React.useState("");
  const [videoClips, setVideoClips] = React.useState([]);
  const [positions, setPositions] = React.useState([
    "Department Manager",
    "Institute Manager",
  ]);
  const [picture, setPicture] = React.useState([]);
  const [newPicture, setNewPicture] = React.useState([]);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    name: "",
    subname: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const managers = useSelector((state) => state.managers);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );

    setManagerId(id);
    dispatch(getAllManagers());
  }, []);

  useEffect(() => {
    if (managers.new) {
      managers.new = false;
      setNotify({
        isOpen: true,
        message: "مدیر  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
    if (managers.managers.length > 0) {
      const manager = managers.managers.filter(
        (m) =>
          m._id ==
          location.pathname.substring(
            location.pathname.lastIndexOf("/") + 1,
            location.pathname.length
          )
      )[0];
      var _picture = [];
      let videoClips = [];

      const pic = { address: manager.picture.address };
      _picture.push(pic);
      setManagerId(manager._id);
      setPicture(_picture);
      setName(manager.name);
      setDescription(manager.description);
      setSnippet(manager.snippet);
      manager.videoClips.forEach((video) => {
        videoClips.push({ address: video.address });
      });
      setVideoClips(videoClips);
      setPosition(manager.position);
      managers.managers = [];
      setLoading(false);
    }
  }, [managers]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.error.data.error !== "undefined"
          ? errors.error.data.error
          : errors.error.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const validate = () => {
    let temp = {};
    temp.name = name ? "" : "لطفا نام مدیر را وارد کنید";
    temp.description = description
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن معرفی مدیر را وارد کنید",
          type: "error",
        });
    temp.snippet = snippet
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن کوتاه معرفی مدیر را وارد کنید",
          type: "error",
        });
    temp.position = position ? "" : "لطفا یکی از سمت‌ها را انتخاب کنید";
    temp.picture =
      !picture.length > 0 && !newPicture.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا  یک تصویر برای این مدیر انتخاب کنید",
            type: "error",
          })
        : "";
    setErrors({ ...temp });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("snippet", snippet);
    formData.append("position", position);
    for (let i = 0; i < videoClips.length; i++) {
      formData.append("videoClips", videoClips[i].address);
    }
    formData.append(
      "picture",
      newPicture.length > 0 ? JSON.stringify(newPicture[0]) : "empty"
    );
    formData.append(
      "currentPicture",
      picture.length > 0 ? JSON.stringify(picture[0]) : "empty"
    );

    formData.append("manager_id", managerId);
    dispatch(editManager(formData));
    setLoading(true);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...videoClips];
    list[index].address = value;
    setVideoClips(list);
  };

  const handleRemoveClick = (index) => {
    if (videoClips.length > 0) {
      const list = [...videoClips];
      list.splice(index, 1);
      setVideoClips(list);
    }
  };

  const handleAddClick = () => {
    setVideoClips([...videoClips, { address: "" }]);
  };

  const classes = useStyles();
  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              تصویر مدیر
            </h3>
          </Grid>
          {picture.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(picture[0].address);
                      setOpen(true);
                    }}
                    src={picture[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setPicture([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Controls.UploadImage
              text="انتخاب تصویر جدید"
              name="uploadImage"
              label="آپلود تصویر جدید"
              dialogTitle="آپلود تصویر جدید"
              value={newPicture}
              acceptedFiles={[]}
              fileObjects={newPicture}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPicture}
              onClose={() => setOpenPicture(!setOpenPicture)}
              onSave={() => setOpenPicture(!setOpenPicture)}
              onClick={() => setOpenPicture(!openPicture)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPicture(newPicture.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPicture.indexOf(deleteFileObj);
                setNewPicture(
                  newPicture.filter((pic) => newPicture.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              مشخصات مدیر
            </h3>
          </Grid>
          <Grid item xs={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="name"
              label="نام مدیر"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={_errors.name}
            />
            <Editor
              value={description}
              onEditorChange={(e) => setDescription(e)}
              placeholder="توضیحات معرفی مدیر"
            />
            <div style={{ marginTop: "64px" }} />
            <Editor
              value={snippet}
              onEditorChange={(e) => setSnippet(e)}
              placeholder="توضیحات کوتاه معرفی مدیر"
              height={300}
            />
            <div style={{ marginTop: "64px" }} />
            <Controls.SelectInstrument
              name="position"
              label="انتخاب سمت"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              options={positions}
              error={_errors.position}
            />
          </Grid>
          {videoClips.length !== 0 &&
            videoClips.map((x, i) => (
              <Grid item xs={12} md={10} key={i}>
                <Controls.Input
                  variant="outlined"
                  name="videoClip"
                  label="آدرس آپارات ویدئو"
                  value={x.address}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errors.videoClips}
                />
                {videoClips.length > 0 && (
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Controls.ActionButton>
                )}
              </Grid>
            ))}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              marginBottom: "16px",
              marginTop: "16px",
              marginRight: "8px",
            }}
          >
            <Tooltip
              title="اضافه کردن یک ویدئو جدید"
              aria-label="add"
              onClick={handleAddClick}
              placement="top"
            >
              <Fab color="primary">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={12}>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره اطلاعات این مدیر اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره مدیر"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}
