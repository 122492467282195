import React, { Component } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Fab,
  Tooltip,
} from "@material-ui/core";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Editor from "../Editor";
import { newWorkshop } from "../../../actions/workshopActions";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
});

const initialState = {
  title: "",
  videoClip: [],
  poster: [],
  pictures: [],
  description: "",
  createdAt: Date(),
  open: false,
  openPoster: false,
  loading: false,
  santourHouse: false,
  department: false,
  tarbiat: false,
  sazshenasi: false,
  notify: {
    isOpen: false,
    message: "",
    type: "",
  },
  confirmDialog: {
    isOpen: false,
    title: "",
    subTitle: "",
  },
  errors: {},
};

class WorkshopForm extends Component {
  constructor() {
    super();
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.reset = this.reset.bind(this);
    this.validate = this.validate.bind(this);
    this.setConfirmDialogue = this.setConfirmDialogue.bind(this);
    this.setNotify = this.setNotify.bind(this);
    this.setOpenPoster = this.setOpenPoster.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workshopReducer.new) {
      nextProps.workshopReducer.new = false;
      this.setNotify({
        isOpen: true,
        message: "کارگاه آموزشی  با موفقیت ذخیره شد",
        type: "success",
      });
      this.setState({ loading: false });
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      let err =
        typeof nextProps.errors.data.error !== "undefined"
          ? nextProps.errors.data.error
          : nextProps.errors.data;
      this.setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
      let errorTimeout = setTimeout(() => {
        this.setState({ errors: {} });
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }

  setConfirmDialogue(data) {
    if (this.validate()) {
      const conDialogue = {
        isOpen: data.isOpen,
        title: data.title,
        subTitle: data.subTitle,
      };
      this.setState({ confirmDialog: conDialogue });
    }
  }

  handleAddClick() {
    this.setState({ videoClip: [...this.state.videoClip, ""] });
  }

  handleInputChange(e, index) {
    const { value } = e.target;
    const list = [...this.state.videoClip];
    list[index] = value;
    this.setState({ videoClip: list });
  }

  handleRemoveClick(index) {
    if (this.state.videoClip.length > 0) {
      const list = [...this.state.videoClip];
      list.splice(index, 1);
      this.setState({ videoClip: list });
    }
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  setOpenPoster(e) {
    this.setState({ openPoster: !this.state.openPoster });
  }

  setOpen() {
    this.setState({ open: !this.state.open });
  }

  reset() {
    this.setState({ errors: {} });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, isOpen: false },
    });

    const formData = new FormDataa();
    formData.append("poster", JSON.stringify(this.state.poster[0]));
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);
    if (this.state.videoClip.length !== 0) {
      formData.append("videoClip", this.state.videoClip[0]);
    } else {
      formData.append("videoClip", "empty");
    }
    if (this.state.pictures.length > 0) {
      for (let i = 0; i < this.state.pictures.length; i++) {
        formData.append("pictures", JSON.stringify(this.state.pictures[i]));
      }
    } else {
      formData.append("pictures", "empty");
    }
    this.state.santourHouse
      ? formData.append("santourHouse", "santourHouse")
      : formData.append("santourHouse", "other");

    this.state.sazshenasi
      ? formData.append("sazshenasi", "sazshenasi")
      : formData.append("sazshenasi", "other");
    this.state.tarbiat
      ? formData.append("tarbiat", "tarbiat")
      : formData.append("tarbiat", "other");
    !this.state.tarbiat && !this.state.sazshenasi && !this.state.santourHouse
      ? formData.append("other", "other")
      : formData.append("other", "none");
    this.props.newWorkshop(formData);
    this.reset();
    this.setState({ loading: true });
  }

  validate() {
    let temp = { ...this.state.errors };
    if ("title" in this.state)
      temp.title = this.state.title ? "" : "لطفا عنوان کارگاه را وارد کنید";
    if ("description" in this.state)
      temp.description = this.state.description
        ? ""
        : "لطفا توضیحات کارگاه را وارد کنید";
    // if ("videoClip" in this.state)
    //   temp.videoClip = this.state.videoClip
    //     ? ""
    //     : "لطفا آدرس  فیلم این کارگاه را وارد کنید";
    temp.videoClip = "";
    if (this.state.videoClip.length > 0) {
      this.state.videoClip.forEach((v) => {
        if (v === "") {
          temp.videoClip = this.setNotify({
            isOpen: true,
            message: "لطفا آدرس  کلیپی که اضافه شده‌است را وارد کنید",
            type: "error",
          });
        }
      });
    }
    if ("poster" in this.state)
      temp.poster =
        this.state.poster.length > 0
          ? ""
          : this.setNotify({
              isOpen: true,
              message: "لطفا یک عکس به عنوان پوستر این کارگاه انتخاب کنید",
              type: "error",
            });
    this.setState({ errors: { ...temp } });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  }

  render() {
    //const { classes } = this.props;
    let content;
    content = !this.state.loading ? (
      <>
        <Form
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} md={10}>
              <Controls.Input
                variant="outlined"
                name="title"
                label="عنوان کارگاه"
                value={this.state.title}
                onChange={this.onChange}
                error={this.state.errors.title}
              />
              {/* <Controls.Input
                variant="outlined"
                name="videoClip"
                label="آدرس آپارات ویدئو"
                value={this.state.videoClip}
                onChange={this.onChange}
                error={this.state.errors.videoClip}
              /> */}
              <div>
                {this.state.videoClip.length !== 0 &&
                  this.state.videoClip.map((x, i) => (
                    <Grid item xs={12} md={12} key={i}>
                      <Controls.Input
                        variant="outlined"
                        name="videoClip"
                        label="آدرس آپارات ویدئو"
                        value={x}
                        onChange={(e) => this.handleInputChange(e, i)}
                        error={this.state.errors.videoClip}
                        key={i}
                      />
                      {this.state.videoClip.length > 0 && (
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => this.handleRemoveClick(i)}
                          key={i + 1}
                        >
                          <DeleteIcon fontSize="small" />
                        </Controls.ActionButton>
                      )}
                    </Grid>
                  ))}
                {this.state.videoClip.length === 0 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ marginBottom: "32px", marginTop: "32px" }}
                  >
                    <Tooltip
                      title="اضافه کردن یک ویدئو جدید"
                      aria-label="add"
                      onClick={this.handleAddClick}
                      placement="top"
                    >
                      <Fab color="primary">
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                )}
              </div>
              {/* <Controls.Input
                variant="outlined"
                name="description"
                label="توضیحات مربوط به این کارگاه"
                value={this.state.description}
                onChange={this.onChange}
                error={this.state.errors.description}
              /> */}
              <Editor
                value={this.state.description}
                onEditorChange={(e) => this.setState({ description: e })}
                placeholder="توضیحات مربوط به این کارگاه"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ marginBottom: "8px", marginTop: "32px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.tarbiat || this.state.sazshenasi}
                    checked={this.state.santourHouse}
                    onChange={() =>
                      this.setState({ santourHouse: !this.state.santourHouse })
                    }
                    name="santourHouse"
                  />
                }
                label="از کارگاه‌های خانه سنتور"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.santourHouse || this.state.tarbiat}
                    checked={this.state.sazshenasi}
                    onChange={() =>
                      this.setState({ sazshenasi: !this.state.sazshenasi })
                    }
                    name="sazshenasi"
                  />
                }
                label="کارگاه سازشناسی برای کودکان"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.santourHouse || this.state.sazshenasi}
                    checked={this.state.tarbiat}
                    onChange={() =>
                      this.setState({ tarbiat: !this.state.tarbiat })
                    }
                    name="tarbiat"
                  />
                }
                label="کارگاه دوره تربیت مربی موسیقی کودک"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.UploadImage
                text="انتخاب پوستر"
                name="uploadImage"
                label="آپلود پوستر"
                dialogTitle="آپلود پوستر"
                value={this.state.poster}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.poster}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.openPoster}
                onClose={this.setOpenPoster}
                onSave={this.setOpenPoster}
                onClick={this.setOpenPoster}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  this.setState({
                    poster: this.state.poster.concat(newFileObj[0]),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.poster.indexOf(deleteFileObj);
                  this.setState({
                    poster: this.state.poster.filter(
                      (pic) => this.state.poster.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={1}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
              <Controls.UploadImage
                text="انتخاب تصاویر"
                name="uploadImage"
                label="آپلود تصاویر"
                dialogTitle="آپلود تصاویر"
                value={this.state.pictures}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.pictures}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.open}
                onClose={this.setOpen}
                onSave={this.setOpen}
                onClick={this.setOpen}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObjs) => {
                  this.setState({
                    pictures: this.state.pictures.concat(newFileObjs),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.pictures.indexOf(deleteFileObj);
                  this.setState({
                    pictures: this.state.pictures.filter(
                      (pic) => this.state.pictures.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={100}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
                error={this.state.errors.pictures}
              />
            </Grid>

            <Grid item style={{ marginTop: "32px" }}>
              <Controls.Button
                onClick={() =>
                  this.setConfirmDialogue({
                    isOpen: true,
                    title: "آیا از ذخیره این کارگاه اطمینان دارید؟",
                    subTitle: "",
                  })
                }
                text="ذخیره این کارگاه"
                color="primary"
                startIcon={<Controls.Icons.SaveIcon />}
              />
              <Controls.Button
                text="پاک کردن همه مقادیر"
                color="secondary"
                onClick={this.reset}
                startIcon={<Controls.Icons.DeleteIcon />}
              />
            </Grid>
          </Grid>
        </Form>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
        <ConfirmDialogue
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialogue}
          onConfirm={this.onSubmit}
        />
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    return <>{content}</>;
  }
}

WorkshopForm.propTypes = {
  workshopReducer: PropTypes.object.isRequired,
  newWorkshop: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  workshopReducer: state.workshopReducer,
  errors: state.errors,
});

export default connect(mapStateToProps, { newWorkshop })(
  withStyles(styles)(WorkshopForm)
);
