import types from "../actions/types";

const initialState = {
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case types.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
