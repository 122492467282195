import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const newFestival = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/festivals/new-festival`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_FESTIVAL,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editFestival = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/festivals/edit-festival`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_FESTIVAL,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllFestivals = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/festivals/get-all-festivals`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const festivals = result.data;
      dispatch({
        type: types.GET_ALL_FESTIVALS,
        payload: festivals,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllFestivalsPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/festivals/get-all-festivals-public`, data)
    .then((result) => {
      const festivals = result.data;
      dispatch({
        type: types.GET_ALL_FESTIVALS,
        payload: festivals,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAFestival = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/festivals/get-by-id`, data)
    .then((result) => {
      const festival = result.data;
      dispatch({
        type: types.GET_A_FESTIVAL,
        payload: festival,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteFestival = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/festivals/delete-a-festival`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_A_FESTIVAL,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};
