import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icons from "../controls/Icons";

export default function SidebarItem(props) {
  const { primary, onClick, open } = props;
  return (
    <ListItem button onClick={onClick}>
      <Icons.ListItemIcon></Icons.ListItemIcon>
      <ListItemText primary={primary} />
      {open ? (
        <Icons.ExpandLess color="primary" style={{ marginRight: "32px" }} />
      ) : (
        <Icons.ExpandMore color="primary" style={{ marginRight: "32px" }} />
      )}
    </ListItem>
  );
}
