import React from "react";
import { Editor as MyEditor } from "@tinymce/tinymce-react";

export default function Editor(props) {
  const { onEditorChange, value, placeholder, height } = props;
  return (
    <MyEditor
      value={value}
      apiKey="ddd8k54nj34rlodrrunwixjzu9uv8ggddtv6p9lplio75s61"
      init={{
        height: height ? height : 500,
        menubar: false,
        placeholder: placeholder ? placeholder : "چیزی بنویس...",
        directionality: "rtl",
        plugins: "link code",
        menubar: "insert format edit",
        default_link_target: "_blank",
        link_context_toolbar: true,
      }}
      outputFormat="html"
      onEditorChange={onEditorChange}
    />
  );
}
