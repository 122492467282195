import { makeStyles } from "@material-ui/core/styles";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import React from "react";
import styles from "../../../assets/jss/views/landingPageSections/teamStyle";
import myStyles from "../../../assets/jss/views/landingPage";

const useStyles = makeStyles(styles);
const usedStyles = makeStyles(myStyles);

export default function Section(props) {
  const classes = useStyles();
  const myClasses = usedStyles();

  const { src } = props;

  return (
    <div>
      <Card plain>
        <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
          <div className="h_iframe-aparat_embed_frame">
            <span></span>
            <iframe
              className={myClasses.videoSection}
              title=""
              src={src}
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>
          </div>
        </GridItem>
      </Card>
    </div>
  );
}
