import React, { Component } from "react";

//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAzmoonById } from "../../actions/azActions";

//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Date
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

//Video
import VideoPlayer from "../../components/common/VideoPlayer";

//Styling
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import Image from "material-ui-image";
import { Typography } from "@material-ui/core";

class AzConcertInstrument extends Component {
  constructor() {
    super();
    this.state = {
      record: {},
      headerUrl: "",
      loading: true,
      titles: [],
    };
  }

  componentDidMount() {
    SmoothScrollTop();
    let id = this.props.location.pathname.substring(
      this.props.location.pathname.lastIndexOf("/") + 1,
      this.props.location.pathname.length
    );
    this.props.getAzmoonById({ azmoon_id: id });
    this.props.getTextAndHeaderPublic({ tag: "آزمون" });
    this.props.getAllTitlesPublic();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.text_header) {
      let textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }
    if (nextProps.azAll.azmoon) {
      let azmoon = nextProps.azAll.azmoon;
      nextProps.azAll.azmoon = null;
      this.setState({ record: azmoon });
      this.setState({ loading: false });
    }

    if (nextProps.auth.titles) {
      this.setState({
        titles: nextProps.auth.titles.slice(),
      });
      nextProps.auth.titles = null;
    }
  }

  render() {
    const { classes } = this.props;

    const content = this.state.record && (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
              <h1 className={classes.title}>{this.state.record.title}</h1>
            </GridItem>
            <GridItem xs={false} sm={false} md={1}></GridItem>
            <GridItem xs={12} sm={12} md={10} data-aos="zoom-in-up">
              <Card plain>
                <CardBody>
                  <VideoPlayer
                    src={this.state.record.videoClip}
                    style={classes.video}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={false} sm={false} md={1}></GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={false} sm={false} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "زیر تیتر صفحه دوم آزمون"
              ).length !== 0 && (
                <h2 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "زیر تیتر صفحه دوم آزمون"
                    )[0].title
                  }
                </h2>
              )}
            </GridItem>
            {this.state.record.items &&
              this.state.record.items.length !== 0 &&
              this.state.record.items.map((x, i) => (
                <GridItem xs={12} sm={12} md={4} key={i} data-aos="zoom-in-up">
                  <NavLink
                    to={`/آزمون/ساز/${this.state.record._id}/${i}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card plain>
                      <CardHeader>
                        <h3 className={classes.cardTitle}>{x.instrument}</h3>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          className={classes.cardTitle}
                        >
                          {ConvertToPersian.ConvertDateToPersian(
                            CalculateDate(x.createdAt)
                          )}
                        </Typography>
                      </CardHeader>
                      <CardBody>
                        <Image
                          src={x.poster.address}
                          className={classes.imgRounded}
                        />
                      </CardBody>
                    </Card>
                  </NavLink>
                </GridItem>
              ))}
          </GridContainer>
        </div>
      </>
    );

    const _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading ? _loading : content}
          </div>
        </div>
      </div>
    );
  }
}

AzConcertInstrument.propTypes = {
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
  getAzmoonById: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  azAll: PropTypes.object.isRequired,
  SmoothScrollTop: PropTypes.func.isRequired,
  getAllTitlesPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  azAll: state.azAll,
});

export default connect(mapStateToProps, {
  getTextAndHeaderPublic,
  getAzmoonById,
  SmoothScrollTop,
  getAllTitlesPublic,
})(withStyles(styles)(AzConcertInstrument));
