import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, Grid } from "@material-ui/core";
import Functional from "./functional";
import { getAllAzConcerts, deleteAzConcert } from "../../../actions/azActions";
import Loading from "../../../components/common/Loading";
import Popup from "../../../components/common/Popup";
import AzConcertInstrumentForm from "./AzConcertInstrumentForm";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";

const styles = (theme) => ({
  pageContent: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1.5),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5),
    },
    padding: theme.spacing(3),
    width: "100%",
  },
  searchInput: {
    width: "75%",
  },
});

const headCells = [
  { id: "title", label: "عنوان آزمون-کنسرت", disableSorting: true },
  { id: "items", label: "تعداد آزمون‌ها", disableSorting: true },
  { id: "poster", label: "پوستر", disableSorting: true },
  { id: "date", label: "تاریخ ساخت", disableSorting: true },
  { id: "actions", label: "تنظیمات", disableSorting: true },
];

class AzConcertList extends Component {
  constructor() {
    super();
    this.state = {
      records: [],
      recordToModify: "",
      filterFn: [],
      loading: false,
      errors: {},
      openPopup: false,
      notify: {
        isOpen: false,
        message: "",
        type: "",
      },
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };
    this.setRecords = this.setRecords.bind(this);
    this.setModifyRecordId = this.setModifyRecordId.bind(this);
    this.setOpenPopup = this.setOpenPopup.bind(this);
    this.setConfirmDialogue = this.setConfirmDialogue.bind(this);
    this.setNotify = this.setNotify.bind(this);
    this.setOpenDeleteDialogue = this.setOpenDeleteDialogue.bind(this);
    this.deleteFromRecords = this.deleteFromRecords.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getAllAzConcerts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.azAll.records.length !== 0) {
      const newRecords = nextProps.azAll.records[0].slice();
      this.setState({ records: newRecords });
      nextProps.azAll.records = [];
    }

    if (nextProps.azAll.addedNewItem) {
      nextProps.azAll.addedNewItem = false;
      this.setState({ openPopup: false });
      this.setNotify({
        isOpen: true,
        message: "آزمون-کنسرت ساز جدید با موفقیت ذخیره شد",
        type: "success",
      });
    }

    if (nextProps.azAll.deleteAnItem) {
      nextProps.azAll.deleteAnItem = false;
      this.setNotify({
        isOpen: true,
        message: "آزمون-کنسرت با موفقیت حذف شد",
        type: "info",
      });
      this.deleteFromRecords(nextProps.azAll._id);
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      let err =
        typeof nextProps.errors.data.error !== "undefined"
          ? nextProps.errors.data.error
          : nextProps.errors.data;
      this.setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
      let errorTimeout = setTimeout(() => {
        this.setState({ errors: {} });
        clearTimeout(errorTimeout);
      }, 4000);
    }

    this.setState({ loading: false });
  }

  setRecords(newRecords) {
    this.setState({ records: [newRecords] });
  }

  setModifyRecordId(_id) {
    this.setState({ openPopup: !this.state.openPopup });
    this.setState({ recordToModify: _id });
  }

  setConfirmDialogue(data) {
    const conDialogue = {
      isOpen: data.isOpen,
      title: data.title,
      subTitle: data.subTitle,
    };
    this.setState({ confirmDialog: conDialogue });
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  setOpenDeleteDialogue(_id, data) {
    this.setConfirmDialogue(data);
    this.setState({ recordToModify: _id });
  }

  setOpenPopup() {
    this.setState({ openPopup: !this.state.openPopup });
  }

  deleteFromRecords(_id) {
    var records = this.state.records.filter((record) => record._id !== _id);
    this.setState({ records: records });
  }

  onDeleteAzConcert(e, recordForEdit) {
    e.preventDefault();
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, isOpen: false },
    });
    const data = {
      azConcert_id: recordForEdit,
    };

    this.props.deleteAzConcert(data);
  }

  render() {
    const { classes } = this.props;

    let content = (
      <>
        <Functional
          records={this.state.records}
          headCells={headCells}
          pageContent={classes.pageContent}
          openInPopup={this.setModifyRecordId}
          openDialogue={this.setOpenDeleteDialogue}
          deleteDialog={{
            isOpen: true,
            title: "آیا از حذف این آزمون-کنسرت اطمینان دارید؟",
            subTitle: "تاثیر این عملیات همیشگی است",
          }}
          az={true}
          workshop={false}
          festival={false}
          instrument={false}
          diploma={false}
          news={false}
          editPageAddress="/admin-dashboard/edit-azmoon"
          nameToEdit="azmoonEdit"
        />
        <Popup
          title="اضافه کردن آزمون-کنسرت یک ساز"
          openPopup={this.state.openPopup}
          setOpenPopup={this.setOpenPopup}
        >
          <AzConcertInstrumentForm recordForEdit={this.state.recordToModify} />
        </Popup>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
        <ConfirmDialogue
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialogue}
          onConfirm={(e) =>
            this.onDeleteAzConcert(e, this.state.recordToModify)
          }
        />
      </>
    );

    let _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    return (
      <Grid item xs={12} md={8} lg={10}>
        {this.state.loading ? _loading : content}
      </Grid>
    );
  }
}

AzConcertList.propTypes = {
  azAll: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getAllAzConcerts: PropTypes.func.isRequired,
  deleteAzConcert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  azAll: state.azAll,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllAzConcerts, deleteAzConcert })(
  withStyles(styles)(AzConcertList)
);
