const cardBodyStyle = {
  cardBody: {
    border: "6px",
    borderRadius: "6px",
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto",
  },
};

export default cardBodyStyle;
