import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import VideoPopup from "../../components/common/VideoPopup";
import Loading from "../../components/common/Loading";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAFestival } from "../../actions/fetivalActions";
//Images
import ZoomImage from "../../shared/components/ZoomImage";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import {
  Card as MuiCard,
  Button as MuiButton,
  CardContent,
  CardActions,
} from "@material-ui/core";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

function FestivalItem() {
  const [item, setItem] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [openPopupVideo, setOpenPopupVideo] = React.useState(false);
  const [videoId, setvideoId] = React.useState();
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const festivalReducer = useSelector((state) => state.festivalReducer);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    SmoothScrollTop();
    dispatch(getAFestival({ festival_id: id }));
    dispatch(getAllTitlesPublic());
    dispatch(getTextAndHeaderPublic({ tag: "فستیوال" }));
  }, []);

  useEffect(() => {
    if (festivalReducer.record) {
      const festival = festivalReducer.record;
      setItem(festival);
      festivalReducer.record = null;
      setLoading(false);
    }
  }, [festivalReducer]);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);
  const classes = useStyles();

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  const content = item && (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            <h1 className={classes.title}>{item.title}</h1>
            <br />
          </GridItem>

          {item.poster && (
            <GridItem xs={12} sm={12} md={4} data-aos="fade-up">
              <Card>
                <ZoomImage
                  alt=""
                  src={item.poster.address}
                  className={classes.imgRounded}
                  gallery={false}
                />
              </Card>
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6} data-aos="fade-down">
            <div className={classes.sideParagraph}>
              {parse(`${item.description}`)}
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "زیر تیتر اول  صفحه دوم فستیوال")
              .length !== 0 && (
              <h2 className={classes.title}>
                {
                  titles.filter(
                    (t) => t.tag === "زیر تیتر اول  صفحه دوم فستیوال"
                  )[0].title
                }
              </h2>
            )}
          </GridItem>
          {item.pictures.length !== 0 &&
            item.pictures.map((x, i) => (
              <GridItem xs={12} sm={12} md={3} key={i} data-aos="fade-up">
                <Card>
                  <ZoomImage
                    alt=""
                    src={x.address}
                    className={classes.imgRounded}
                    gallery={true}
                  />
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "زیر تیتر دوم  صفحه دوم فستیوال")
              .length !== 0 && (
              <h2 className={classes.title}>
                {
                  titles.filter(
                    (t) => t.tag === "زیر تیتر دوم  صفحه دوم فستیوال"
                  )[0].title
                }
              </h2>
            )}
          </GridItem>
          {item.videoClips.length !== 0 &&
            item.videoClips.map((x, i) => (
              <GridItem xs={12} sm={12} md={4} key={i} data-aos="fade-up">
                <MuiCard elevation={0}>
                  <CardContent className={classes.cardContent}>
                    <VideoPlayer src={x} style={{ height: "300px" }} />
                  </CardContent>
                  <CardActions style={{ direction: "rtl" }}>
                    <MuiButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        setvideoId(i);
                        setOpenPopupVideo(true);
                      }}
                    >
                      نمایش بزرگتر
                    </MuiButton>
                  </CardActions>
                </MuiCard>
              </GridItem>
            ))}
        </GridContainer>
      </div>
      <VideoPopup
        openPopup={openPopupVideo}
        setOpenPopup={() => setOpenPopupVideo(!openPopupVideo)}
        maxWidth="md"
        data-aos="flip-down"
      >
        <div className={classes.video}>
          <span></span>
          <iframe
            style={{ width: "100%", height: "100%" }}
            title=""
            src={item.videoClips[videoId] ? item.videoClips[videoId] : ""}
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </VideoPopup>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>{loading ? _loading : content}</div>
      </div>
    </div>
  );
}

export default FestivalItem;
