import types from "../actions/types";

const initialState = {
  records: [],
  addedNewItem: false,
  deleteAnItem: false,
  deleteAnAzmoon: false,
  azmoon: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_AZ_CONCERTS:
      return {
        ...state,
        records: [action.payload],
      };
    case types.GET_A_AZ_CONCERT:
      return {
        ...state,
        azmoon: action.payload,
      };
    case types.NEW_AZ_INSTRUMENT:
      return {
        ...state,
        addedNewItem: true,
      };
    case types.DELETE_AZ_CONCERT:
      return {
        ...state,
        deleteAnItem: true,
        _id: action.payload,
      };
    case types.DELETE_AZMOON:
      return {
        ...state,
        deleteAnAzmoon: true,
        _id: action.payload,
      };
    default:
      return state;
  }
}
