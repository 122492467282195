import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { getAllNews, deleteNews } from "../../../actions/adminActions";
import Loading from "../../../components/common/Loading";
import Functional from "../AzConcerts/functional";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  pageContent: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1.5),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(5),
    },
    padding: theme.spacing(3),
    width: "100%",
  },
  searchInput: {
    width: "75%",
  },
});
const useStyles = makeStyles(styles);

const headCells = [
  { id: "title", label: "عنوان", disableSorting: true },
  { id: "picture", label: "تصویر", disableSorting: true },
  { id: "date", label: "تاریخ ساخت", disableSorting: true },
  { id: "actions", label: "تنظیمات", disableSorting: true },
];

function NewsList() {
  const [loading, setLoading] = React.useState(true);
  const [openPopUp, setOpenPopUp] = React.useState(true);
  const [news, setNews] = React.useState([]);
  const [newsToModify, setNewsToModify] = React.useState("");
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllNews());
  }, []);

  useEffect(() => {
    if (auth.news) {
      const _news = auth.news.slice();
      setNews(_news);
      auth.news = null;

      setLoading(false);
    }

    if (auth.deleteANews) {
      setNotify({
        isOpen: true,
        message: "خبر با موفقیت حذف شد",
        type: "info",
      });
      auth.deleteANews = false;
      deleteFromRecords(auth._id);
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const onDeleteNews = (e, recordForEdit) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const data = {
      news_id: recordForEdit,
    };

    dispatch(deleteNews(data));
  };

  const setModifyNewsId = (_id) => {
    setOpenPopUp(!openPopUp);
    setNewsToModify(_id);
  };

  const setOpenDeleteDialogue = (_id, data) => {
    setConfirmDialog(data);
    setNewsToModify(_id);
  };

  const deleteFromRecords = (_id) => {
    var records = news.filter((record) => record._id !== _id);
    setNews(records);
  };

  const classes = useStyles();

  const content = (
    <>
      <Functional
        records={news}
        headCells={headCells}
        pageContent={classes.pageContent}
        openInPopup={setModifyNewsId}
        openDialogue={setOpenDeleteDialogue}
        deleteDialog={{
          isOpen: true,
          title: "",
          subTitle: "",
        }}
        az={false}
        workshop={false}
        festival={false}
        instrument={false}
        diploma={false}
        news={true}
        editPageAddress="/admin-dashboard/edit-news"
        nameToEdit="newsEdit"
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirm={(e) => onDeleteNews(e, newsToModify)}
      />
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default NewsList;
