import { container, title } from "../../material-kit-react";
import imagesStyle from "../imagesStyles.js";
import classNames from "classnames";

const landingPageStyle = (theme) => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "right",
    ...container,
  },
  title: {
    ...title,
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  ...imagesStyle,
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-5px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  image: {
    width: "200px",
    height: "200px",
    marginLeft: "-200px",
    [theme.breakpoints.down("md")]: {
      width: "150px",
      height: "150px",
      marginTop: "10px",
      marginRight: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px",
      margin: "200px",
      // marginTop: "110px",
      //marginLeft: "30px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "150px",
    //   height: "150px",
    //   marginTop: "110px",
    //   marginLeft: "180px",
    // },
  },
  video: {
    width: "100%",
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "250px",
    },
  },
  pagination: {
    float: "right",
    marginBottom: "50px",
    marginTop: "50px",
    color: "#bdbdbd",
  },
  cardContent: {
    marginRight: "8px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "32px",
    },
  },
  map: {
    height: "300px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  paper: {
    padding: "8px",
    margin: "8px",
  },
  gridItem: {
    marginBottom: "64px",
    marginTop: "64px",
  },
  gridItemHeader: {
    marginBottom: "4px",
    marginTop: "64px",
    display: "flex",
    justifyContent: "center",
  },
  paragraph: {
    color: "#999",
    //textAlign: "center",
    direction: "rtl",
  },
  sideParagraph: {
    color: "#999",
    textAlign: "justify",
    direction: "rtl",
    [theme.breakpoints.up("md")]: {
      marginTop: "128px",
    },
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  sideNews: {
    [theme.breakpoints.up("md")]: {
      marginTop: "64px",
    },
  },
  videoSection: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  cardTitle: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default landingPageStyle;
