import types from "../actions/types";

const initialState = {
  records: [],
  new: false,
  workshop: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_WORKSHOPS:
      return {
        ...state,
        records: action.payload,
      };
    case types.GET_A_WORKSHOPS:
      return {
        ...state,
        workshop: action.payload,
      };
    case types.DELETE_A_WORKSHOP:
      return {
        ...state,
        deleteAnItem: true,
        _id: action.payload,
      };
    case types.NEW_WORKSHOP:
      return {
        ...state,
        new: true,
      };
    default:
      return state;
  }
}
