import React, { useEffect, useCallback, useState } from "react";
//Actions
import { getAllPictures } from "../../actions/adminActions";
import { getTextAndHeaderPublic } from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import ModalCarousel from "../../shared/components/ModalCarousel";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function Gallery() {
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState([]);
  const [headerUrl, setHeaderUrl] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(16);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getTextAndHeaderPublic({ tag: "گالری" }));
    dispatch(getAllPictures({ page: page, itemPerPage: itemPerPage }));
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.pictures.length !== 0) {
      const _pictures = auth.pictures.pictures.slice();
      let pictures = [];
      if (_pictures.length !== 0) {
        for (let i = 0; i < _pictures.length; i++) {
          pictures.push({
            src: _pictures[i].address,
            width: 1,
            height: 1,
          });
        }
      }
      setPictures(pictures);
      setCount(auth.pictures.count);
      auth.pictures = [];

      setLoading(false);
    }
  }, [auth]);
  const classes = useStyles();

  const content = (
    <div className={classes.section}>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
          <Typography variant="h5" className={classes.title}>
            گالری تصاویر آموزشگاه موسیقی نو
          </Typography>
          <div style={{ marginBottom: "64px" }}></div>
        </GridItem>
        {pictures.length !== 0 && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <ModalCarousel pictures={pictures} />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            shape="rounded"
            count={Math.ceil(count / itemPerPage)}
            page={page}
            size="large"
            className={classes.pagination}
            onChange={(e, page) => {
              setPage(page);
              dispatch(
                getAllPictures({ page: page, itemPerPage: itemPerPage })
              );
              setLoading(true);
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            <Typography variant="h5" className={classes.title}>
              گالری تصاویر آموزشگاه موسیقی نو
            </Typography>
            <div style={{ marginBottom: "64px" }}></div>
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={classes.section}
      >
        <NotFound />
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : pictures.length === 0 ? notFound : content}
        </div>
      </div>
    </div>
  );
}
