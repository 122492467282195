import { createMuiTheme } from "@material-ui/core/styles";

import Input from "./Input";
import SelectInstrument from "./SelectInstrument";
import UploadImage from "./UploadImage";
import Button from "./Button";
import Icons from "./Icons";
import ActionButton from "./ActionButton";

const theme = createMuiTheme({
  //#00a152
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#d32f2f",
    },
    success: {
      main: "#00a152",
    },
  },
});

const Controls = {
  Input,
  SelectInstrument,
  UploadImage,
  Button,
  theme: theme,
  Icons,
  ActionButton,
};

export default Controls;
