import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";
import { Form } from "../../components/dashboard related/useForms";
import Controls from "../../components/dashboard related/controls/Controls";
import Loading from "../../components/common/Loading";
import Notification from "../../components/common/Notification";

//images
import stars from "./../../images/sign.jpg";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAdmin } from "../../actions/adminActions";

const styles = ({ breakpoints, palette }) => {
  const cover = stars;
  const backDropBG = "rgba(0,0,0,0.5)";
  return {
    root: {
      width: "100%",
      textAlign: "right",
      height: "100vh",
      position: "relative",
      background: `url(${cover})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "#ffffff",
      [breakpoints.only("xs")]: {
        position: "relative",
        height: "100vh",
      },
    },
    appBar: {
      background: "none",
    },
    toolbar: {
      minHeight: 64,
    },
    backDrop: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: backDropBG,
    },
    container: {
      height: "100%",
      zIndex: 2,
      position: "relative",
    },
    brand: {
      color: "#ffffff",
      fontWeight: 900,
    },
    left: {
      flexGrow: 1,
    },
    right: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    logo: {
      width: 40,
      height: 40,
      marginLeft: "auto",
      [breakpoints.up("sm")]: {
        marginRight: "auto",
      },
    },
    mlNormal: {
      marginLeft: 16,
    },
    content: {
      padding: 16,
    },
    input: {
      background: "rgba(255,255,255,0.16)",
      "&:hover": {
        background: "rgba(255,255,255,0.24)",
      },
      "&$focused": {
        background: "rgba(255,255,255,0.24)",
      },
    },
    inputInput: {
      color: "#ffffff",
    },
    underline: {
      "&:after": {
        borderColor: palette.primary.light,
      },
    },
    focused: {},
    inputLabel: {
      color: "#ffffff",
      opacity: 0.8,
      "&$focused": {
        opacity: 1,
        color: "#ffffff",
      },
    },
    checkbox: {
      color: "rgba(255,255,255,0.87)",
    },
    checkboxLabel: {
      color: "rgba(255,255,255,0.87)",
    },
    grey: {
      color: "#c5c5c5",
    },
    buttonLabel: {
      textTransform: "none",
    },
    forgetPassword: {
      textAlign: "center",
      color: "#ffffff",
      [breakpoints.only("xs")]: {
        position: "absolute",
        bottom: 32,
        left: "50%",
        transform: "translateX(-50%)",
      },
      [breakpoints.up("sm")]: {
        marginTop: 40,
      },
    },
  };
};

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      notify: {
        isOpen: false,
        message: "",
        type: "",
      },
      errors: {},
      rememberMe: false,
      loading: false,
    };

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setNotify = this.setNotify.bind(this);
  }

  componentDidMount() {
    // this.setState({ loading: false });
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/admin-dashboard");
    // }
    const password = localStorage.getItem("password");
    const username = localStorage.getItem("username");
    if (password && username) {
      this.setState({ username: username });
      this.setState({ password: password });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.setState({ loading: false });
      //this.props.history.push("/admin-dashboard/list-admin");
      window.location.href = "/admin-dashboard/list-admin";
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      nextProps.errors.errors = null;
      this.setNotify({
        isOpen: true,
        message: "نام کاربری یا رمز عبور اشتباه است",
        type: "error",
      });
      this.setState({ loading: false });
      let clearError = setTimeout(() => {
        clearTimeout(clearError);
        this.setState({ errors: {} });
      }, 1000);
    }
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  validate() {
    let temp = { ...this.state.errors };
    if ("username" in this.state)
      temp.username = this.state.username ? "" : "لطفا نام کاربری را وارد کنید";
    if ("password" in this.state)
      temp.password = this.state.password
        ? ""
        : "لطفا رمز عبور خود را وارد کنید";
    this.setState({ errors: { ...temp } });

    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.validate()) {
      const admin = {
        username: this.state.username,
        password: this.state.password,
      };
      if (this.state.rememberMe) {
        localStorage.setItem("username", this.state.username);
        localStorage.setItem("password", this.state.password);
      }

      this.props.loginAdmin(admin);
      this.setState({ loading: true });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} dir="rtl">
        <div className={classes.backDrop} />
        <Grid className={classes.container} container justify={"center"}>
          <AppBar
            className={classes.appBar}
            position={"absolute"}
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Grid container>
                <Grid item xs container alignItems={"center"}>
                  <Typography className={classes.grey}>
                    <Link color={"inherit"} href={"/"} underline={"none"}>
                      بازگشت به صفحه اصلی
                    </Link>
                  </Typography>
                </Grid>
                <Hidden only={"xs"}>
                  <Grid
                    xs
                    item
                    container
                    alignItems={"center"}
                    justify={"flex-end"}
                  ></Grid>
                </Hidden>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid
            className={classes.content}
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            container
            alignItems={"center"}
          >
            <Form onSubmit={this.onSubmit} classes={{}}>
              <Controls.Input
                fullWidth
                label="نام کاربری"
                name="username"
                margin={"normal"}
                variant="filled"
                value={this.state.username}
                onChange={this.onChange}
                error={this.state.errors.username}
                classes={{}}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: classes.focused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                    focused: classes.focused,
                    underline: classes.underline,
                    input: classes.inputInput,
                  },
                }}
              />
              <Controls.Input
                fullWidth
                label="رمز عبور"
                name="password"
                margin={"normal"}
                variant="filled"
                type={"password"}
                value={this.state.password}
                onChange={this.onChange}
                error={this.state.errors.password}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: classes.focused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                    focused: classes.focused,
                    underline: classes.underline,
                    input: classes.inputInput,
                  },
                }}
              />
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox value="checkedC" className={classes.checkbox} />
                  }
                  label="مرا به خاطر بسپار"
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  onChange={() => {
                    this.setState({ rememberMe: !this.state.rememberMe });
                  }}
                />
              </FormControl>
              <FormControl margin={"normal"} fullWidth>
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Loading color="primary" login={true} />
                  </div>
                ) : (
                  <></>
                )}
                <Button
                  classes={{ label: classes.buttonLabel }}
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                >
                  ورود
                </Button>
              </FormControl>
              {/* <div className={classes.forgetPassword}>
                <Typography color={"inherit"}>
                  <Link color={"inherit"}>رمز عبور کرده‌اید؟</Link>
                </Typography>
              </div> */}
            </Form>
          </Grid>
        </Grid>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginAdmin })(
  withStyles(styles)(Login)
);
