import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import PrivateRoute from "../../components/common/PrivateRoute";

export default function DashboardMain() {
  return (
    <Switch>
      <PrivateRoute path="" component={Dashboard} />
      {/* <Route path="">
        <Dashboard />
      </Route> */}
    </Switch>
  );
}
