import React, { Component } from "react";
//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
//Actions
import { getAllWorkshopsPublic } from "../../actions/workshopActions";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import parse from "html-react-parser";
//Date
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";
import { Typography } from "@material-ui/core";
//Images
import Image from "material-ui-image";
//Styling
import { withStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";

class Workshops extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      errors: {},
      page: 1,
      itemPerPage: 6,
      count: 0,
      loading: true,
      description: "",
      headerUrl: "",
      type: "",
      titles: [],
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    const type = this.props.location.pathname.substring(
      this.props.location.pathname.lastIndexOf("/") + 1,
      this.props.location.pathname.length
    );
    this.setState({ type: type });
    SmoothScrollTop();
    this.props.getAllWorkshopsPublic({
      page: this.state.page,
      itemPerPage: this.state.itemPerPage,
    });
    this.props.getAllTitlesPublic();
    this.props.getTextAndHeaderPublic({ tag: "کارگاه" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workshopReducer.records.length !== 0) {
      if (this.state.type === "همه") {
        this.setState({ items: nextProps.workshopReducer.records.workshops });
      } else if (this.state.type === "سازشناسی") {
        this.setState({
          items: nextProps.workshopReducer.records.workshops.filter(
            (workshop) => workshop.sazshenasi
          ),
        });
      } else if (this.state.type === "مربی") {
        this.setState({
          items: nextProps.workshopReducer.records.workshops.filter(
            (workshop) => workshop.tarbiat === true
          ),
        });
      } else {
        this.setState({
          items: nextProps.workshopReducer.records.workshops.filter(
            (workshop) => workshop.other
          ),
        });
      }

      this.setState({ count: nextProps.workshopReducer.records.count });
      this.setState({ loading: false });
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }

    if (nextProps.auth.titles) {
      this.setState({
        titles: nextProps.auth.titles.slice(),
      });
      nextProps.auth.titles = null;
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      nextProps.errors.errors = null;
    }
  }

  onChangePage(page) {
    this.setState({ page });
  }

  render() {
    const { classes } = this.props;
    let content = this.state.items && (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی  صفحه اول کارگاه"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی  صفحه اول کارگاه"
                    )[0].title
                  }
                </h1>
              )}
              <br />
            </GridItem>
            <GridItem xs={12} sm={12} md={7} data-aos="fade-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "زیر تیتر  صفحه اول کارگاه"
              ).length !== 0 && (
                <h2 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "زیر تیتر  صفحه اول کارگاه"
                    )[0].title
                  }
                </h2>
              )}
            </GridItem>
            {this.state.items.length !== 0 &&
              this.state.items
                .slice(
                  (this.state.page - 1) * this.state.itemPerPage,
                  this.state.page * this.state.itemPerPage
                )
                .map((x, i) => (
                  <GridItem xs={12} sm={12} md={4} key={i} data-aos="fade-left">
                    <NavLink
                      to={`/کارگاه/${x._id}`}
                      exact
                      style={{ textDecoration: "none" }}
                    >
                      <Card plain>
                        <CardHeader>
                          <h3 className={classes.cardTitle}>{x.title}</h3>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.cardTitle}
                          >
                            {ConvertToPersian.ConvertDateToPersian(
                              CalculateDate(x.createdAt)
                            )}
                          </Typography>
                        </CardHeader>
                        <CardBody>
                          <Image
                            src={x.poster.address}
                            className={classes.imgRounded}
                          />
                        </CardBody>
                      </Card>
                    </NavLink>
                  </GridItem>
                ))}
            <GridItem xs={12} sm={12} md={12}>
              <Pagination
                shape="rounded"
                count={Math.ceil(this.state.count / this.state.itemPerPage)}
                page={this.state.page}
                size="large"
                className={classes.pagination}
                onChange={(page) => {
                  this.onChangePage(page);
                  this.props.getAllWorkshopsPublic({
                    page: page,
                    itemPerPage: this.state.itemPerPage,
                  });
                }}
              />
            </GridItem>
          </GridContainer>
        </div>
      </>
    );

    let _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    let notFound = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی  صفحه اول کارگاه"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی  صفحه اول کارگاه"
                    )[0].title
                  }
                </h1>
              )}
              <br />
            </GridItem>
            <GridItem xs={12} sm={12} md={7} data-aos="fade-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className={classes.section}
        >
          <NotFound />
        </div>
      </>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading
              ? _loading
              : this.state.items.length === 0
              ? notFound
              : content}
          </div>
        </div>
      </div>
    );
  }
}

Workshops.propTypes = {
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  workshopReducer: PropTypes.object.isRequired,
  getAllWorkshopsPublic: PropTypes.func.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
  getAllTitlesPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workshopReducer: state.workshopReducer,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllWorkshopsPublic,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
})(withStyles(styles)(Workshops));
