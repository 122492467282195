import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import CardBody from "../../components/Main Website/Card/CardBody";
import smoothScrollTop from "../../utils/SmoothScrollToTop";
import NotFound from "../../components/common/NotFound";
import { getTextAndHeaderPublic } from "../../actions/adminActions";
import { getAInstructorById } from "../../actions/instructorActions";
//Images
import ZoomImage from "../../shared/components/ZoomImage";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

function InstructorItem() {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [myInstructor, setMyInstructor] = React.useState();

  const auth = useSelector((state) => state.auth);
  const instructor = useSelector((state) => state.instructor);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    smoothScrollTop();
    dispatch(getAInstructorById({ instructor_id: id }));
    dispatch(getTextAndHeaderPublic({ tag: "اساتید" }));
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }
  }, [auth]);

  useEffect(() => {
    if (instructor.instructor) {
      let myInstructor = instructor.instructor;
      instructor.instructor = null;
      setMyInstructor(myInstructor);
      setLoading(false);
    }
  }, [instructor]);

  useEffect(() => {
    if (errors.errors) {
      setLoading(false);
    }
  }, [errors]);

  const classes = useStyles();

  const content = myInstructor && (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            <h1 className={classes.title}>{myInstructor.name}</h1>
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-left">
            <Card plain>
              <ZoomImage
                gallery={true}
                src={myInstructor.picture.address}
                className={classes.imgRounded}
                alt=""
              />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} data-aos="zoom-in-right">
            <div className={classes.sideParagraph}>
              {parse(`${myInstructor.description}`)}
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            <h2
              className={classes.title}
            >{`ویدئو اجرای ${myInstructor.name}`}</h2>
          </GridItem>
          {myInstructor.videoClips.length !== 0 &&
            myInstructor.videoClips.map((x, i) => (
              <GridItem xs={12} sm={12} md={10} key={i} data-aos="zoom-in-up">
                <Card plain>
                  <CardBody>
                    <VideoPlayer src={x.address} style={classes.video} />
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className={classes.section}
            >
              <NotFound />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl}></Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : !myInstructor ? notFound : content}
        </div>
      </div>
    </div>
  );
}

export default InstructorItem;
