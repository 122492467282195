import types from "../actions/types";

const initialState = {
  instructors: [],
  new: false,
  instructor: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_INSTRUCTOR:
      return {
        ...state,
        instructors: action.payload,
      };
    case types.GET_A_INSTRUCTOR:
      return {
        ...state,
        instructor: action.payload,
      };
    case types.DELETE_AN_INSTRUCTOR:
      return {
        ...state,
        deleteAnItem: true,
        _id: action.payload,
      };
    case types.NEW_AZ_INSTRUCTOR:
      return {
        ...state,
        new: true,
      };
    default:
      return state;
  }
}
