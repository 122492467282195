import React, { useEffect } from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { getTextAndHeaderPublic } from "../../actions/adminActions";
import { getAllDepAzConcertsPublic } from "../../actions/azActions";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import styles from "../../assets/jss/views/landingPage";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

import { Lightbox } from "react-modal-image";
import { Pagination } from "@material-ui/lab";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Image from "material-ui-image";

const useStyles = makeStyles(styles);

export default function AzConcerts() {
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [azmoons, setAzmoons] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(8);

  const auth = useSelector((state) => state.auth);
  const azAll = useSelector((state) => state.azAll);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getTextAndHeaderPublic({ tag: "آزمون" }));
    dispatch(
      getAllDepAzConcertsPublic({ page: page, itemPerPage: itemPerPage })
    );
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }
  }, [auth]);

  useEffect(() => {
    if (azAll.records.length !== 0) {
      let azmoons = azAll.records[0].azConcerts.slice();
      setAzmoons(azmoons);
      setCount(azAll.records[0].count);
      setLoading(false);
    }
  }, [azAll]);

  const classes = useStyles();

  const content = (
    <div className={classes.section}>
      <GridContainer justify="flex-start" spacing={2}>
        <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
          <h1 className={classes.title}>آزمون‌های دپارتمان کودک و نوجوان</h1>
          <br />
        </GridItem>
        {azmoons.length !== 0 &&
          azmoons.map((x, i) => (
            <GridItem key={i} xs={12} sm={12} md={3} data-aos="fade-left">
              <NavLink
                to={`/آزمون/دپارتمان/ساز/${x._id}`}
                exact
                style={{ textDecoration: "none" }}
              >
                <Card plain>
                  <CardHeader>
                    <h3 className={classes.cardTitle}>{x.title}</h3>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      className={classes.cardTitle}
                    >
                      {ConvertToPersian.ConvertDateToPersian(
                        CalculateDate(x.createdAt)
                      )}
                    </Typography>
                  </CardHeader>
                  <CardBody>
                    <Image
                      src={x.poster.address}
                      className={classes.imgRounded}
                    />
                  </CardBody>
                </Card>
              </NavLink>
            </GridItem>
          ))}
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            shape="rounded"
            count={Math.ceil(count / itemPerPage)}
            page={page}
            size="large"
            className={classes.pagination}
            onChange={(e, page) => {
              setPage(page);
              dispatch(
                getAllDepAzConcertsPublic({
                  page: page,
                  itemPerPage: itemPerPage,
                })
              );
              setLoading(true);
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  const notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            <h1 className={classes.title}>آزمون‌های دپارتمان کودک و نوجوان</h1>
            <br />
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={classes.section}
      >
        <NotFound />
      </div>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : azmoons.length === 0 ? notFound : content}
          {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
        </div>
      </div>
    </div>
  );
}
