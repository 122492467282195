import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Card from "../../components/Main Website/Card/Card";
import CardBody from "../../components/Main Website/Card/CardBody";
import Loading from "../../components/common/Loading";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";
import ZoomImage from "../../shared/components/ZoomImage";
import { Lightbox } from "react-modal-image";
import { useEffect } from "react";
import { getDiplomaPublic } from "../../actions/diplomaActions";
import { getTextAndHeaderPublic } from "../../actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";

const useStyles = makeStyles(styles);

function AllDiplomas() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [imageId, setImageId] = React.useState();
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [diplomas, setDiplomas] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(9);

  const diplomaReducer = useSelector((state) => state.diplomaReducer);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getTextAndHeaderPublic({ tag: "دیپلم" }));
    dispatch(getDiplomaPublic());
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }
  }, [auth]);

  useEffect(() => {
    if (diplomaReducer.diploma) {
      let diploma = diplomaReducer.diploma;
      diplomaReducer.diploma = null;
      var _diplomas = [];
      if (diploma.diplomas) {
        for (let i = 0; i < diploma.diplomas.length; i++) {
          _diplomas.push({
            address: diploma.diplomas[i].address,
            thumbnail: diploma.diplomas[i].address,
          });
        }
      }
      setDiplomas(_diplomas);
      setLoading(false);
    }
  }, [diplomaReducer]);

  const classes = useStyles();

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  const content = diplomas.length !== 0 && (
    <div className={classes.section}>
      <GridContainer justify="flex-start">
        {diplomas
          .slice((page - 1) * itemPerPage, page * itemPerPage)
          .map((x, i) => (
            <GridItem key={i} xs={12} sm={12} md={3} data-aos="zoom-in-up">
              <Card plain>
                <CardBody>
                  <ZoomImage
                    src={x.address}
                    className={classes.imgRounded}
                    gallery={true}
                    alt=""
                  />
                </CardBody>
              </Card>
            </GridItem>
          ))}

        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            shape="rounded"
            count={Math.ceil(diplomas.length / itemPerPage)}
            page={page}
            size="large"
            className={classes.pagination}
            onChange={(e, page) => setPage(page)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : content}
          {open && (
            <Lightbox
              medium={diplomas[imageId].address}
              onClose={() => setOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AllDiplomas;
