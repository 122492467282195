import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { getLandingData } from "../../actions/adminActions";
import { makeStyles } from "@material-ui/core/styles";
import HeaderSlider from "../../components/Main Website/Parallax/HeaderSlider";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Sections
import Map from "./Sections/Map/Map";
import Introduction from "./Sections/Introduction/Introduction";
import Instruments from "./Sections/Instruments/Instruments";
import All from "./Sections/all/All";
import styles from "../../assets/jss/views/landingPage";
const useStyles = makeStyles(styles);

function Homepage() {
  const [landingHeaderImages, setLandingHeaderImages] = React.useState([]);
  const [titles, setTitles] = React.useState([]);
  const [landingDesc, setlandingDesc] = React.useState("");
  const [festivalHeaderUrl, setFestivalHeaderUrl] = React.useState("");
  const [festivalDesc, setFestivalDesc] = React.useState("");
  const [azHeaderUrl, setAzHeaderUrl] = React.useState("");
  const [azDesc, setAzDesc] = React.useState("");
  const [workshopHeaderUrl, setWorkshopHeaderUrl] = React.useState("");
  const [workshopDesc, setWorkshopDesc] = React.useState("");
  const [diplomaHeaderUrl, setDiplomaHeaderUrl] = React.useState("");
  const [diplomaDesc, setDiplomaDesc] = React.useState("");
  const [instruments, setInstruments] = React.useState([]);
  const [azmoon, setAzmoon] = React.useState();
  const [festival, setFestival] = React.useState();
  const [workshop, setWorkshop] = React.useState();
  const [news, setNews] = React.useState();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getLandingData());
  }, []);

  useEffect(() => {
    if (auth.landingData) {
      if (auth.landingData.instruments.length !== 0) {
        let instruments = auth.landingData.instruments.slice();
        setInstruments(instruments);
      }
      if (auth.landingData.titles.length !== 0) {
        setTitles(auth.landingData.titles.slice());
      }

      if (auth.landingData.azConcert.length !== 0) {
        setAzmoon(auth.landingData.azConcert[0]);
      }
      if (auth.landingData.festival.length !== 0) {
        setFestival(auth.landingData.festival[0]);
      }
      if (auth.landingData.workshop.length !== 0) {
        setWorkshop(auth.landingData.workshop[0]);
      }
      if (auth.landingData.news.length !== 0) {
        setNews(auth.landingData.news[0]);
      }
      if (auth.landingData.header) {
        setLandingHeaderImages(auth.landingData.header.pictures.slice());
      }

      if (auth.landingData.textHeaders.length !== 0) {
        text_desc(auth.landingData.textHeaders);
      }
      if (auth.landingData.header) {
        setlandingDesc(auth.landingData.header.description);
      }

      auth.landingData = null;
    }
  }, [auth]);

  const text_desc = (data) => {
    var address = "";
    var description = "";
    let td = "";
    td = data.filter((t) => t.tag === "معرفی دیپلم");
    address = td.length !== 0 ? td[0].picture.address : "";
    setDiplomaHeaderUrl(address);
    td = data.filter((t) => t.tag === "معرفی دیپلم");
    description = td.length !== 0 ? td[0].description : "";
    setDiplomaDesc(description);
    td = data.filter((t) => t.tag === "معرفی دیپلم");
    address = td.length !== 0 ? td[0].picture.address : "";
    setDiplomaHeaderUrl(address);
    td = data.filter((t) => t.tag === "معرفی آزمون");
    description = td.length !== 0 ? td[0].description : "";
    setAzDesc(description);
    td = data.filter((t) => t.tag === "معرفی آزمون");
    address = td.length !== 0 ? td[0].picture.address : "";
    setAzHeaderUrl(address);

    td = data.filter((t) => t.tag === "معرفی فستیوال");
    address = td.length !== 0 ? td[0].picture.address : "";
    setFestivalHeaderUrl(address);
    td = data.filter((t) => t.tag === "معرفی فستیوال");
    description = td.length !== 0 ? td[0].description : "";
    setFestivalDesc(description);

    td = data.filter((t) => t.tag === "معرفی کارگاه");
    address = td.length !== 0 ? td[0].picture.address : "";
    setWorkshopHeaderUrl(address);
    td = data.filter((t) => t.tag === "معرفی کارگاه");
    description = td.length !== 0 ? td[0].description : "";
    setWorkshopDesc(description);
  };

  const classes = useStyles();

  return (
    <div>
      <HeaderSlider images={landingHeaderImages}></HeaderSlider>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Introduction
            workshopHeaderUrl={workshopHeaderUrl}
            workshopDesc={workshopDesc}
            festivalHeaderUrl={festivalHeaderUrl}
            festivalDesc={festivalDesc}
            azHeaderUrl={azHeaderUrl}
            azDesc={azDesc}
            landingDesc={landingDesc}
            titles={titles}
          />
          {instruments.length !== 0 && (
            <Instruments
              instruments={instruments}
              diplomaHeaderUrl={diplomaHeaderUrl}
              diplomaDesc={diplomaDesc}
            />
          )}
          <All
            azmoon={azmoon}
            festival={festival}
            news={news}
            workshop={workshop}
          />
          <Map />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
