import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import Icons from "../controls/Icons";
import SidebarItem from "./SidebarItem";
import Collapse from "./Collapse";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingRight: theme.spacing(4),
  },
});

class SidebarItems extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
      concert: false,
      workshop: false,
      festival: false,
      instrument: false,
      instructor: false,
      management: false,
      textandheader: false,
      diploma: false,
      news: false,
      santourHouse: false,
      titles: false,
      gallery: false,
    };
  }

  render() {
    const { classes, _open } = this.props;
    return (
      <List component="nav" className={classes.root}>
        <SidebarItem
          primary="ادمین"
          onClick={() => this.setState({ admin: !this.state.admin })}
          open={this.state.admin}
        />
        <Collapse
          to="/admin-dashboard/new-admin"
          _in={this.state.admin && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ایجاد ادمین جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-admin"
          _in={this.state.admin && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست ادمین‌ها"
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="آزمون-کنسرت"
          onClick={() => this.setState({ concert: !this.state.concert })}
          open={this.state.concert}
        />
        <Collapse
          to="/admin-dashboard/new-az-concert"
          _in={this.state.concert && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ساخت آزمون کنسرت جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-az-concert"
          _in={this.state.concert && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست آزمون-کنسرت‌ها"
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="کارگاه آموزشی"
          onClick={() => this.setState({ workshop: !this.state.workshop })}
          open={this.state.workshop}
        />
        <Collapse
          to="/admin-dashboard/new-workshop"
          _in={this.state.workshop && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ساخت کارگاه آموزشی جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-workshops"
          _in={this.state.workshop && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست کارگاه‌های آموزشی"
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="فستیوال"
          onClick={() => this.setState({ festival: !this.state.festival })}
          open={this.state.festival}
        />
        <Collapse
          to="/admin-dashboard/new-festival"
          _in={this.state.festival && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ساخت  فستیوال جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-festival"
          _in={this.state.festival && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست فستیوال‌ها "
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="سازها"
          onClick={() => this.setState({ instrument: !this.state.instrument })}
          open={this.state.instrument}
        />
        <Collapse
          to="/admin-dashboard/new-instrument"
          _in={this.state.instrument && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ساخت  ساز جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-instruments"
          _in={this.state.instrument && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست سازها "
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="اساتید"
          onClick={() => this.setState({ instructor: !this.state.instructor })}
          open={this.state.instructor}
        />
        <Collapse
          to="/admin-dashboard/new-instructor"
          _in={this.state.instructor && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="اضافه کردن استاد جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-instructors"
          _in={this.state.instructor && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست اساتید "
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="مدیریت"
          onClick={() => this.setState({ management: !this.state.management })}
          open={this.state.management}
        />
        <Collapse
          to="/admin-dashboard/new-manager"
          _in={this.state.management && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="اضافه کردن مدیر جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-managers"
          _in={this.state.management && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست مدیران "
          icon={<Icons.ListIcon color="primary" />}
        />
        <SidebarItem
          primary="خبر"
          onClick={() => this.setState({ news: !this.state.news })}
          open={this.state.news}
        />
        <Collapse
          to="/admin-dashboard/news"
          _in={this.state.news && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="اضافه کردن خبر جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-news"
          _in={this.state.news && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="لیست اخبار"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <SidebarItem
          primary="خانه سنتور"
          onClick={() =>
            this.setState({ santourHouse: !this.state.santourHouse })
          }
          open={this.state.santourHouse}
        />
        <Collapse
          to="/admin-dashboard/edit-santour-house"
          _in={this.state.santourHouse && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ویرایش محتوا"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <SidebarItem
          primary="متن و عکس هدر"
          onClick={() =>
            this.setState({ textandheader: !this.state.textandheader })
          }
          open={this.state.textandheader}
        />
        <Collapse
          to="/admin-dashboard/text-header"
          _in={this.state.textandheader && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="اضافه کردن متن و هدر جدید"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <SidebarItem
          primary="عناوین"
          onClick={() => this.setState({ titles: !this.state.titles })}
          open={this.state.titles}
        />
        <Collapse
          to="/admin-dashboard/titles"
          _in={this.state.titles && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ویرایش عناوین"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <SidebarItem
          primary="صفحه دیپلم"
          onClick={() => this.setState({ diploma: !this.state.diploma })}
          open={this.state.diploma}
        />
        <Collapse
          to="/admin-dashboard/diploma"
          _in={this.state.diploma && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="اضافه کردن محتوا"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <Collapse
          to="/admin-dashboard/list-diplomas"
          _in={this.state.diploma && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ویرایش محتوا"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
        <SidebarItem
          primary="گالری"
          onClick={() => this.setState({ gallery: !this.state.gallery })}
          open={this.state.gallery}
        />
        <Collapse
          to="/admin-dashboard/edit-gallery"
          _in={this.state.gallery && _open}
          timeout="auto"
          unmountOnExit
          component="div"
          disablePadding
          button
          classes={classes.nested}
          primary="ویرایش عکس ها"
          icon={<Icons.AddBoxIcon color="primary" />}
        />
      </List>
    );
  }
}

SidebarItems.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarItems);
