import React, { useEffect } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Fab, Grid, Paper, Tooltip } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import { updateDiploma } from "../../../actions/diplomaActions";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function DiplomaForm() {
  const [pictures, setPictures] = React.useState([]);
  const [diplomas, setDiplomas] = React.useState([]);
  const [videos, setVideos] = React.useState([""]);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [openDiplomaPicture, setOpenDiplomaPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const diplomaReducer = useSelector((state) => state.diplomaReducer);
  const dispatch = useDispatch();

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const reset = () => {
    setDiplomas([]);
    setPictures([]);
    setVideos([""]);
    setOpenPicture(false);
    setOpenDiplomaPicture(false);
    setLoading(false);
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };

  const validate = () => {
    let temp = { ..._errors };

    temp.pictures =
      !pictures.length > 0 && !diplomas.length > 0 && !videos.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا حداقل برای یک فیلد مقداری را انتخاب کنید",
            type: "error",
          })
        : "";

    setErrors({ ...temp });
    const errors_ = Object.values(temp).filter((x) => x !== "");
    if (errors_.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormDataa();
    formData.append(
      "pictures",
      pictures.length > 0 ? JSON.stringify(pictures) : []
    );
    formData.append(
      "diplomas",
      diplomas.length > 0 ? JSON.stringify(diplomas) : []
    );
    formData.append(
      "videoClips",
      videos.length > 0 && videos[0].length !== 0 ? JSON.stringify(videos) : []
    );
    dispatch(updateDiploma(formData));
    //reset();
    setLoading(true);
  };

  useEffect(() => {
    if (diplomaReducer.new) {
      setNotify({
        isOpen: true,
        message: "اطلاعات  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [diplomaReducer]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);
  const classes = useStyles();
  const content = (
    <>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            {videos.length !== 0 &&
              videos.map((x, i) => (
                <>
                  {" "}
                  <Controls.Input
                    variant="outlined"
                    name="videos"
                    label="آدرس آپارات ویدئو"
                    value={x}
                    onChange={(e) => {
                      const { value } = e.target;
                      const list = [...videos];
                      list[i] = value;
                      setVideos(list);
                    }}
                    error={_errors.videos}
                    key={i}
                  />
                  {videos.length > 0 ? (
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        if (videos.length > 1) {
                          const list = [...videos];
                          list.splice(i, 1);
                          setVideos(list);
                        }
                      }}
                      key={i + 1}
                    >
                      <DeleteIcon fontSize="small" />
                    </Controls.ActionButton>
                  ) : (
                    <></>
                  )}
                  {videos.length - 1 === i ? (
                    <Grid key={i + 2}>
                      <Tooltip
                        title="اضافه کردن یک ویدئو جدید"
                        aria-label="add"
                        onClick={() => {
                          setVideos([...videos, ""]);
                        }}
                        placement="top"
                      >
                        <Fab color="primary">
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </Grid>
          <Grid item xs={12} md={10}>
            <Controls.UploadImage
              text="انتخاب تصاویر"
              name="uploadImage"
              label="آپلود تصاویر"
              dialogTitle="آپلود تصاویر"
              value={pictures}
              //onChange={(e) => {}}
              acceptedFiles={["image/*"]}
              fileObjects={pictures}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPicture}
              onClose={() => setOpenPicture(!openPicture)}
              onSave={() => setOpenPicture(!openPicture)}
              onClick={() => setOpenPicture(!openPicture)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObj) => {
                setPictures(pictures.concat(newFileObj));
              }}
              onDelete={(deleteFileObj) => {
                const index = pictures.indexOf(deleteFileObj);
                setPictures(
                  pictures.filter((pic) => pictures.indexOf(pic) !== index)
                );
              }}
              filesLimit={100}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
            />
            <Controls.UploadImage
              text="انتخاب تصاویر دیپلم‌ها"
              name="uploadDiplomas"
              label="آپلود تصاویر دیپلم‌ها"
              dialogTitle="آپلود تصاویر دیپلم‌ها"
              value={diplomas}
              //onChange={this.onChange}
              acceptedFiles={["image/*"]}
              fileObjects={diplomas}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openDiplomaPicture}
              onClose={() => setOpenDiplomaPicture(!openDiplomaPicture)}
              onSave={() => setOpenDiplomaPicture(!openDiplomaPicture)}
              onClick={() => setOpenDiplomaPicture(!openDiplomaPicture)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObj) => {
                setDiplomas(diplomas.concat(newFileObj));
              }}
              onDelete={(deleteFileObj) => {
                const index = diplomas.indexOf(deleteFileObj);
                setDiplomas(
                  diplomas.filter((pic) => diplomas.indexOf(pic) !== index)
                );
              }}
              filesLimit={100}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
            />
          </Grid>
          <Grid item>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره اطلاعات اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره اطلاعات"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
            <Controls.Button
              text="پاک کردن همه مقادیر"
              color="secondary"
              onClick={reset}
              startIcon={<Controls.Icons.DeleteIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      <Paper className={classes.paper}>{loading ? _loading : content}</Paper>
    </Grid>
  );
}

export default DiplomaForm;
