import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputOnChange,
    resetForm,
  };
}

export function Form(props) {
  const { children, classes, ...other } = props;
  const useStyles = makeStyles((theme) => classes);
  const _classes = useStyles();
  return (
    <form className={_classes.root} autoComplete="off" {...other}>
      {children}
    </form>
  );
}
