import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Paper } from "@material-ui/core";
// core components
import Button from "../../../../components/Main Website/CustomButtons/Button";
import CustomDropdown from "../../../../components/Main Website/CustomDropdown/CustomDropdown";
import styles from "../../../../assets/jss/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

function Links(props) {
  const [manager, setManager] = React.useState(false);

  const { handleDrawerToggle } = props;

  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              صفحه نخست
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/آزمون"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              آزمون-کنسرت
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to={`/کارگاه ها/همه`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              کارگاه آموزشی
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/فستیوال"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              فستیوال
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/اساتید"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              معرفی اساتید
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/خانه سنتور"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              خانه سنتور
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/دپارتمان"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              دپارتمان کودک
            </Button>
          </Link>
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => setManager(!manager)}
        >
          <CustomDropdown
            noLiPadding
            buttonText="مدیریت"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            dropdownList={[
              <Link
                to="/مدیر آموزشگاه"
                className={classes.dropdownLink}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                مدیر آموزشگاه
              </Link>,
              <Link to="/مدیر دپارتمان" className={classes.dropdownLink}>
                مدیر دپارتمان موسیقی کودک و نوجوان
              </Link>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/اخبار"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              اخبار آموزشگاه
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/دیپلم"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              دیپلم موسیقی
            </Button>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to="/گالری"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={handleDrawerToggle}
            >
              گالری
            </Button>
          </Link>
        </ListItem>
      </List>
    </Paper>
  );
}

export default Links;
