import { Grid, Paper, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import PropTypes from "prop-types";
import ManagerForm from "./ManagerForm";

const styles = (theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

class Manager extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} md={8} lg={10}>
        <Paper className={classes.paper}>
          <ManagerForm />
        </Paper>
      </Grid>
    );
  }
}

Manager.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Manager);
