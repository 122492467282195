import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import {
  AppBar,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronRight from "@material-ui/icons/ChevronRight";

import SidebarItems from "../../components/dashboard related/sidebar items/SidebarItems";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AdminsForm from "../dashboard/Admin/AdminsForm";
import AdminEdit from "../dashboard/Admin/AdminEdit";
import AdminList from "../dashboard/Admin/AdminList";
import AzConcert from "../dashboard/AzConcerts/AzConcert";
import Workshop from "../dashboard/Workshops/Workshop";
import Festival from "../dashboard/Festivals/Festival";
import Instrument from "../dashboard/Instrument/Instrument";
import Instructor from "../dashboard/Instructor/Instructor";
import Manager from "../dashboard/Management/Manager";
import TextAndHeader from "../dashboard/TextAndHeader/TextAndHeader";
import Titles from "../dashboard/TextAndHeader/Titles";
import DiplomaForm from "../dashboard/Diploma/DiplomaForm";
import News from "../dashboard/News/News";
import ManagerList from "../dashboard/Management/ManagerList";
import ManagerEdit from "../dashboard/Management/ManagerEdit";
import InstructorList from "../dashboard/Instructor/InstructorList";
import InstrumentList from "../dashboard/Instrument/InstrumentList";
import AzConcertList from "../dashboard/AzConcerts/AzConcertList";
import WorkshopList from "../dashboard/Workshops/WorkshopList";
import FestivalList from "../dashboard/Festivals/FestivalList";
import DiplomaList from "../dashboard/Diploma/DiplomaList";
import NewsList from "../dashboard/News/NewsList";
import NewsEdit from "../dashboard/News/NewsEdit";
import DiplomaEdit from "../dashboard/Diploma/DiplomaEdit";
import AzConcertEdit from "../dashboard/AzConcerts/AzConcertEdit";
import AzConcertInstrumentEdit from "../dashboard/AzConcerts/AzConcertInstrumentEdit";
import FestivalEdit from "../dashboard/Festivals/FestivalEdit";
import WorkshopEdit from "../dashboard/Workshops/WorkshopEdit";
import InstrumentEdit from "../dashboard/Instrument/InstrumentEdit";
import InstructorEdit from "../dashboard/Instructor/InstructorEdit";
import SantourHouse from "../dashboard/Santour House/SantourHouse";
import GalleryEdit from "../dashboard/Gallery/GalleryEdit";
import clsx from "clsx";

import ConfirmDialogue from "../../components/common/ConfirmDialogue";
import { logoutAdmin } from "../../actions/adminActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    //position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(30),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(5),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  searchInput: {
    paddingRight: `0px ${theme.spacing(1)}px`,
    marginRight: theme.spacing(4),
    fontSize: "0.8rem",
    borderRadius: "5px",
    backgroundColor: "lightBlue",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
}));

function Dashboard({ logoutAdmin }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"));

  if (!currentAdmin) {
    currentAdmin = {
      username: "کاربر",
    };
  }

  return (
    <div dir="rtl">
      <>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Grid container alignItems="center">
              <Grid item xs sm></Grid>
              <Grid item>
                <Typography>{currentAdmin.username}</Typography>
              </Grid>
              <Grid item>
                <AccountCircleIcon
                  color="inherit"
                  style={{ marginRight: "4px", marginLeft: "8px" }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  color="inherit"
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "آیا می‌خواهید خارج شوید؟",
                      subTitle: "",
                    })
                  }
                >
                  <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="right"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRight />
            </IconButton>
          </div>

          <List>{<SidebarItems _open={open} />}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Route
                exact
                path="/admin-dashboard/new-admin"
                component={AdminsForm}
              />
              <Route
                exact
                path="/admin-dashboard/new-az-concert"
                component={AzConcert}
              />
              <Route
                exact
                path="/admin-dashboard/new-workshop"
                component={Workshop}
              />
              <Route
                exact
                path="/admin-dashboard/new-festival"
                component={Festival}
              />
              <Route
                exact
                path="/admin-dashboard/new-instrument"
                component={Instrument}
              />
              <Route
                exact
                path="/admin-dashboard/new-instructor"
                component={Instructor}
              />
              <Route
                exact
                path="/admin-dashboard/new-manager"
                component={Manager}
              />
              <Route
                exact
                path="/admin-dashboard/text-header"
                component={TextAndHeader}
              />
              <Route exact path="/admin-dashboard/titles" component={Titles} />
              <Route
                exact
                path="/admin-dashboard/diploma"
                component={DiplomaForm}
              />
              <Route exact path="/admin-dashboard/news" component={News} />
              <Route
                exact
                path="/admin-dashboard/list-admin"
                component={AdminList}
              />
              <Route
                exact
                path="/admin-dashboard/list-az-concert"
                component={AzConcertList}
              />
              <Route
                exact
                path="/admin-dashboard/list-workshops"
                component={WorkshopList}
              />
              <Route
                exact
                path="/admin-dashboard/list-festival"
                component={FestivalList}
              />
              <Route
                exact
                path="/admin-dashboard/list-instruments"
                component={InstrumentList}
              />
              <Route
                exact
                path="/admin-dashboard/list-instructors"
                component={InstructorList}
              />
              <Route
                exact
                path="/admin-dashboard/list-managers"
                component={ManagerList}
              />
              <Route
                exact
                path="/admin-dashboard/list-diplomas"
                component={DiplomaList}
              />
              <Route
                exact
                path="/admin-dashboard/list-news"
                component={NewsList}
              />
              <Route
                exact
                path="/admin-dashboard/edit-admin/:id"
                component={AdminEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-diploma/:id"
                component={DiplomaEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-news/:id"
                component={NewsEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-azmoon/:id"
                component={AzConcertEdit}
              />
              <Route
                exact
                path="/admin-dashboard/azmoon-concert-edit/:id/:number"
                component={AzConcertInstrumentEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-festival/:id"
                component={FestivalEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-workshop/:id"
                component={WorkshopEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-instrument/:id"
                component={InstrumentEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-instructor/:id"
                component={InstructorEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-manager/:id"
                component={ManagerEdit}
              />
              <Route
                exact
                path="/admin-dashboard/edit-santour-house"
                component={SantourHouse}
              />
              <Route
                exact
                path="/admin-dashboard/edit-gallery"
                component={GalleryEdit}
              />
            </Grid>
            <ConfirmDialogue
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              onConfirm={() => {
                logoutAdmin();
                window.location.href = "/admin-login";
              }}
            />
          </Container>
        </main>
      </>
    </div>
  );
}

Dashboard.propTypes = {
  logoutAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const Dash = connect(mapStateToProps, { logoutAdmin })(Dashboard);
export default Dash;
