import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import {
  Collapse,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from "@material-ui/core";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { newTitle, getAllTitlesPublic } from "../../../actions/adminActions";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function Titles() {
  const _titles = {
    landing: {
      instroduction: {
        title: "عنوان فعالیت‌ها",
        value: "فعالیت‌های آموزشگاه موسیقی نو",
      },
      instruments: {
        title: "عنوان رشته‌های آموزشی",
        value: "رشته‌های آموزشی و اساتید",
      },
      all: {
        title: "عنوان آخرین مطالب",
        value: "آخرین مطالب",
      },
      map: {
        title: "عنوان نقشه",
        value: "مکان آموزشگاه بر روی نقشه",
      },
    },
    azmoons: {
      mainPage: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
      },
      instruments: {
        subTitle: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
      },
      item: {
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
        subTitle3: {
          title: "زیر تیتر سوم",
          value: "عنوان نمونه",
        },
      },
    },
    workshops: {
      mainPage: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
      },
      item: {
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
      },
    },
    festivals: {
      mainPage: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
      },
      item: {
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
      },
    },
    instructors: {
      mainPage: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
      },
      instrument: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
      },
    },
    santourHouse: {
      mainPage: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
      },
      sahamiWorks: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
      },
      studentsWorks: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
        subTitle1: {
          title: "زیر تیتر اول",
          value: "عنوان نمونه",
        },
        subTitle2: {
          title: "زیر تیتر دوم",
          value: "عنوان نمونه",
        },
      },
      notes: {
        mainTitle: {
          title: "تیتر اصلی",
          value: "عنوان نمونه",
        },
      },
    },
  };

  const azmoonTags = ["تیتر اصلی صفحه اول آزمون", "زیر تیتر صفحه اول آزمون"];
  const azmoonInstrumentTags = ["زیر تیتر صفحه دوم آزمون"];
  const azmoonItem = [
    "زیر تیتر اول صفحه سوم آزمون",
    "زیر تیتر دوم صفحه سوم آزمون",
    "زیر تیتر سوم صفحه سوم آزمون",
  ];

  const diplomaTags = ["تیتر اصلی صفحه دیپلم", "زیر تیتر صفحه دیپلم"];

  const festivalTags = [
    "تیتر اصلی صفحه اول فستیوال",
    "زیر تیتر صفحه اول فستیوال",
  ];
  const festivalItemTags = [
    "زیر تیتر دوم  صفحه دوم فستیوال",
    "زیر تیتر اول  صفحه دوم فستیوال",
  ];

  const workshopTags = [
    "تیتر اصلی  صفحه اول کارگاه",
    "زیر تیتر  صفحه اول کارگاه",
  ];
  const workshopItemTags = ["زیر تیتر صفحه دوم کارگاه"];

  const instructorTags = [
    "تیتر اصلی صفحه اساتید",
    "زیر تیتر اول صفحه اساتید",
    "زیر تیتر دوم صفحه اساتید",
  ];

  const depManagerTag = ["تیتر اصلی صفحه مدیر دپارتمان"];
  const insManagerTag = ["تیتر اصلی صفحه مدیر آموزشگاه"];

  const landingTags = [
    "تیتر اصلی صفحه لندینگ",
    "تیتر بخش معرفی آزمون",
    "تیتر بخش معرفی کارگاه",
    "تیتر بخش معرفی فستیوال",
  ];

  const departmentTags = [
    "تیتر اصلی صفحه دپارتمان",
    "تیتر بخش اساتید",
    "تیتر بخش سایر فعالیت‌ها",
    "تیتر بخش آزمون‌ها",
    "تیتر بخش مدیریت",
    "تیتر بخش کارگاه‌های سازشناسی",
    "تیتر بخش دوره‌های تربیت مربی",
  ];

  const santourHouseTags = [
    "تیتر اصلی صفحه خانه سنتور",
    "تیتر بخش مدیریت خانه سنتور",
    "تیتر بخش آثار استاد سهامی",
    "تیتر بخش آثار هنرجویان",
    "تیتر بخش نت‌های منتشر شده",
  ];

  const newsTags = ["تیتر اصلی صفحه اخبار"];

  const [azmoonPages, setAzmoonPages] = React.useState(false);
  const [workshopPages, setWorkshopPages] = React.useState(false);
  const [festivalPages, setFestivalPages] = React.useState(false);
  const [managementPages, setManagementPages] = React.useState(false);
  const [titles, setTitles] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const auth = useSelector((state) => state.auth);
  const _errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTitlesPublic());
  }, []);

  useEffect(() => {
    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
      setLoading(false);
    }

    if (auth.added) {
      auth.added = false;
      setNotify({
        isOpen: true,
        message: "با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (_errors.errors) {
      setErrors(_errors.errors);
      let err =
        typeof _errors.errors.data.error !== "undefined"
          ? _errors.errors.data.error
          : _errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      _errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [_errors]);

  const reset = () => {
    setErrors({});
    setTag("");
    setTitle("");
    setLoading(false);
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const formData = new FormData();
    formData.append("tag", tag);
    formData.append("title", title);
    dispatch(newTitle(formData));
    reset();
    setLoading(true);
  };

  const validate = () => {
    let temp = {};
    temp.tag = tag ? "" : "لطفا صفحه را انتخاب کنید";
    temp.title = title ? "" : "لطفا عنوان را وارد کنید";

    setErrors({ ...temp });

    const _errors = Object.values(temp).filter((x) => x !== "");
    if (_errors.length === 0) {
      return true;
    }
    return false;
  };

  const setValue = (tag) => {
    try {
      let val = titles.filter((t) => t.tag === tag)[0].title;
      setTitle(val);
    } catch (error) {
      setTitle("");
    }
  };

  const classes = useStyles();

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  const azmoonSection = (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={azmoonPages}
            onChange={() => setAzmoonPages(!azmoonPages)}
          />
        }
        label="صفحات آزمون-کنسرت"
      />

      <Collapse in={azmoonPages}>
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه اصلی آزمون-کنسرت"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={azmoonTags}
          error={errors.tag}
        />
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه دوم آزمون-کنسرت"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={azmoonInstrumentTags}
          error={errors.tag}
        />
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه سوم آزمون-کنسرت"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={azmoonItem}
          error={errors.tag}
        />
      </Collapse>
    </>
  );

  const workshopSection = (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={workshopPages}
            onChange={() => setWorkshopPages(!workshopPages)}
          />
        }
        label="صفحات کارگاه‌های آموزشی"
      />

      <Collapse in={workshopPages}>
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه اصلی کارگاه‌های آموزشی"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={workshopTags}
          error={errors.tag}
        />
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه دوم کارگاه‌های آموزشی"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={workshopItemTags}
          error={errors.tag}
        />
      </Collapse>
    </>
  );

  const festivalSection = (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={festivalPages}
            onChange={() => setFestivalPages(!festivalPages)}
          />
        }
        label="صفحات فستیوال"
      />

      <Collapse in={festivalPages}>
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه اصلی فستیوال"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={festivalTags}
          error={errors.tag}
        />
        <Controls.SelectInstrument
          name="tag"
          label="تیترهای صفحه دوم فستیوال"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={festivalItemTags}
          error={errors.tag}
        />
      </Collapse>
    </>
  );

  const ManagementSection = (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={managementPages}
            onChange={() => setManagementPages(!managementPages)}
          />
        }
        label="صفحات مدیریت"
      />

      <Collapse in={managementPages}>
        <Controls.SelectInstrument
          name="tag"
          label="تیتر صفحه مدیریت آموزشگاه"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={insManagerTag}
          error={errors.tag}
        />
        <Controls.SelectInstrument
          name="tag"
          label="تیتر صفحه مدیریت دپارتمان"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
            setValue(e.target.value);
          }}
          options={depManagerTag}
          error={errors.tag}
        />
      </Collapse>
    </>
  );

  const content = (
    <>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: "8px",
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={10}>
            <Controls.SelectInstrument
              name="tag"
              label="تیترهای صفحه لندینگ"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={landingTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="تیترهای صفحه خانه سنتور"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={santourHouseTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="تیترهای صفحه دپارتمان"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={departmentTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="تیترهای صفحه اصلی اساتید"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={instructorTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="تیترهای صفحه اصلی دیپلم موسیقی"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={diplomaTags}
              error={errors.tag}
            />
            <Controls.SelectInstrument
              name="tag"
              label="تیتر اصلی صفحه اخبار"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setValue(e.target.value);
              }}
              options={newsTags}
              error={errors.tag}
            />
            {azmoonSection}
            {workshopSection}
            {festivalSection}
            {ManagementSection}
          </Grid>

          <Grid item xs={12} md={10}>
            <Controls.Input
              variant="outlined"
              name="title"
              label="عنوان"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={errors.title}
            />
          </Grid>

          <Grid item>
            <Controls.Button
              onClick={() => {
                if (validate()) {
                  setConfirmDialog({
                    isOpen: true,
                    title: "آیا از ذخیره تغییرات اطمینان دارید؟",
                    subTitle: "",
                  });
                }
              }}
              text="ذخیره تغییرات"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
            <Controls.Button
              text="پاک کردن همه مقادیر"
              color="secondary"
              onClick={reset}
              startIcon={<Controls.Icons.DeleteIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirm={onSubmit}
      />
    </>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      <Paper className={classes.paper}>{loading ? _loading : content}</Paper>
    </Grid>
  );
}

export default Titles;
