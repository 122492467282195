import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
//import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Custom Material-UI class name generator.
//const generateClassName = createGenerateClassName();

function RTL(props) {
  return (
    // <JssProvider jss={jss} generateClassName={generateClassName}>
    //   {props.children}
    // </JssProvider>
    <StylesProvider jss={jss}>{props.children}</StylesProvider>
  );
}

export default RTL;
