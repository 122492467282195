import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const newWorkshop = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/workshops/new-workshop`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_WORKSHOP,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editWorkshop = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/workshops/edit-workshop`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_WORKSHOP,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAWorkshopById = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/workshops/get-workshop-by-id`, data)
    .then((result) => {
      const workshop = result.data;
      dispatch({
        type: types.GET_A_WORKSHOPS,
        payload: workshop,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllWorkshops = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/workshops/get-all-workshops`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const workshops = result.data;
      dispatch({
        type: types.GET_ALL_WORKSHOPS,
        payload: workshops,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllWorkshopsPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/workshops/get-all-workshops-public`, data)
    .then((result) => {
      const workshops = result.data;
      dispatch({
        type: types.GET_ALL_WORKSHOPS,
        payload: workshops,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteworkshop = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/workshops/delete-a-workshop`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_A_WORKSHOP,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};
