import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAzmoonById } from "../../actions/azActions";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import ZoomImage from "../../shared/components/ZoomImage";
import { Lightbox } from "react-modal-image";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

function AzConcertItem() {
  const [address, setAddress] = React.useState("");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [item, setItem] = React.useState();
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const azAll = useSelector((state) => state.azAll);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    SmoothScrollTop();
    let number = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    setNumber(number);
    const id = location.pathname
      .match(new RegExp("/" + "(.*)" + "/" + "(.*)" + "/" + "(.*)" + "/"))[3]
      .trim();
    dispatch(getAzmoonById({ azmoon_id: id }));
    dispatch(getAllTitlesPublic());
    dispatch(getTextAndHeaderPublic({ tag: "آزمون" }));
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  useEffect(() => {
    if (azAll.azmoon) {
      let azmoon = azAll.azmoon;
      azAll.azmoon = null;

      setItem(azmoon.items[number]);

      setLoading(false);
    }
  }, [azAll]);

  const classes = useStyles();

  const content = item && (
    <>
      <div className={classes.section} data-aos="zoom-in-up">
        <GridContainer justify="center"></GridContainer>
        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-down">
          <h1 className={classes.title}>
            {`آزمون-کنسرت هنرجویان ساز ${item.instrument}`}
          </h1>
          <br />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
          <Card plain>
            <CardBody>
              <VideoPlayer src={item.videoClip} style={classes.video} />
            </CardBody>
          </Card>
        </GridItem>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {item.pictures.length !== 0 && (
            <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-down">
              {titles.filter((t) => t.tag === "زیر تیتر اول صفحه سوم آزمون")
                .length !== 0 && (
                <h2 className={classes.title}>
                  {
                    titles.filter(
                      (t) => t.tag === "زیر تیتر اول صفحه سوم آزمون"
                    )[0].title
                  }
                </h2>
              )}
              <br />
            </GridItem>
          )}
          {item.pictures.length !== 0 &&
            item.pictures.map((x, i) => (
              <GridItem xs={12} sm={12} md={3} key={i} data-aos="zoom-in-up">
                <Card plain style={{ borderRadius: "6px" }}>
                  <ZoomImage
                    alt=""
                    src={x.address}
                    className={classes.imgRounded}
                    gallery={true}
                  />
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {item.topStudents.length !== 0 && (
            <>
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-down">
                {titles.filter((t) => t.tag === "زیر تیتر دوم صفحه سوم آزمون")
                  .length !== 0 && (
                  <h2 className={classes.title}>
                    {
                      titles.filter(
                        (t) => t.tag === "زیر تیتر دوم صفحه سوم آزمون"
                      )[0].title
                    }
                  </h2>
                )}
              </GridItem>
              {item.topStudents.map((x, i) => (
                <GridItem xs={12} sm={12} md={3} key={i} data-aos="zoom-in-up">
                  <Card plain>
                    <CardHeader>
                      <h3
                        className={classes.cardTitle}
                        style={{ color: "black" }}
                      >
                        {x.name}
                      </h3>
                    </CardHeader>
                    <ZoomImage
                      alt=""
                      style={{ width: "100%", heigth: "100%" }}
                      src={x.picture ? x.picture.address : ""}
                      className={classes.imgRounded}
                      gallery={true}
                    />
                  </Card>
                </GridItem>
              ))}
            </>
          )}
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {item.results.length !== 0 && (
            <>
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-down">
                {titles.filter((t) => t.tag === "زیر تیتر سوم صفحه سوم آزمون")
                  .length !== 0 && (
                  <h2 className={classes.title}>
                    {
                      titles.filter(
                        (t) => t.tag === "زیر تیتر سوم صفحه سوم آزمون"
                      )[0].title
                    }
                  </h2>
                )}
              </GridItem>
              {item.results.map((x, i) => (
                <GridItem xs={12} sm={12} md={3} key={i} data-aos="zoom-in-up">
                  <Card plain>
                    <ZoomImage
                      alt=""
                      style={{ width: "100%", heigth: "100%" }}
                      src={x.address}
                      className={classes.imgRounded}
                    />
                  </Card>
                </GridItem>
              ))}
            </>
          )}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax filter image={headerUrl}></Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : content}
          {openPopup && (
            <Lightbox
              medium={address}
              alt="تصویر بارگذاری نشد. دوباره تلاش کنید"
              onClose={() => setOpenPopup(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AzConcertItem;
