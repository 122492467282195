import React, { Component } from "react";
//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
//Actions
import {
  getInstructorsByInstrument,
  getDepartmentInstructors,
} from "../../actions/instructorActions";
import { getTextAndHeaderPublic } from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import smoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import Image from "material-ui-image";
//Styling
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/views/landingPage";

class InstrumentInstructors extends Component {
  constructor() {
    super();
    this.state = {
      myInstrument: "",
      instructors: [],
      myInstructors: [],
      loading: true,
      errors: {},
      headerUrl: "",
      type: "",
      loading: true,
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    smoothScrollTop();
    let targetInstrument = this.props.location.pathname.substring(
      this.props.location.pathname.lastIndexOf("/") + 1,
      this.props.location.pathname.length
    );

    targetInstrument === "دپارتمان"
      ? this.props.getDepartmentInstructors()
      : this.props.getInstructorsByInstrument({
          instrumentName: targetInstrument,
        });
    targetInstrument === "دپارتمان"
      ? this.setState({ type: "department" })
      : this.setState({ type: "other" });

    this.props.getTextAndHeaderPublic({ tag: "اساتید" });

    this.setState({ myInstrument: targetInstrument });
    this.setState({ loading: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instructor.instructors.length !== 0) {
      const instructors = nextProps.instructor.instructors.slice();
      this.setState({ loading: false });
      this.setState({ myInstructors: instructors });
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      nextProps.errors.errors = null;
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }
  }

  onChangePage(e, page) {
    this.setState({ page });
  }

  render() {
    const { classes } = this.props;
    const content = (
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
            <h1 className={classes.title}>
              {this.state.type === "department"
                ? `اساتید دپارتمان کودک آموزشگاه موسیقی نو`
                : `اساتید ساز ${this.state.myInstrument} آموزشگاه موسیقی نو`}
            </h1>
          </GridItem>
          {this.state.myInstructors.length !== 0 &&
            this.state.myInstructors.map((x, i) => (
              <GridItem xs={12} sm={12} md={4} key={i} data-aos="fade-up">
                <NavLink
                  to={`/اساتید/${x._id}`}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  <Card plain>
                    <CardHeader>
                      <h3 className={classes.cardTitle}>{`${x.name}`}</h3>
                    </CardHeader>
                    <CardBody>
                      <Image
                        src={x.picture.address}
                        className={classes.imgRounded}
                      />
                    </CardBody>
                  </Card>
                </NavLink>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    );

    const _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    let notFound = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={false} sm={false} md={12} data-aos="fade-down">
              <h1 className={classes.title}>
                {this.state.type === "department"
                  ? `اساتید دپارتمان کودک آموزشگاه موسیقی نو`
                  : `اساتید ساز ${this.state.myInstrument} آموزشگاه موسیقی نو`}
              </h1>
            </GridItem>
          </GridContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className={classes.section}
        >
          <NotFound />
        </div>
      </>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading
              ? _loading
              : this.state.myInstructors.length === 0
              ? notFound
              : content}
          </div>
        </div>
      </div>
    );
  }
}

InstrumentInstructors.propTypes = {
  errors: PropTypes.object.isRequired,
  instructor: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getInstructorsByInstrument: PropTypes.func.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
  getDepartmentInstructors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  instructor: state.instructor,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getInstructorsByInstrument,
  getTextAndHeaderPublic,
  getDepartmentInstructors,
})(withStyles(styles)(InstrumentInstructors));
