import React, { useEffect } from "react";
import { getAllInstruments } from "../../../actions/instrumentActions";
import {
  editInstructor,
  getAInstructorById,
} from "../../../actions/instructorActions";
import { useSelector, useDispatch } from "react-redux";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Form } from "../../../components/dashboard related/useForms";
import Loading from "../../../components/common/Loading";
import Card from "../../../components/Main Website/Card/Card";
import Editor from "../Editor";
import Controls from "../../../components/dashboard related/controls/Controls";
import Image from "material-ui-image";
import { Lightbox } from "react-modal-image";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { useLocation } from "react-router-dom";

const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

export default function InstructorEdit() {
  const [address, setAddress] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [instructorId, setInstructorId] = React.useState("");
  const [instrument, setInstrument] = React.useState("");
  const [videoClips, setVideoClips] = React.useState([]);
  const [santourHouse, setSantourHouse] = React.useState(false);
  const [department, setDepartment] = React.useState(false);
  const [master, setMaster] = React.useState(false);
  const [_instruments, setInstruments] = React.useState([]);
  const [picture, setPicture] = React.useState([]);
  const [newsId, setInstrumentId] = React.useState();
  const [newPicture, setNewPicture] = React.useState([]);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    name: "",
    subname: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const instructorReducer = useSelector((state) => state.instructor);
  const instruments = useSelector((state) => state.instruments);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    dispatch(getAllInstruments());
    dispatch(getAInstructorById({ instructor_id: id }));
  }, []);

  useEffect(() => {
    if (instructorReducer.instructor) {
      const instructor = instructorReducer.instructor;
      instructorReducer.instructor = null;
      var _picture = [];
      const pic = { address: instructor.picture.address };
      _picture.push(pic);
      setInstructorId(instructor._id);
      setPicture(_picture);
      setName(instructor.name);
      setDescription(instructor.description);
      setDepartment(instructor.department);
      setSantourHouse(instructor.santourHouse);
      setMaster(instructor.master);
      setVideoClips(instructor.videoClips);
      setInstrument(instructor.instrumentName);
      setLoading(false);
    }
    if (instructorReducer.new) {
      instructorReducer.new = false;
      setNotify({
        isOpen: true,
        message: "استاد  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [instructorReducer]);

  useEffect(() => {
    if (instruments.instruments) {
      const arrayOfInstruments = instruments.instruments.slice();
      const arrayOfNames = [];
      for (let i = 0; i < arrayOfInstruments.length; i++) {
        arrayOfNames.push(arrayOfInstruments[i].name);
      }
      setInstruments(arrayOfNames);
    }
  }, [instruments]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const validate = () => {
    let temp = {};
    temp.name = name ? "" : "لطفا نام استاد را وارد کنید";
    temp.description = description ? "" : "لطفا متن معرفی استاد را وارد کنید";
    temp.videoClips =
      videoClips.length > 0
        ? ""
        : setNotify({
            isOpen: true,
            message: "لطفا حداقل یک آدرس  فیلم برای اجرای این استاد وارد کنید",
            type: "error",
          });
    temp.instrument = instrument ? "" : "لطفا یکی از سازها را انتخاب کنید";
    temp.picture =
      !picture.length > 0 && !newPicture.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا  یک تصویر برای این استاد انتخاب کنید",
            type: "error",
          })
        : "";
    setErrors({ ...temp });

    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("instrument", instrument);
    formData.append("videoClips", JSON.stringify(videoClips));
    formData.append(
      "picture",
      newPicture.length > 0 ? JSON.stringify(newPicture[0]) : "empty"
    );
    formData.append(
      "currentPicture",
      picture.length > 0 ? JSON.stringify(picture[0]) : "empty"
    );
    master
      ? formData.append("master", "master")
      : formData.append("master", "other");
    santourHouse
      ? formData.append("santourHouse", "santourHouse")
      : formData.append("santourHouse", "other");
    department
      ? formData.append("department", "department")
      : formData.append("department", "other");
    formData.append("instructor_id", instructorId);
    dispatch(editInstructor(formData));
    setLoading(true);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...videoClips];
    list[index].address = value;
    setVideoClips(list);
  };

  const handleRemoveClick = (index) => {
    if (videoClips.length > 0) {
      const list = [...videoClips];
      list.splice(index, 1);
      setVideoClips(list);
    }
  };

  const handleAddClick = () => {
    setVideoClips([...videoClips, { address: "" }]);
  };

  const classes = useStyles();

  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              تصویر استاد
            </h3>
          </Grid>
          {picture.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(picture[0].address);
                      setOpen(true);
                    }}
                    src={picture[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setPicture([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Controls.UploadImage
              text="انتخاب تصویر جدید"
              name="uploadImage"
              label="آپلود تصویر جدید"
              dialogTitle="آپلود تصویر جدید"
              value={newPicture}
              acceptedFiles={[]}
              fileObjects={newPicture}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPicture}
              onClose={() => setOpenPicture(!setOpenPicture)}
              onSave={() => setOpenPicture(!setOpenPicture)}
              onClick={() => setOpenPicture(!openPicture)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPicture(newPicture.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPicture.indexOf(deleteFileObj);
                setNewPicture(
                  newPicture.filter((pic) => newPicture.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              مشخصات استاد
            </h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Controls.Input
              variant="outlined"
              name="name"
              label="نام استاد"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={_errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Editor
              value={description}
              onEditorChange={(e) => setDescription(e)}
              placeholder="توضیحات معرفی استاد"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {_instruments.length > 0 && (
              <Controls.SelectInstrument
                name="instrument"
                label="انتخاب ساز"
                value={instrument}
                onChange={(e) => setInstrument(e.target.value)}
                options={_instruments}
                error={_errors.instrument}
              />
            )}
          </Grid>
          {videoClips.length !== 0 &&
            videoClips.map((x, i) => (
              <Grid item xs={12} md={10} key={i}>
                <Controls.Input
                  variant="outlined"
                  name="videoClip"
                  label="آدرس آپارات ویدئو"
                  value={x.address}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errors.videoClips}
                />
                {videoClips.length > 0 && (
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Controls.ActionButton>
                )}
              </Grid>
            ))}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              marginBottom: "16px",
              marginTop: "16px",
              marginRight: "8px",
            }}
          >
            <Tooltip
              title="اضافه کردن یک ویدئو جدید"
              aria-label="add"
              onClick={handleAddClick}
              placement="top"
            >
              <Fab color="primary">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={master}
                  onChange={() => setMaster(!master)}
                  name=" checked"
                />
              }
              label="از اساتید برجسته"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={department}
                  checked={santourHouse}
                  onChange={() => setSantourHouse(!santourHouse)}
                  name="santourHouse"
                />
              }
              label="از اساتید خانه سنتور"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={santourHouse}
                  checked={department}
                  onChange={() => setDepartment(!department)}
                  name="department"
                />
              }
              label="از اساتید دپارتمان کودک"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controls.Button
              onClick={() =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا از ذخیره این استاد اطمینان دارید؟",
                  subTitle: "",
                })
              }
              text="ذخیره این استاد"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}
