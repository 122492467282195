import types from "../actions/types";

const initialState = {
  new: false,
  poster: {},
  title: "",
  videoClip: "",
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.NEW_AZ_CONCERT:
      return {
        ...state,
        new: true,
      };
    case types.NEW_ITEM:
      return {
        ...state,
        items: [...this.state.items, action.payload],
      };
    default:
      return state;
  }
}
