import React, { useEffect, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Grid,
  Typography,
  Box,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import smoothScrollTop from "../../utils/SmoothScrollToTop";
import ZoomImage from "../../shared/components/ZoomImage";
import { useSelector, useDispatch } from "react-redux";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import Loading from "../../components/common/Loading";
import NewsCard from "./NewsCard";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import {
  getTextAndHeaderPublic,
  getNewsById,
  getNewsLimit,
} from "../../actions/adminActions";
import parse from "html-react-parser";
import Styles from "../../assets/jss/views/landingPage";
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

const useStyles = makeStyles(Styles);

const styles = (theme) => ({
  blogContentWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    maxWidth: 1280,
    width: "100%",
  },
  wrapper: {
    minHeight: "20vh",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  card: {
    boxShadow: theme.shadows[4],
  },
});

function NewsItemm(props) {
  const { classes } = props;
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [item, setItem] = React.useState();
  const [news, setNews] = React.useState([]);
  const [newsDate, setNewsDate] = React.useState();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    smoothScrollTop();
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    dispatch(getNewsById({ news_id: id }));
    dispatch(getNewsLimit());
    dispatch(getTextAndHeaderPublic({ tag: "اخبار" }));
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.aNews) {
      let news = auth.aNews;
      auth.aNews = null;
      setItem(news);

      let date = CalculateDate(news.createdAt);
      date = ConvertToPersian.ConvertDateToPersian(date);

      setNewsDate(date);
      setLoading(false);
    }

    if (auth.news.length !== 0) {
      const news = auth.news.slice();
      setNews(news);
      auth.news = [];
    }
  }, [auth]);

  const myClasses = useStyles();

  const content = item && (
    <>
      <div className={classes.blogContentWrapper}>
        <GridContainer
          className={classNames("lg-p-top", classes.wrapper)}
          justify="flex-start"
          spacing={5}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Box>
              <Box pt={3} pr={3} pl={3} pb={2}>
                <Typography variant="h4">
                  <b
                    className={myClasses.title}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {item.title}
                  </b>
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {newsDate}
                </Typography>
              </Box>
              <ZoomImage
                className={classes.img}
                src={item.picture.address}
                alt=""
              />
              <Box p={1}>
                <div className={myClasses.paragraph}>
                  {parse(`${item.description}`)}
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={false} sm={false} md={1} />
          <Grid item xs={12} sm={12} md={3} className={myClasses.sideNews}>
            <Typography
              variant="h6"
              className={myClasses.title}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              سایر اخبار
            </Typography>
            {news.map((x) => (
              <Box key={x._id} mb={3}>
                <NewsCard
                  title={x.title}
                  snippet={x.description}
                  date={x.createdAt}
                  url={`/اخبار/${x._id}`}
                />
              </Box>
            ))}
          </Grid>
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax image={headerUrl}></Parallax>
      <div className={classNames(myClasses.main, myClasses.mainRaised)}>
        <div className={myClasses.container}>
          {loading ? _loading : content}
        </div>
      </div>
    </div>
  );
}

NewsItemm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsItemm);
