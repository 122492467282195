import React, { Component } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import {
  Grid,
  Tooltip,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/Add";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loading from "../../../components/common/Loading";

import { getAllInstruments } from "../../../actions/instrumentActions";
import { newAzInstrument } from "../../../actions/azActions";

import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00a152",
    },
  },
});

const styles = {
  root: {
    width: "100%",
    marginBottom: "16px",
    marginTop: "16px",
    backgroundColor: "#e0f7fa",
  },
  sendButton: {
    marginLeft: "",
  },
};

const initialState = {
  instrument: "",
  videoClip: "",
  topStudents: [],
  pictures: [],
  poster: [],
  results: [],
  azId: "",
  instruments: [],
  loading: false,
  open: false,
  openPicture: [],
  openResult: false,
  openPoster: false,
  santourHouse: false,
  department: false,
  notify: {
    isOpen: false,
    message: "",
    type: "",
  },
  confirmDialog: {
    isOpen: false,
    title: "",
    subTitle: "",
  },
  errors: {},
};

class AzConcertInstrumentForm extends Component {
  constructor() {
    super();
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setOpenResults = this.setOpenResults.bind(this);
    this.setOpenPicture = this.setOpenPicture.bind(this);
    this.reset = this.reset.bind(this);
    this.validate = this.validate.bind(this);
    this.setOpenPoster = this.setOpenPoster.bind(this);
    this.setConfirmDialogue = this.setConfirmDialogue.bind(this);
    this.setNotify = this.setNotify.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.props.getAllInstruments();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      let err =
        typeof nextProps.errors.data.error !== "undefined"
          ? nextProps.errors.data.error
          : nextProps.errors.data;
      this.setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
      let errorTimeout = setTimeout(() => {
        this.setState({ errors: {} });
        clearTimeout(errorTimeout);
      }, 4000);
    }

    if (nextProps.instruments.instruments) {
      const arrayOfInstruments = nextProps.instruments.instruments.slice();
      const arrayOfNames = [];
      for (let i = 0; i < arrayOfInstruments.length; i++) {
        arrayOfNames.push(arrayOfInstruments[i].name);
      }
      this.setState({ instruments: arrayOfNames });
      this.setState({ loading: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  setOpenPoster(e) {
    this.setState({ openPoster: !this.state.openPoster });
  }

  setOpen() {
    this.setState({ open: !this.state.open });
  }

  setOpenPicture(i) {
    const list = [...this.state.openPicture];
    list[i] = !list[i];
    this.setState({ openPicture: list });
  }

  setOpenResults() {
    this.setState({ openResult: !this.state.openResult });
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  setConfirmDialogue(data) {
    const conDialogue = {
      isOpen: data.isOpen,
      title: data.title,
      subTitle: data.subTitle,
    };
    this.setState({ confirmDialog: conDialogue });
  }

  handleInputChange(e, index, target) {
    const { value } = e.target;
    const list = [...this.state.topStudents];
    target === "name"
      ? (list[index].name = value)
      : (list[index].videoUrl = value);
    this.setState({ topStudents: list });
  }

  handleRemoveClick(index) {
    if (this.state.topStudents.length > 0) {
      const list = [...this.state.topStudents];
      list.splice(index, 1);
      this.setState({ topStudents: list });
    }
  }

  handleAddClick() {
    this.setState({
      topStudents: [
        ...this.state.topStudents,
        { name: "", videoUrl: "", picture: [] },
      ],
    });
  }

  reset() {
    this.setState(initialState);
  }

  onSubmit(e, recordForEdit) {
    e.preventDefault();
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, isOpen: false },
    });
    if (this.validate()) {
      var formData = new FormData();
      formData.append(
        "topStudents",
        this.state.topStudents.length > 0
          ? JSON.stringify(this.state.topStudents)
          : "empty"
      );
      formData.append("instrument", this.state.instrument);
      formData.append("videoClip", this.state.videoClip);
      formData.append(
        "pictures",
        this.state.pictures.length > 0
          ? JSON.stringify(this.state.pictures)
          : "empty"
      );
      formData.append("poster", JSON.stringify(this.state.poster[0]));
      formData.append(
        "results",
        this.state.results.length > 0
          ? JSON.stringify(this.state.results)
          : "empty"
      );
      formData.append("azConcert_id", recordForEdit);
      this.state.santourHouse
        ? formData.append("santourHouse", "santourHouse")
        : formData.append("santourHouse", "other");
      this.state.department
        ? formData.append("department", "department")
        : formData.append("department", "other");
      this.setState({ loading: true });
      this.props.newAzInstrument(formData);
    }
  }

  validate() {
    let temp = { ...this.state.errors };
    if (this.state.topStudents.length > 0) {
      for (let i = 0; i < this.state.topStudents.length; i++) {
        if (!this.state.topStudents[i].name) {
          this.setNotify({
            isOpen: true,
            message: `لطفا نام هنرجوی برتر ${i + 1} را وارد کنید`,
            type: "error",
          });
          temp.topStudents = "نام هنرجو را وارد کنید";
          break;
        } else if (this.state.topStudents[i].picture.length === 0) {
          this.setNotify({
            isOpen: true,
            message: `لطفا تصویر هنرجوی برتر ${i + 1} را وارد کنید`,
            type: "error",
          });
          temp.topStudentsVideo = "خطا";
          break;
        } else {
          temp.topStudents = "";
        }
      }
    }
    if ("videoClip" in this.state)
      temp.videoClip = this.state.videoClip
        ? ""
        : "لطفا آدرس فیلم این آزمون را وارد کنید";
    if ("instrument" in this.state)
      temp.instrument =
        this.state.instrument.length !== 0
          ? ""
          : "لطفا یکی از سازها را انتخاب کنید";
    if ("poster" in this.state)
      temp.poster =
        this.state.poster.length > 0
          ? ""
          : this.setNotify({
              isOpen: true,
              message:
                "لطفا یک تصویر به عنوان پوستر آزمون-کنسرت این ساز انتخاب کنید",
              type: "error",
            });
    this.setState({ errors: { ...temp } });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  }
  //Card className={classes.root}
  render() {
    const { classes } = this.props;
    let content = (
      <div dir="rtl">
        <Form
          onSubmit={(e, recordForEdit) => this.onSubmit(e, recordForEdit)}
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} md={10}>
              <div>
                {this.state.topStudents.length !== 0 &&
                  this.state.topStudents.map((x, i) => (
                    <div key={i + 5}>
                      {" "}
                      <Controls.Input
                        variant="outlined"
                        name="topStudents"
                        label="نام هنرجو برتر"
                        value={x.name}
                        onChange={(e) => this.handleInputChange(e, i, "name")}
                        error={this.state.errors.topStudents}
                      />
                      <Controls.Input
                        variant="outlined"
                        name="topStudents"
                        label="آدرس ویدئو اجرای هنرجو برتر(در صورت وجود)"
                        value={x.videoUrl}
                        onChange={(e) =>
                          this.handleInputChange(e, i, "videoUrl")
                        }
                        //error={this.state.errors.topStudents[i]}
                      />
                      <Controls.UploadImage
                        text="انتخاب تصویر هنرجو"
                        name="uploadImage"
                        label="آپلود تصویر هنرجو"
                        dialogTitle="آپلود تصویر هنرجو"
                        value={this.state.topStudents[i].picture}
                        //onChange={this.onChange}
                        acceptedFiles={["image/*"]}
                        fileObjects={this.state.topStudents[i].picture}
                        cancelButtonText="انصراف"
                        submitButtonText="تایید"
                        maxFileSize={50000000}
                        open={this.state.openPicture[i]}
                        onClose={() => this.setOpenPicture(i)}
                        onSave={() => this.setOpenPicture(i)}
                        onClick={() => this.setOpenPicture(i)}
                        startIcon={<Controls.Icons.CloudUploadIcon />}
                        onAdd={(newFileObjs) => {
                          var list = [...this.state.topStudents];
                          list[i].picture.push(newFileObjs[0]);
                          this.setState({
                            topStudents: list,
                          });
                        }}
                        onDelete={(deleteFileObj) => {
                          const index =
                            this.state.topStudents[i].picture.indexOf(
                              deleteFileObj
                            );

                          var list = [...this.state.topStudents];

                          list[i].picture = list[i].picture.filter(
                            (pic) => list[i].picture.indexOf(pic) !== index
                          );

                          this.setState({
                            topStudents: list,
                          });
                        }}
                        filesLimit={1}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        clearOnUnmount={false}
                        //error={this.state.errors.topStudents[i]}
                      />
                      {this.state.topStudents.length > 0 ? (
                        <Controls.Button
                          text="حذف"
                          color="secondary"
                          onClick={() => this.handleRemoveClick(i)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
              </div>
            </Grid>
            <Grid item xs={12} md={10}>
              <Tooltip
                title="اضافه کردن یک هنرجوی برتر "
                aria-label="add"
                onClick={this.handleAddClick}
                placement="top"
              >
                <Fab color="primary">
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={10}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.Input
                variant="outlined"
                name="videoClip"
                label="آدرس آپارات ویدئو"
                value={this.state.videoClip}
                onChange={this.onChange}
                error={this.state.errors.videoClip}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.SelectInstrument
                name="instrument"
                label="انتخاب ساز"
                value={this.state.instrument}
                onChange={this.onChange}
                options={this.state.instruments}
                error={this.state.errors.instrument}
              />
            </Grid>
            <Grid item item xs={12} md={10}>
              <Controls.UploadImage
                text="انتخاب تصاویر نتایج"
                name="uploadImage"
                label="آپلود تصاویر نتایج"
                dialogTitle="آپلود تصاویر نتایج"
                value={this.state.results}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.results}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.openResult}
                onClose={this.setOpenResults}
                onSave={this.setOpenResults}
                onClick={this.setOpenResults}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObjs) => {
                  this.setState({
                    results: this.state.results.concat(newFileObjs),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.results.indexOf(deleteFileObj);
                  this.setState({
                    results: this.state.results.filter(
                      (pic) => this.state.results.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={200}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
                error={this.state.errors.results}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ marginBottom: "8px", marginTop: "32px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.department}
                    checked={this.state.santourHouse}
                    onChange={() =>
                      this.setState({ santourHouse: !this.state.santourHouse })
                    }
                    name="santourHouse"
                  />
                }
                label="از کنسرت‌های خانه سنتور"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.santourHouse}
                    checked={this.state.department}
                    onChange={() =>
                      this.setState({ department: !this.state.department })
                    }
                    name="department"
                  />
                }
                label="از کنسرت‌های دپارتمان کودک و نوجوان"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controls.UploadImage
                text="انتخاب تصاویر"
                name="uploadImage"
                label="آپلود تصاویر"
                dialogTitle="آپلود تصاویر"
                value={this.state.pictures}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.pictures}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.open}
                onClose={this.setOpen}
                onSave={this.setOpen}
                onClick={this.setOpen}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObjs) => {
                  this.setState({
                    pictures: this.state.pictures.concat(newFileObjs),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.pictures.indexOf(deleteFileObj);
                  this.setState({
                    pictures: this.state.pictures.filter(
                      (pic) => this.state.pictures.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={100}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
                error={this.state.errors.pictures}
              />
              <Controls.UploadImage
                text="انتخاب پوستر"
                name="uploadPoster"
                label="آپلود پوستر"
                dialogTitle="آپلود پوستر"
                value={this.state.poster}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.poster}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.openPoster}
                onClose={this.setOpenPoster}
                onSave={this.setOpenPoster}
                onClick={this.setOpenPoster}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObjs) => {
                  this.setState({
                    poster: this.state.poster.concat(newFileObjs),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.poster.indexOf(deleteFileObj);
                  this.setState({
                    poster: this.state.poster.filter(
                      (pic) => this.state.poster.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={1}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
                error={this.state.errors.poster}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Tooltip
                title="تایید"
                aria-label="add"
                onClick={(e) =>
                  this.setConfirmDialogue({
                    isOpen: true,
                    title: "آیا این آزمون-کنسرت ذخیره شود؟",
                    subTitle: "",
                  })
                }
                placement="top"
              >
                <Fab color="primary" className={classes.sendButton}>
                  <SendIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        </Form>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
        <ConfirmDialogue
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialogue}
          onConfirm={(e) => this.onSubmit(e, this.props.recordForEdit)}
        />
      </div>
    );
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loading color="primary" />
          </div>
        ) : (
          content
        )}
      </>
    );
  }
}

AzConcertInstrumentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  azCon: PropTypes.object.isRequired,
  instruments: PropTypes.object.isRequired,
  getAllInstruments: PropTypes.func.isRequired,
  newAzInstrument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  azCon: state.azCon,
  instruments: state.instruments,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllInstruments, newAzInstrument })(
  withStyles(styles)(AzConcertInstrumentForm)
);
