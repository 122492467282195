import React, { Component } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Divider,
  Fab,
  Grid,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { newInstructor } from "../../../actions/instructorActions";
import { getAllInstruments } from "../../../actions/instrumentActions";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import Editor from "../Editor";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {},
});

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
});

const initialState = {
  name: "",
  instrument: "",
  videoClips: [""],
  picture: [],
  description: "",
  instruments: [],
  checked: false,
  santourHouse: false,
  department: false,
  createdAt: Date(),
  open: false,
  openPoster: false,
  loading: false,
  notify: {
    isOpen: false,
    message: "",
    type: "",
  },
  confirmDialog: {
    isOpen: false,
    title: "",
    subTitle: "",
  },
  errors: {},
};

class InstructorForm extends Component {
  constructor() {
    super();
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.reset = this.reset.bind(this);
    this.validate = this.validate.bind(this);
    this.setConfirmDialogue = this.setConfirmDialogue.bind(this);
    this.setNotify = this.setNotify.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.props.getAllInstruments();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instructor.new) {
      nextProps.instructor.new = false;
      this.setNotify({
        isOpen: true,
        message: "استاد  با موفقیت ذخیره شد",
        type: "success",
      });
      this.setState({ loading: false });
    }

    if (nextProps.instruments.instruments) {
      const arrayOfInstruments = nextProps.instruments.instruments.slice();
      const arrayOfNames = [];
      for (let i = 0; i < arrayOfInstruments.length; i++) {
        arrayOfNames.push(arrayOfInstruments[i].name);
      }
      this.setState({ instruments: arrayOfNames });
    }

    if (nextProps.errors.errors) {
      this.setState({ errors: nextProps.errors.errors });
      let err =
        typeof nextProps.errors.data.error !== "undefined"
          ? nextProps.errors.data.error
          : nextProps.errors.data;
      this.setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
      let errorTimeout = setTimeout(() => {
        this.setState({ errors: {} });
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }

  setConfirmDialogue(data) {
    if (this.validate()) {
      const conDialogue = {
        isOpen: data.isOpen,
        title: data.title,
        subTitle: data.subTitle,
      };
      this.setState({ confirmDialog: conDialogue });
    }
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeVideoClip(i, e) {
    var arr = this.state.videoClips;
    arr[i].value = e.target.value;
    this.setState({ videoClips: arr });
  }

  setOpen() {
    this.setState({ open: !this.state.open });
  }

  reset() {
    this.setState(initialState);
  }

  handleInputChange(e, index) {
    const { value } = e.target;
    const list = [...this.state.videoClips];
    list[index] = value;
    this.setState({ videoClips: list });
  }

  handleRemoveClick(index) {
    if (this.state.videoClips.length > 1) {
      const list = [...this.state.videoClips];
      list.splice(index, 1);
      this.setState({ videoClips: list });
    }
  }

  handleAddClick() {
    this.setState({ videoClips: [...this.state.videoClips, ""] });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, isOpen: false },
    });

    const formData = new FormDataa();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("instrument", this.state.instrument);
    for (let i = 0; i < this.state.videoClips.length; i++) {
      formData.append("videoClips", this.state.videoClips[i]);
    }
    formData.append("picture", JSON.stringify(this.state.picture[0]));
    this.state.checked
      ? formData.append("master", "master")
      : formData.append("master", "other");
    this.state.santourHouse
      ? formData.append("santourHouse", "santourHouse")
      : formData.append("santourHouse", "other");
    this.state.department
      ? formData.append("department", "department")
      : formData.append("department", "other");
    this.props.newInstructor(formData);
    this.reset();
    this.setState({ loading: true });
  }

  validate() {
    let temp = {};
    if ("name" in this.state)
      temp.name = this.state.name ? "" : "لطفا نام استاد را وارد کنید";
    if ("description" in this.state)
      temp.description = this.state.description
        ? ""
        : "لطفا متن معرفی استاد را وارد کنید";
    if ("videoClips" in this.state)
      temp.videoClips =
        this.state.videoClips.length > 0
          ? ""
          : this.setNotify({
              isOpen: true,
              message:
                "لطفا حداقل یک آدرس  فیلم برای اجرای این استاد وارد کنید",
              type: "error",
            });
    if ("instrument" in this.state)
      temp.instrument =
        this.state.instrument.length !== 0
          ? ""
          : "لطفا یکی از سازها را انتخاب کنید";
    if ("picture" in this.state)
      temp.picture =
        this.state.picture.length > 0
          ? ""
          : this.setNotify({
              isOpen: true,
              message: "لطفا  یک تصویر برای این استاد انتخاب کنید",
              type: "error",
            });
    this.setState({ errors: { ...temp } });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  }

  render() {
    //const { classes } = this.props;
    let content;
    content = !this.state.loading ? (
      <>
        <Form
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} md={10}>
              <Controls.Input
                variant="outlined"
                name="name"
                label="نام استاد"
                value={this.state.name}
                onChange={this.onChange}
                error={this.state.errors.name}
              />
              <Editor
                value={this.state.description}
                onEditorChange={(e) => this.setState({ description: e })}
                placeholder="توضیحات معرفی استاد"
              />
              <Controls.SelectInstrument
                name="instrument"
                label="انتخاب ساز"
                value={this.state.instrument}
                onChange={this.onChange}
                options={this.state.instruments}
                error={this.state.errors.instrument}
              />
              {this.state.videoClips.length !== 0 &&
                this.state.videoClips.map((x, i) => (
                  <Grid item xs={12} md={10} key={i}>
                    {" "}
                    <Controls.Input
                      variant="outlined"
                      name="videoClip"
                      label="آدرس آپارات ویدئو"
                      value={x}
                      onChange={(e) => this.handleInputChange(e, i)}
                      error={this.state.errors.videoClips}
                    />
                    {this.state.videoClips.length > 0 && (
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => this.handleRemoveClick(i)}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    )}
                  </Grid>
                ))}
              <Grid item xs={12} sm={12} md={12}>
                <Tooltip
                  title="اضافه کردن یک ویدئو جدید"
                  aria-label="add"
                  onClick={this.handleAddClick}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ marginBottom: "8px", marginTop: "32px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checked}
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                    name=" checked"
                  />
                }
                label="از اساتید برجسته"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.department}
                    checked={this.state.santourHouse}
                    onChange={() =>
                      this.setState({ santourHouse: !this.state.santourHouse })
                    }
                    name="santourHouse"
                  />
                }
                label="از اساتید خانه سنتور"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={this.state.santourHouse}
                    checked={this.state.department}
                    onChange={() =>
                      this.setState({ department: !this.state.department })
                    }
                    name="department"
                  />
                }
                label="از اساتید دپارتمان کودک"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            <Grid item xs={6}>
              <Controls.UploadImage
                text="انتخاب تصویر"
                name="uploadImage"
                label="آپلود تصویر"
                dialogTitle="آپلود تصویر"
                color="primary"
                value={this.state.picture}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.picture}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.open}
                onClose={this.setOpen}
                onSave={this.setOpen}
                onClick={this.setOpen}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  this.setState({
                    picture: this.state.picture.concat(newFileObj[0]),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.picture.indexOf(deleteFileObj);
                  this.setState({
                    picture: this.state.picture.filter(
                      (pic) => this.state.picture.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={1}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
                error={this.state.errors.pictures}
              />
            </Grid>
            <Divider />
            <Grid item xs={12} md={12}>
              <Controls.Button
                onClick={() =>
                  this.setConfirmDialogue({
                    isOpen: true,
                    title: "آیا از ذخیره این استاد اطمینان دارید؟",
                    subTitle: "",
                  })
                }
                text="ذخیره این استاد"
                color="primary"
                startIcon={<Controls.Icons.SaveIcon />}
              />
              <Controls.Button
                text="پاک کردن همه مقادیر"
                color="secondary"
                onClick={this.reset}
                startIcon={<Controls.Icons.DeleteIcon />}
              />
            </Grid>
          </Grid>
        </Form>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
        <ConfirmDialogue
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialogue}
          onConfirm={this.onSubmit}
        />
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    return <>{content}</>;
  }
}

InstructorForm.propTypes = {
  instructor: PropTypes.object.isRequired,
  newInstructor: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  instruments: PropTypes.object.isRequired,
  getAllInstruments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  instructor: state.instructor,
  instruments: state.instruments,
  errors: state.errors,
});

export default connect(mapStateToProps, { newInstructor, getAllInstruments })(
  withStyles(styles)(InstructorForm)
);
