import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CardContent, CardMedia, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Actions
import {
  getSantourHouse,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Card from "../../components/Main Website/Card/Card";
import Loading from "../../components/common/Loading";
import styles from "../../assets/jss/views/landingPage";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
import ReactAudioPlayer from "react-audio-player";
import JsFileDownloader from "js-file-downloader";
import parse from "html-react-parser";
import ZoomImage from "../../shared/components/ZoomImage";

const useStyles = makeStyles(styles);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export default function SahamiWorks() {
  const [loading, setLoading] = React.useState(true);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [videos, setVideos] = React.useState([]);
  const [musics, setMusics] = React.useState([]);
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getTextAndHeaderPublic({ tag: "آثار استاد سهامی" }));
    dispatch(getAllTitlesPublic());
    dispatch(getSantourHouse());
  }, []);

  useEffect(() => {
    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      setDescription(textHeader.description);
      auth.text_header = null;
    }

    if (auth.santourHouse) {
      let santourHouse = auth.santourHouse;
      auth.santourHouse = null;
      if (santourHouse.sahami) {
        if (santourHouse.sahami.videoClips) {
          let videos = [];
          videos = santourHouse.sahami.videoClips.slice();
          setVideos(videos);
        }

        if (santourHouse.sahami.musics) {
          let musics = [];
          musics = santourHouse.sahami.musics.slice();
          setMusics(musics);
        }
      }
      //setHeaderUrl(santourHouse.sahami.poster.address);

      setLoading(false);
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  const classes = useStyles();

  const content = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر بخش آثار استاد سهامی")
              .length !== 0 && (
              <Typography variant="h4" className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر بخش آثار استاد سهامی")[0]
                    .title
                }
              </Typography>
            )}
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem
            xs={12}
            sm={12}
            md={12}
            data-aos="fade-up"
            className={classes.gridItem}
          >
            {videos.length !== 0 && (
              <>
                <Typography
                  variant="h5"
                  style={{
                    color: "GrayText",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  آثار ویدئویی
                </Typography>
                <Carousel
                  responsive={responsive}
                  additionalTransfrom={5}
                  arrows={true}
                  autoPlaySpeed={4500}
                  centerMode={false}
                  className=""
                  containerClass="container-with-dots"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  showDots={true}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  autoPlay={true}
                >
                  {videos.map((x, i) => (
                    <div
                      style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                        padding: "4px",
                      }}
                      key={i}
                    >
                      <Card plain>
                        <CardMedia>
                          <VideoPlayer src={x.address} style={classes.video} />
                        </CardMedia>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              </>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem
            xs={12}
            sm={12}
            md={12}
            data-aos="fade-down"
            className={classes.gridItem}
          >
            {musics.length !== 0 && (
              <>
                <Typography
                  variant="h5"
                  style={{
                    color: "GrayText",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  آثار صوتی
                </Typography>
                <Carousel
                  responsive={responsive}
                  additionalTransfrom={5}
                  arrows={true}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  //className=""
                  containerClass="container-with-dots"
                  //dotListClass=""
                  draggable
                  focusOnSelect={true}
                  infinite
                  itemClass="carousel-item-padding-40-px"
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  showDots={true}
                  //sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  autoPlay={true}
                >
                  {musics.map((x, i) => (
                    <div
                      style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                        padding: "4px",
                      }}
                      key={i}
                    >
                      <Card>
                        <CardMedia>
                          <ZoomImage
                            alt=""
                            src={x.poster.address}
                            className={classes.imgRounded}
                            gallery={true}
                          />
                        </CardMedia>
                        <CardContent>
                          <Typography
                            variant="subtitle2"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {x.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "4px",
                            }}
                          >
                            {x.artist}
                          </Typography>
                          <ReactAudioPlayer
                            src={x.address}
                            controls
                            style={{ width: "100%" }}
                          />
                          <Button
                            onClick={() => {
                              new JsFileDownloader({ url: x.address });
                            }}
                            color="primary"
                          >
                            دانلود
                          </Button>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              </>
            )}
          </GridItem>
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>{loading ? _loading : content}</div>
      </div>
    </div>
  );
}
