import types from "./types";
import uri from "./api_URI";
import jwt_decode from "jwt-decode";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

export const loginAdmin = (adminData) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/login`, adminData)
    .then((res) => {
      const { token, admin } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      const now = new Date();
      const currentAdmin = {
        _id: admin._id,
        superAdmin: admin.superAdmin,
        status: admin.status,
        username: admin.username,
        expireOn: now.getTime() + 86400000,
      };
      localStorage.setItem("currentAdmin", JSON.stringify(currentAdmin));
      dispatch(setCurrentAdmin(decoded));
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const createAdmin = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/create-admin`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_ADMIN,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAdmins = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-admins`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const admins = result.data;
      dispatch({
        type: types.GET_ADMINS,
        payload: admins,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAdminById = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/get-admin-by-id`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((result) => {
      const admin = result.data;
      dispatch({
        type: types.GET_ADMIN,
        payload: admin,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editAdmin = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/admins/edit-admin`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_ADMIN,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deletePicture = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/delete-picture`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_A_PICTURE,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteAdmin = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/admins/delete-admin`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_ADMIN,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const newHeader = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/landing/header-images`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_TEXT_HEADER,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const postNews = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/news`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_NEWS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const postSantourHouse = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/edit-santour-house`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.SET_SANTOURHOUSE,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllNews = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-news`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const news = result.data;
      dispatch({
        type: types.GET_NEWS,
        payload: news,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getSantourHouse = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-santour-house`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const santourHouse = result.data;
      dispatch({
        type: types.GET_SANTOURHOUSE,
        payload: santourHouse,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllNewsPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/get-news-public`, data)
    .then((result) => {
      const news = result.data;
      dispatch({
        type: types.GET_NEWS,
        payload: news,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllPictures = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/get-pictures`, data)
    .then((result) => {
      const pictures = result.data;
      dispatch({
        type: types.GET_PICTURES,
        payload: pictures,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllPicturesDashboard = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/get-pictures-dashboard`, data)
    .then((result) => {
      const pictures = result.data;
      dispatch({
        type: types.GET_PICTURES,
        payload: pictures,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getNewsLimit = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-news-limit`)
    .then((result) => {
      const news = result.data;
      dispatch({
        type: types.GET_NEWS,
        payload: news,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getNewsById = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/get-news-by-id`, data)
    .then((result) => {
      const news = result.data;
      dispatch({
        type: types.GET_A_NEWS,
        payload: news,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getLandingData = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/landing/landing-data`)
    .then((result) => {
      const data = result.data;
      dispatch({
        type: types.GET_LANDING_DATA,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getFooterData = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/landing/footer-data`)
    .then((result) => {
      const data = result.data;
      dispatch({
        type: types.GET_FOOTER_DATA,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteNews = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/admins/delete-news`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_NEWS,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editNews = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/edit-news`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_NEWS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const landingAndSantourHeader = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/landing/get-header`, data)
    .then((result) => {
      const header = result.data;
      dispatch({
        type: types.GET_SLIDER_HEADER,
        payload: header,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const newTextAndHeader = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/text-header`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_TEXT_HEADER,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getTextAndHeaderPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/admins/get-text-header`, data)
    .then((result) => {
      const textHeader = result.data;
      dispatch({
        type: types.GET_TEXT_HEADER,
        payload: textHeader,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllTextAndHeaderPublic = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-text-header-all`)
    .then((result) => {
      const textHeaders = result.data;
      dispatch({
        type: types.GET_ALL_TEXT_HEADER,
        payload: textHeaders,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const newTitle = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/admins/new-title`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_TITLE,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllTitlesPublic = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/admins/get-titles-all`)
    .then((result) => {
      const titles = result.data;
      dispatch({
        type: types.GET_ALL_TITLES,
        payload: titles,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const logoutAdmin = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentAdmin({}));
};

export const setCurrentAdmin = (decoded) => {
  return {
    type: types.SET_CURRENT_ADMIN,
    payload: decoded,
  };
};

export const clearCurrentadmin = () => {
  return {
    type: types.CLEAR_CURRENT_ADMIN,
  };
};

export const sendToProps = (data) => (dispatch) => {
  dispatch({
    type: types.NEW_ITEM,
    payload: {
      topStudents: data.topStudents,
      pictures: data.pictures,
      videoClip: data.videoClip,
      instrument: data.instrument,
    },
  });
};
