import React from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: "100%",
    height: "100%",
  },
  dialogContent: {
    marginTop: "50px",
  },
}));

export default function VideoPopup(props) {
  const { children, openPopup, setOpenPopup, ...other } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      classes={{ paper: classes.dialogWrapper }}
      onBackdropClick={setOpenPopup}
      {...other}
    >
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
