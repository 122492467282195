import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
//Actions
import {
  getAllNewsPublic,
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import ZoomImage from "../../shared/components/ZoomImage";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
//Styling
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";
import { Typography } from "@material-ui/core";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

const useStyles = makeStyles(styles);

function News() {
  const [loading, setLoading] = React.useState(true);
  const [titles, setTitles] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [itemPerPage, setItemPerPage] = React.useState(9);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getTextAndHeaderPublic({ tag: "اخبار" }));
    dispatch(getAllTitlesPublic());
    dispatch(getAllNewsPublic({ page: page, itemPerPage: itemPerPage }));
  }, []);

  useEffect(() => {
    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }

    if (auth.text_header) {
      const textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }

    if (auth.news.length !== 0) {
      const _news = auth.news.news.slice();
      setNews(_news);
      setCount(auth.news.count);
      auth.news = [];

      setLoading(false);
    }
  }, [auth]);

  const calculateDate = (createdAt) => {
    let date = CalculateDate(createdAt);
    date = ConvertToPersian.ConvertDateToPersian(date);
    return date;
  };

  const classes = useStyles();

  const content = (
    <div className={classes.section}>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
          {titles.filter((t) => t.tag === "تیتر اصلی صفحه اخبار").length !==
            0 && (
            <Typography variant="h5" className={classes.title}>
              {titles.filter((t) => t.tag === "تیتر اصلی صفحه اخبار")[0].title}
            </Typography>
          )}
          <div style={{ marginBottom: "64px" }}></div>
        </GridItem>
        {news &&
          news
            .slice((page - 1) * itemPerPage, page * itemPerPage)
            .map((x, i) => (
              <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up" key={i}>
                <NavLink
                  to={`/اخبار/${x._id}`}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  <Card plain>
                    <CardHeader>
                      <h3
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {x.title}
                      </h3>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {calculateDate(x.createdAt)}
                      </Typography>
                    </CardHeader>
                    <CardBody>
                      <ZoomImage
                        alt=""
                        src={x.picture.address}
                        className={classes.imgRounded}
                        gallery={true}
                      />
                    </CardBody>
                  </Card>
                </NavLink>
              </GridItem>
            ))}
        <GridItem xs={12} sm={12} md={12}>
          <Pagination
            shape="rounded"
            count={Math.ceil(count / itemPerPage)}
            page={page}
            size="large"
            className={classes.pagination}
            onChange={(e, page) => {
              setPage(page);
              dispatch(
                getAllNewsPublic({ page: page, itemPerPage: itemPerPage })
              );
              setLoading(true);
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه اخبار").length !==
              0 && (
              <h1 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه اخبار")[0]
                    .title
                }
              </h1>
            )}
            <br />
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={classes.section}
      >
        <NotFound />
      </div>
    </>
  );

  return (
    <div>
      <Parallax filter image={headerUrl} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? _loading : news.length === 0 ? notFound : content}
        </div>
      </div>
    </div>
  );
}

export default News;
