import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Typography, Card, Box, withStyles } from "@material-ui/core";
import parse from "html-react-parser";
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

const styles = (theme) => ({
  img: {
    width: "100%",
    height: "auto",
    marginBottom: 8,
  },
  card: {
    boxShadow: theme.shadows[2],
  },
  noDecoration: {
    textDecoration: "none !important",
  },
  cardTitle: {
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: "#3C4858",
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
    "&:active": {
      color: theme.palette.primary.dark,
    },
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  showFocus: {
    "&:focus span": {
      color: theme.palette.secondary.dark,
    },
  },
  snippet: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "70px",
  },
});

function NewsCard(props) {
  const { classes, url, src, date, title, snippet, onClick } = props;
  let shortSnippet = snippet.substring(0, snippet.indexOf("."));

  let newsDate = CalculateDate(date);
  newsDate = ConvertToPersian.ConvertDateToPersian(newsDate);

  return (
    <Card className={classes.card}>
      {src && (
        <a href={url} tabIndex={-1} onClick={onClick}>
          <img src={src} className={classes.img} alt="" />
        </a>
      )}
      <Box p={2}>
        <Typography variant="subtitle2" color="textSecondary">
          {newsDate}
        </Typography>
        <a
          href={url}
          tabIndex={-1}
          className={classNames(classes.noDecoration, classes.showFocus)}
          onClick={onClick}
        >
          <Typography variant="h6">
            <span className={classes.cardTitle}>{title}</span>
          </Typography>
        </a>
        <Typography
          variant="body1"
          color="textSecondary"
          // className={classes.snippet}
        >
          {parse(`${shortSnippet}`)}
          <a
            href={url}
            className={classes.noDecoration}
            tabIndex={-1}
            onClick={onClick}
          >
            <span className={classes.link}> ...ادامه مطلب</span>
          </a>
        </Typography>
      </Box>
    </Card>
  );
}

NewsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  //date: PropTypes.number.isRequired,
  snippet: PropTypes.string.isRequired,
  src: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(NewsCard);
