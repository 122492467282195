import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-jalaali";
import useTable from "../../../components/dashboard related/useTable";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import Image from "material-ui-image";
import Controls from "../../../components/dashboard related/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

export default function Functional(props) {
  const {
    records,
    headCells,
    az,
    openInPopup,
    openDialogue,
    deleteDialog,
    workshop,
    festival,
    instrument,
    diploma,
    news,
    admins,
    manager,
    editPageAddress,
    nameToEdit,
  } = props;
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells);

  const convertToJalali = (date) => {
    let jalaliDate = {
      year: new Date(date).getFullYear(),
      month: Number(date.slice(date.indexOf("-") + 1, date.lastIndexOf("-"))),
      day: new Date(date).getDate(),
    };
    jalaliDate = `${jalaliDate.year}/${jalaliDate.month}/${jalaliDate.day}`;
    const newDate = moment(jalaliDate, "YYYY-M-D").format("jYYYY/jM/jD");
    return newDate;
  };

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item._id}>
              {!diploma &&
                !admins &&
                (instrument || manager ? (
                  <TableCell dir="rtl">{item.name}</TableCell>
                ) : (
                  <TableCell dir="rtl">{item.title}</TableCell>
                ))}
              {!news && !instrument && !manager && !admins && (
                <TableCell>
                  {az
                    ? item.items
                      ? item.items.length
                      : 0
                    : item.pictures
                    ? item.pictures.length
                    : 0}
                </TableCell>
              )}
              {admins && <TableCell dir="rtl">{item.username}</TableCell>}
              {admins && (
                <TableCell dir="rtl">
                  {item.superAdmin ? "ادمین ناظر" : "ادمین"}
                </TableCell>
              )}
              {diploma && (
                <TableCell>
                  {item.diplomas ? item.diplomas.length : 0}
                </TableCell>
              )}
              {!diploma && !admins && (
                <TableCell>
                  {workshop || az || festival ? (
                    <Image
                      src={item.poster.address}
                      aspectRatio={6 / 5}
                    ></Image>
                  ) : instrument || news || manager ? (
                    <Image
                      src={item.picture.address}
                      aspectRatio={6 / 5}
                    ></Image>
                  ) : (
                    <></>
                  )}
                </TableCell>
              )}
              <TableCell>
                {convertToJalali(
                  item.createdAt.slice(0, item.createdAt.indexOf("T"))
                )}
              </TableCell>
              <TableCell>
                <Controls.ActionButton color="primary">
                  <Link
                    to={`${editPageAddress}/${item._id}`}
                    style={{ textDecoration: "none" }}
                    // onClick={() =>
                    //   localStorage.setItem(
                    //     `${nameToEdit}`,
                    //     JSON.stringify(item)
                    //   )
                    // }
                  >
                    <EditIcon fontSize="small" />
                  </Link>
                </Controls.ActionButton>
                {az && (
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => openInPopup(item._id)}
                  >
                    <AddIcon fontSize="small" />
                  </Controls.ActionButton>
                )}
                {!diploma && (
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() =>
                      openDialogue(item._id, {
                        isOpen: deleteDialog.isOpen,
                        title: deleteDialog.title,
                        subTitle: deleteDialog.subTitle,
                      })
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </Controls.ActionButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}
