import React from "react";
import { DropzoneDialogBase } from "material-ui-dropzone";
import Controls from "./Controls";

function UploadImage(props) {
  const {
    acceptedFiles,
    fileObjects,
    dialogTitle,
    cancelButtonText,
    submitButtonText,
    maxFileSize,
    open,
    onClose,
    onSave,
    onAdd,
    onDelete,
    onClick,
    startIcon,
    filesLimit,
    showPreviews,
    showFileNamesInPreview,
    clearOnUnmount,
    text,
    color,
  } = props;
  return (
    <>
      <Controls.Button
        text={text}
        onClick={onClick}
        color={color}
        startIcon={startIcon}
      />
      <DropzoneDialogBase
        acceptedFiles={acceptedFiles}
        fileObjects={fileObjects}
        dialogTitle={dialogTitle}
        cancelButtonText={cancelButtonText}
        submitButtonText={submitButtonText}
        maxFileSize={maxFileSize}
        open={open}
        onClose={onClose}
        onSave={onSave}
        onClick={onClick}
        onAdd={onAdd}
        onDelete={onDelete}
        filesLimit={filesLimit}
        showPreviews={showPreviews}
        showFileNamesInPreview={showFileNamesInPreview}
        clearOnUnmount={clearOnUnmount}
      />
    </>
  );
}

export default UploadImage;
