import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import azFormReducer from "./azFormReducer";
import azAllReducer from "./azAllReducer";
import instrumentReducer from "./instrumentReducer";
import workshopReducer from "./workshopReducer";
import festivalReducer from "./festivalReducer";
import instructorReducer from "./instructorReducer";
import managerReducer from "./managerReducer";
import diplomaReducer from "./diplomaReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  azCon: azFormReducer,
  azAll: azAllReducer,
  instruments: instrumentReducer,
  instructor: instructorReducer,
  workshopReducer,
  festivalReducer,
  managers: managerReducer,
  diplomaReducer,
});
