import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Modules
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getDepAzmoonById } from "../../actions/azActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Date
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import styles from "../../assets/jss/views/landingPage";
import Image from "material-ui-image";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(styles);

function AzConcertInstruments() {
  const [headerUrl, setHeaderUrl] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [titles, setTitles] = React.useState([]);
  const [azmoon, setAzmoon] = React.useState({});

  const auth = useSelector((state) => state.auth);
  const azAll = useSelector((state) => state.azAll);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    SmoothScrollTop();
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    dispatch(getDepAzmoonById({ azmoon_id: id }));
    dispatch(getTextAndHeaderPublic({ tag: "آزمون" }));
    dispatch(getAllTitlesPublic());
  }, []);

  useEffect(() => {
    if (azAll.azmoon) {
      let azmoon = azAll.azmoon;
      setAzmoon(azmoon);
      azAll.azmoon = null;
      setLoading(false);
    }
  }, [azAll]);

  useEffect(() => {
    if (auth.titles) {
      let titles = auth.titles.slice();
      setTitles(titles);
      auth.titles = null;
    }

    if (auth.text_header) {
      let textHeader = auth.text_header.textHeader;
      setHeaderUrl(textHeader.picture.address);
      auth.text_header = null;
    }
  }, [auth]);

  const classes = useStyles();

  const content = azmoon && (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            <h1 className={classes.title}>{azmoon.title}</h1>
          </GridItem>
          <GridItem xs={false} sm={false} md={1}></GridItem>
          <GridItem xs={12} sm={12} md={10} data-aos="zoom-in-up">
            <Card plain>
              <CardBody>
                <VideoPlayer src={azmoon.videoClip} style={classes.video} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={false} sm={false} md={1}></GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          <GridItem xs={false} sm={false} md={12} data-aos="zoom-in-up">
            {titles.filter((t) => t.tag === "زیر تیتر صفحه دوم آزمون")
              .length !== 0 && (
              <h2 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "زیر تیتر صفحه دوم آزمون")[0]
                    .title
                }
              </h2>
            )}
          </GridItem>
          {azmoon.items &&
            azmoon.items.length !== 0 &&
            azmoon.items.map((x, i) => (
              <GridItem xs={12} sm={12} md={4} key={i} data-aos="zoom-in-up">
                <NavLink
                  to={`/آزمون/ساز/${azmoon._id}/${i}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card plain>
                    <CardHeader>
                      <h3 className={classes.cardTitle}>{x.instrument}</h3>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.cardTitle}
                      >
                        {ConvertToPersian.ConvertDateToPersian(
                          CalculateDate(x.createdAt)
                        )}
                      </Typography>
                    </CardHeader>
                    <CardBody>
                      <Image
                        src={x.poster.address}
                        className={classes.imgRounded}
                      />
                    </CardBody>
                  </Card>
                </NavLink>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <div>
      <Parallax filter image={headerUrl}></Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>{loading ? _loading : content}</div>
      </div>
    </div>
  );
}
export default AzConcertInstruments;
