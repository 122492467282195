import React, { Component } from "react";
//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
//Actions
import { getAllAzConcertsPublic } from "../../actions/azActions";
import {
  getTextAndHeaderPublic,
  getAllTitlesPublic,
} from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import Image from "material-ui-image";
//Video
import VideoPlayer from "../../components/common/VideoPlayer";
//Styling
import { withStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";
import parse from "html-react-parser";
import { Typography } from "@material-ui/core";
import ConvertToPersian from "../../utils/ConvertToPersian";
import CalculateDate from "../../utils/calculateDate";

class AzConcert extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      errors: {},
      count: 0,
      page: 1,
      itemPerPage: 9,
      description: "",
      headerUrl: "",
      titles: [],
      loading: true,
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    SmoothScrollTop();
    this.props.getAllAzConcertsPublic({
      itemPerPage: this.state.itemPerPage,
      page: this.state.page,
    });
    this.props.getAllTitlesPublic();
    this.props.getTextAndHeaderPublic({ tag: "آزمون" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.azAll.records.length !== 0) {
      this.setState({ items: nextProps.azAll.records[0].azConcerts });
      this.setState({ count: nextProps.azAll.records[0].count });
      nextProps.azAll.records = [];
      this.setState({ loading: false });
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }

    if (nextProps.auth.titles) {
      this.setState({
        titles: nextProps.auth.titles.slice(),
      });
      nextProps.auth.titles = null;
    }
  }

  onChangePage(e, page) {
    this.setState({ page });
    this.fetchData(page);
  }

  fetchData(page) {
    this.props.getAllAzConcertsPublic({
      itemPerPage: this.state.itemPerPage,
      page,
    });
    this.setState({ loading: true });
  }

  render() {
    const { classes } = this.props;
    const _loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="primary" />
      </div>
    );

    const content = this.state.items.length !== 0 && (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={false} sm={false} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه اول آزمون"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه اول آزمون"
                    )[0].title
                  }
                </h1>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={7} data-aos="zoom-in-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="center">
            {this.state.items.length !== 0 && (
              <>
                <GridItem xs={12} sm={12} md={10} data-aos="zoom-in-up">
                  <Card plain>
                    <CardBody>
                      <VideoPlayer
                        src={
                          this.state.items[0]
                            ? this.state.items[0].videoClip
                            : ""
                        }
                        style={classes.video}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            )}
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="flex-start">
            <GridItem xs={false} sm={false} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "زیر تیتر صفحه اول آزمون"
              ).length !== 0 && (
                <h2 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "زیر تیتر صفحه اول آزمون"
                    )[0].title
                  }
                </h2>
              )}
            </GridItem>
            {this.state.items.length !== 0 &&
              this.state.items.map((x, i) => (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  key={i}
                  data-aos="zoom-in-down"
                >
                  <NavLink
                    to={`/آزمون/ساز/${x._id}`}
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    <Card plain>
                      <CardHeader>
                        <h3 className={classes.cardTitle}>{x.title}</h3>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          className={classes.cardTitle}
                        >
                          {ConvertToPersian.ConvertDateToPersian(
                            CalculateDate(x.createdAt)
                          )}
                        </Typography>
                      </CardHeader>
                      <CardBody>
                        <Image
                          src={x.poster.address}
                          className={classes.imgRounded}
                        />
                      </CardBody>
                    </Card>
                  </NavLink>
                </GridItem>
              ))}
            <GridItem xs={12} sm={12} md={12}>
              <Pagination
                shape="rounded"
                count={Math.ceil(this.state.count / this.state.itemPerPage)}
                page={this.state.page}
                size="large"
                className={classes.pagination}
                onChange={(e, page) => this.onChangePage(e, page)}
              />
            </GridItem>
          </GridContainer>
        </div>
      </>
    );

    let notFound = (
      <>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={false} sm={false} md={12} data-aos="zoom-in-up">
              {this.state.titles.filter(
                (t) => t.tag === "تیتر اصلی صفحه اول آزمون"
              ).length !== 0 && (
                <h1 className={classes.title}>
                  {
                    this.state.titles.filter(
                      (t) => t.tag === "تیتر اصلی صفحه اول آزمون"
                    )[0].title
                  }
                </h1>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={7} data-aos="zoom-in-up">
              <div className={classes.paragraph}>
                {parse(`${this.state.description}`)}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          className={classes.section}
        >
          <NotFound />
        </div>
      </>
    );

    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {this.state.loading
              ? _loading
              : this.state.items.length === 0
              ? notFound
              : content}
          </div>
        </div>
      </div>
    );
  }
}

AzConcert.propTypes = {
  errors: PropTypes.object.isRequired,
  azAll: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getAllAzConcertsPublic: PropTypes.func.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
  SmoothScrollTop: PropTypes.func.isRequired,
  getAllTitlesPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  azAll: state.azAll,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllAzConcertsPublic,
  getTextAndHeaderPublic,
  SmoothScrollTop,
  getAllTitlesPublic,
})(withStyles(styles)(AzConcert));

// .slice(
//   (this.state.page - 1) * this.state.itemPerPage,
//   this.state.page * this.state.itemPerPage
// )
