import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  setCurrentAdmin,
  logoutAdmin,
  clearCurrentadmin,
} from "../../actions/adminActions";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import store from "../../store";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);

  const decoded = jwt_decode(localStorage.jwtToken);

  store.dispatch(setCurrentAdmin(decoded));

  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    store.dispatch(logoutAdmin());
    store.dispatch(clearCurrentadmin());

    window.location.href = "/admin-login";
  }
}

const redirectTo = () => {
  window.location.href = "/admin-login";
};

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Switch>
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin-login" />
        )
      }
    />
  </Switch>
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
