import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import styles from "../../assets/jss/views/landingPage";

const useStyles = makeStyles(styles);

export default function CircularIndeterminate(props) {
  const classes = useStyles();
  const loginPage = (
    <div style={{ marginTop: "32px", marginBottom: "32px" }}>
      <Typography variant="body1" className={classes.title}>
        .موردی یافت نشد
      </Typography>
    </div>
  );

  return <> {loginPage}</>;
}
