import React, { useEffect } from "react";
import { Grid, Typography, Box, IconButton, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getFooterData } from "../../../actions/adminActions";

import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InstagramIcon from "@material-ui/icons/Instagram";
import transitions from "@material-ui/core/styles/transitions";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ConvertToPersian from "../../../utils/ConvertToPersian";
import CalculateDate from "../../../utils/calculateDate";

const useStyles = makeStyles((theme) => ({
  root: {
    //display: "flex",
    //flexDirection: "column",
    minHeight: "70vh",
    direction: "rtl",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "32px",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[250]
        : theme.palette.grey[200],
  },
  footerInner: {
    direction: "rtl",
    backgroundColor: theme.palette.common.darkBlack,
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  brandText: {
    fontFamily: "'Baloo Bhaijaan', cursive",
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  footerLinks: {
    marginTop: theme.spacing(2.5),
    marginBot: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  infoIcon: {
    marginLeft: theme.spacing(2),
    color: `${theme.palette.common.white} !important`,
    backgroundColor: "#33383b !important",
  },
  socialIcon: {
    fill: theme.palette.common.white,
    //backgroundColor: "#33383b",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      //backgroundColor: theme.palette.primary.light,
    },
  },
  link: {
    cursor: "Pointer",
    color: theme.palette.common.white,
    transition: transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
  },
}));

const infos = [
  {
    icon: <LocationOnIcon />,
    description: "شيراز، بلوار پاسداران (زرهي)، نبش كوچه ۵۸",
  },
  {
    icon: <PhoneIcon />,
    description: ConvertToPersian.ConvertNumberToPersian("09173027260"),
  },
];

const socialIcons = [
  {
    icon: (
      <svg
        role="img"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Instagram</title>
        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
      </svg>
    ),
    label: "Instagram",
    href: "https://www.instagram.com/now.music.academy/",
  },
];

export default function StickyFooter() {
  const [items, setItems] = React.useState();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFooterData());
  }, []);

  useEffect(() => {
    if (auth.footerData) {
      let data = auth.footerData;
      setItems(data);
      auth.footerData = null;
    }
  }, [auth]);
  const classes = useStyles();

  const calculateDate = (createdAt) => {
    let date = CalculateDate(createdAt);
    date = ConvertToPersian.ConvertDateToPersian(date);
    return date;
  };

  const returnYear = (date) => {
    let year = CalculateDate(date);
    year = year.split("/");
    year = ConvertToPersian.ConvertNumberToPersian(year[0]);
    return year;
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerInner}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="h6" paragraph className="text-white">
              آموزشگاه موسیقی نو
            </Typography>
            <Typography style={{ color: "#8f9296" }} paragraph>
              نخستین آموزشگاه موسیقی در جنوب کشور با آزمون-کنسرت‌های کیفی
            </Typography>
            <Typography style={{ color: "#8f9296" }} paragraph>
              دپارتمان هدفمند موسیقی کودک و نوجوان
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="h6" paragraph className="text-white">
              ما را در اینستاگرام دنبال کنید
            </Typography>
            <Box display="flex">
              {socialIcons.map((socialIcon, index) => (
                <Box key={index} mr={index !== socialIcons.length - 1 ? 1 : 0}>
                  <IconButton
                    aria-label={socialIcon.label}
                    className={classes.socialIcon}
                    href={socialIcon.href}
                  >
                    <InstagramIcon fontSize="large" color="primary" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box display="flex">
              <div>
                {infos.map((info, index) => (
                  <Box display="flex" mb={1} key={index}>
                    <Box mr={2}>
                      <IconButton
                        className={classes.infoIcon}
                        tabIndex={-1}
                        disabled
                      >
                        {info.icon}
                      </IconButton>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2" className="text-white">
                        {info.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="h6" paragraph className="text-white">
              آخرین مطالب
            </Typography>
            {items && (
              <>
                {typeof items.news !== "undefined" && (
                  <Box display="flex" mb={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2" className="text-white">
                        <Link
                          to={`/اخبار/${items.news._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button color="primary">{items.news.title}</Button>
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      style={{ marginRight: "auto" }}
                    >
                      <Typography color="primary" variant="subtitle2">
                        {calculateDate(items.news.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {typeof items.festival !== "undefined" && (
                  <Box display="flex" mb={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2" className="text-white">
                        <Link
                          to={`/فستیوال/${items.festival._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button color="primary">
                            {items.festival.title}
                          </Button>
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      style={{ marginRight: "auto" }}
                    >
                      <Typography color="primary" variant="subtitle2">
                        {calculateDate(items.festival.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {typeof items.workshop !== "undefined" && (
                  <Box display="flex" mb={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2" className="text-white">
                        <Link
                          to={`/کارگاه/${items.workshop._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button color="primary">
                            {items.workshop.title}
                          </Button>
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      style={{ marginRight: "auto" }}
                    >
                      <Typography color="primary" variant="subtitle2">
                        {calculateDate(items.workshop.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {typeof items.azConcert !== "undefined" && (
                  <Box display="flex" mb={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2" className="text-white">
                        <Link
                          to={`/آزمون/ساز/${items.azConcert._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button color="primary">
                            {items.azConcert.title}
                          </Button>
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      style={{ marginRight: "auto" }}
                    >
                      <Typography color="primary" variant="subtitle2">
                        {calculateDate(items.azConcert.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div>
            کلیه حقوق این وب‌سایت متعلق به{" "}
            {
              <a
                href="https://now-music-academy.com"
                style={{ textDecoration: "none", color: "#1976d2" }}
              >
                آموزشگاه موسیقی نو
              </a>
            }{" "}
            می‌باشد. {returnYear(new Date().toISOString())}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
