import React from "react";
import MyCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export default function Carousel(props) {
  const { children, arrows, dots } = props;
  return (
    <MyCarousel
      responsive={responsive}
      additionalTransfrom={5}
      arrows={arrows ? true : false}
      autoPlaySpeed={4500}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      showDots={dots ? true : false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      autoPlay={true}
    >
      {children}
    </MyCarousel>
  );
}
