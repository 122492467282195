import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Main Website/Card/Card";
import CardBody from "../../components/Main Website/Card/CardBody";
import styles from "../../assets/jss/views/landingPage";
import ZoomImage from "../../shared/components/ZoomImage";
//Video
import VideoPlayer from "./VideoPlayer";
import Carousel2 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles(styles);

export default function Carousel(props) {
  const { pictures, topStudents, type } = props;
  const classes = useStyles();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel2
      responsive={responsive}
      additionalTransfrom={5}
      arrows
      autoPlaySpeed={3000}
      centerMode={true}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      showDots={true}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      autoPlay={true}
    >
      {type &&
        pictures &&
        pictures.map((x, i) => (
          <div
            key={i}
            style={{
              marginRight: "8px",
              marginLeft: "8px",
            }}
          >
            <ZoomImage
              alt=""
              src={x.address}
              className={classes.imgRounded}
              gallery={true}
            />
          </div>
        ))}
      {!type &&
        topStudents &&
        topStudents.map((x, j) => (
          <div key={j} style={{ marginRight: "8px", marginLeft: "8px" }}>
            <Card>
              <CardBody>
                <VideoPlayer
                  style={{ width: "100%", height: "100%" }}
                  src={x.videoUrl}
                />
              </CardBody>
            </Card>
          </div>
        ))}
    </Carousel2>
  );
}
