import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import GridContainer from "../../../../components/Main Website/Grid/GridContainer.js";
import GridItem from "../../../../components/Main Website/Grid/GridItem.js";
import Card from "../../../../components/Main Website/Card/Card.js";
import CardBody from "../../../../components/Main Website/Card/CardBody.js";
import Carousel from "react-multi-carousel";
import styles from "../../../../assets/jss/views/landingPageSections/teamStyle";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import ZoomImage from "../../../../shared/components/ZoomImage";

const useStyles = makeStyles(styles);

function Instruments(props) {
  const { instruments, diplomaHeaderUrl, diplomaDesc } = props;

  const newInstrument = {
    name: "دیپلم موسیقی",
    picture: { address: diplomaHeaderUrl },
    description: diplomaDesc,
  };

  instruments.push(newInstrument);

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid2,
    classes.ingCardTop
  );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
          <h2 className={classes.title}>رشته‌های آموزشی و اساتید</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            <Carousel
              responsive={responsive}
              additionalTransfrom={5}
              arrows={true}
              autoPlaySpeed={4500}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              autoPlay={true}
            >
              {instruments.length !== 0 &&
                instruments.map((x, i) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                    key={i}
                  >
                    <Card plain>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.itemGrid}
                      >
                        <ZoomImage
                          src={x.picture.address}
                          className={imageClasses}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          gallery={true}
                          alt=""
                        />
                      </GridItem>
                      <h4 className={classes.cardTitle}>
                        {x.name}
                        <br />
                      </h4>
                      <CardBody>
                        <div className={classes.description}>
                          {parse(`${x.description}`)}
                        </div>
                        {x.name !== "دیپلم موسیقی" ? (
                          <Link
                            className={classes.cardTitle}
                            to={`اساتید/سازها/${x.name}`}
                          >
                            <Button color="primary">{`اساتید ${x.name}`}</Button>
                          </Link>
                        ) : (
                          <Link className={classes.cardTitle} to="/دیپلم">
                            <Button color="primary">{`${x.name}`}</Button>
                          </Link>
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                ))}
            </Carousel>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default Instruments;
