function ConvertDateToPersian(date) {
  let persian = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  let num = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  date = date.split("/");

  for (let k = 0; k < date.length; k++) {
    let myString = date[k].toString();
    let result = "";
    for (let j = 0; j < myString.length; j++) {
      for (let i = 0; i < num.length; i++) {
        if (myString[j] == num[i]) {
          result = result.concat(persian[i]);
        }
      }
    }

    if (date.length - k !== 1) {
      date[k] = result.concat("/");
    } else {
      date[k] = result;
    }
  }

  return date;
}

function ConvertNumberToPersian(number) {
  let persian = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  let num = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  let myString = number.toString();
  let result = "";
  for (let j = 0; j < myString.length; j++) {
    for (let i = 0; i < num.length; i++) {
      if (myString[j] == num[i]) {
        result = result.concat(persian[i]);
      }
    }
  }

  return result;
}

export default {
  ConvertDateToPersian,
  ConvertNumberToPersian,
};
