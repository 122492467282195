import React, { Component } from "react";
//Modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
//Actions
import { getAllInstrumentsPublic } from "../../actions/instrumentActions";
import { getTextAndHeaderPublic } from "../../actions/adminActions";
//Components
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import Card from "../../components/Main Website/Card/Card";
import CardHeader from "../../components/Main Website/Card/CardHeader";
import CardBody from "../../components/Main Website/Card/CardBody";
import Parallax from "../../components/Main Website/Parallax/Parallax";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Images
import Image from "material-ui-image";
//Styling
import { withStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import styles from "../../assets/jss/views/landingPage";

class Instruments extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      errors: {},
      page: 1,
      itemPerPage: 9,
      description: "",
      headerUrl: "",
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    SmoothScrollTop();
    this.props.getAllInstrumentsPublic();
    this.props.getTextAndHeaderPublic({ tag: "سازها" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instruments.instruments.length !== 0) {
      this.setState({ items: nextProps.instruments.instruments });
      nextProps.instruments.instruments = [];
    }

    if (nextProps.auth.text_header) {
      const textHeader = nextProps.auth.text_header.textHeader;
      this.setState({
        description: textHeader.description,
        headerUrl: textHeader.picture.address,
      });
      nextProps.auth.text_header = null;
    }
  }

  onChangePage(e, page) {
    this.setState({ page });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Parallax filter image={this.state.headerUrl}></Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={12}>
                <h1 className={classes.title} style={{ color: "black" }}>
                  معرفی سازها
                </h1>
                <br />
              </GridItem>
              <GridItem xs={false} sm={false} md={2}></GridItem>
              <GridItem xs={12} sm={12} md={8}>
                {localStorage.getItem("instrumDesc") ? (
                  <p style={{ color: "black" }}>
                    {localStorage.getItem("instrumDesc")}
                  </p>
                ) : (
                  <p style={{ color: "black" }}>{this.state.description}</p>
                )}
                <br />
              </GridItem>
              <GridItem xs={false} sm={false} md={2}></GridItem>
              <GridItem xs={false} sm={false} md={12}>
                <div style={{ marginTop: "32px", marginBottom: "32px" }} />
              </GridItem>
              {this.state.items.length !== 0 &&
                this.state.items
                  .slice(
                    (this.state.page - 1) * this.state.itemPerPage,
                    this.state.page * this.state.itemPerPage
                  )
                  .map((x, i) => (
                    <GridItem xs={12} sm={12} md={4} key={i}>
                      <Card plain>
                        <CardHeader>
                          <h2>{x.name}</h2>
                        </CardHeader>
                        <CardBody>
                          <Image src={x.picture.address} />
                          <h4>{x.description}</h4>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
              <GridItem xs={12} sm={12} md={12}>
                <Pagination
                  shape="rounded"
                  count={Math.ceil(
                    this.state.items.length / this.state.itemPerPage
                  )}
                  page={this.state.page}
                  size="large"
                  className={classes.pagination}
                  onChange={(e, page) => this.onChangePage(e, page)}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

Instruments.propTypes = {
  errors: PropTypes.object.isRequired,
  instruments: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getAllInstrumentsPublic: PropTypes.func.isRequired,
  getTextAndHeaderPublic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  instruments: state.instruments,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllInstrumentsPublic,
  getTextAndHeaderPublic,
})(withStyles(styles)(Instruments));
