import React from "react";

const VideoPlayer = (props) => {
  const { src, style } = props;
  return (
    <div className="h_iframe-aparat_embed_frame">
      <span></span>
      <iframe
        className={style}
        //style={{ width: "100%", height: "100%" }}
        title=""
        src={src}
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;

// width="100%"
// height="500px"
