import React, { useEffect } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, Paper } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Editor from "../Editor";
import {
  editInstrument,
  getAllInstruments,
} from "../../../actions/instrumentActions";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import { useLocation } from "react-router-dom";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#00a152",
    // },
  },
});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function InstrumentEdit() {
  const [imageId, setImageId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [id, setId] = React.useState();
  const [description, setDescription] = React.useState("");
  const [picture, setPicture] = React.useState([]);
  const [instrumentId, setInstrumentId] = React.useState();
  const [newPicture, setNewPicture] = React.useState([]);
  const [openPicture, setOpenPicture] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    name: "",
    subname: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const instruments = useSelector((state) => state.instruments);
  const dispatch = useDispatch();

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const reset = () => {
    setNewPicture([]);
    setOpenPicture(false);
    setLoading(false);
    setConfirmDialog({
      isOpen: false,
      name: "",
      subname: "",
    });
    setNotify({
      isOpen: false,
      message: "",
      type: "",
    });
    setErrors({});
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.name = name ? "" : "لطفا نام ساز را وارد کنید";
    temp.description = description ? "" : "لطفا متن معرفی ساز را وارد کنید";
    temp.pictures =
      !picture.length > 0 && !newPicture.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک عکس برای این سازانتخاب کنید",
            type: "error",
          })
        : "";

    setErrors({ ...temp });
    const errors_ = Object.values(temp).filter((x) => x !== "");
    if (errors_.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormDataa();
    formData.append(
      "picture",
      newPicture.length > 0 ? JSON.stringify(newPicture[0]) : "empty"
    );
    formData.append("name", name);
    formData.append("description", description);
    formData.append(
      "currentPicture",
      picture.length > 0 ? JSON.stringify(picture[0]) : "empty"
    );
    formData.append("instrument_id", instrumentId);
    dispatch(editInstrument(formData));
    reset();
    setLoading(true);
  };

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    setId(id);
    dispatch(getAllInstruments());
  }, []);

  useEffect(() => {
    if (instruments.instruments.length !== 0) {
      let instrument = instruments.instruments.filter((x) => x._id == id)[0];
      var _picture = [];
      const pic = { address: instrument.picture.address };
      _picture.push(pic);
      setInstrumentId(instrument._id);
      setPicture(_picture);
      setName(instrument.name);
      setDescription(instrument.description);
      instruments.instruments = [];
      setLoading(false);
    }
    if (instruments.new) {
      setNotify({
        isOpen: true,
        message: "اطلاعات  با موفقیت ذخیره شد",
        type: "success",
      });
      instruments.new = false;
      setLoading(false);
    }
  }, [instruments]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const classes = useStyles();

  const content = (
    <>
      <Paper className={classes.paper}>
        <Form
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Controls.Input
                variant="outlined"
                name="name"
                label="نام ساز"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={_errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Editor
                value={description}
                onEditorChange={(e) => setDescription(e)}
                placeholder="توضیحات مربوط به این ساز"
              />
            </Grid>
            {picture.length !== 0 && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "GrayText",
                      fontSize: "20px",
                      direction: "rtl",
                      textAlign: "center",
                    }}
                  >
                    تصویر ساز
                  </h1>
                  <br />
                </Grid>
                {picture.map((x, i) => (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    style={{ marginRight: "8px" }}
                    key={i}
                  >
                    <Card>
                      <Image
                        onClick={() => {
                          setImageId(i);
                          setOpen(true);
                        }}
                        src={x.address}
                      />
                      <Controls.ActionButton
                        onClick={() => {
                          if (picture.length > 0) {
                            const list = [...picture];
                            list.splice(i, 1);
                            setPicture(list);
                          }
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </Controls.ActionButton>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12} md={10}>
              <div style={{ marginBottom: "16px" }} />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.UploadImage
                text="تصویر جدید"
                name="uploadImage"
                label="آپلود تصویر"
                dialogTitle="آپلود تصویر"
                value={newPicture}
                //onChange={(e) => {}}
                acceptedFiles={["image/*"]}
                fileObjects={newPicture}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={openPicture}
                onClose={() => setOpenPicture(!openPicture)}
                onSave={() => setOpenPicture(!openPicture)}
                onClick={() => setOpenPicture(!openPicture)}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  setNewPicture(newPicture.concat(newFileObj));
                }}
                onDelete={(deleteFileObj) => {
                  const index = newPicture.indexOf(deleteFileObj);
                  setNewPicture(
                    newPicture.filter(
                      (pic) => newPicture.indexOf(pic) !== index
                    )
                  );
                }}
                filesLimit={100}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
            </Grid>
            <Grid item>
              <Controls.Button
                onClick={() =>
                  setConfirmDialogue({
                    isOpen: true,
                    name: "آیا از ذخیره اطلاعات اطمینان دارید؟",
                    subname: "",
                  })
                }
                text="ذخیره اطلاعات"
                color="secondary"
                startIcon={<Controls.Icons.SaveIcon />}
              />
            </Grid>
          </Grid>
        </Form>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialogue
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialogue}
          onConfirm={onSubmit}
        />
        {open && (
          <Lightbox
            medium={picture[imageId].address}
            onClose={() => setOpen(false)}
          />
        )}
      </Paper>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default InstrumentEdit;
