import React from "react";
import { TextField } from "@material-ui/core";
//import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//const rtlTheme = createMuiTheme({ direction: "rtl" });

function Input(props) {
  const { name, label, value, onChange, error = null, ...other } = props;
  return (
    <TextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
      {...other}
    />
  );
}

export default Input;
