import types from "./types";
import uri from "./api_URI";
import axios from "axios";

export const newAzConcert = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/azconcerts/new-az-concert`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_AZ_CONCERT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAzmoonById = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/azconcerts/get-azmoon-by-id`, data)
    .then((result) => {
      const azmoon = result.data;
      dispatch({
        type: types.GET_A_AZ_CONCERT,
        payload: azmoon,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getDepAzmoonById = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/azconcerts/get-dep-azmoon-by-id`, data)
    .then((result) => {
      const azmoon = result.data;
      dispatch({
        type: types.GET_A_AZ_CONCERT,
        payload: azmoon,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllAzConcerts = () => (dispatch) => {
  axios
    .get(`${uri.BaseURI}/api/azconcerts/get-all-az`, {
      headers: { "x-auth-token": `${localStorage.getItem("jwtToken")}` },
    })
    .then((result) => {
      const azConcerts = result.data;
      dispatch({
        type: types.GET_ALL_AZ_CONCERTS,
        payload: azConcerts,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllAzConcertsPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/azconcerts/get-all-az-public`, data)
    .then((result) => {
      const azConcerts = result.data;
      dispatch({
        type: types.GET_ALL_AZ_CONCERTS,
        payload: azConcerts,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const getAllDepAzConcertsPublic = (data) => (dispatch) => {
  axios
    .post(`${uri.BaseURI}/api/azconcerts/get-all-dep-concerts`, data)
    .then((result) => {
      const azConcerts = result.data;
      dispatch({
        type: types.GET_ALL_AZ_CONCERTS,
        payload: azConcerts,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const newAzInstrument = (data) => (dispatch) => {
  axios({
    method: "post",
    url: `${uri.BaseURI}/api/azconcerts/new-az-concert-instrument`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_AZ_INSTRUMENT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editAzInstrument = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/azconcerts/edit-az-concert-instrument`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_AZ_INSTRUMENT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteAzConcert = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/azconcerts/delete-concert`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_AZ_CONCERT,
        payload: res.data._id,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const deleteAzmoon = (data) => (dispatch) => {
  axios({
    method: "delete",
    url: `${uri.BaseURI}/api/azconcerts/delete-azmoon`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.DELETE_AZMOON,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const editAzmoon = (data) => (dispatch) => {
  axios({
    method: "put",
    url: `${uri.BaseURI}/api/azconcerts/edit-az-concert`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `${localStorage.getItem("jwtToken")}`,
    },
    data: data,
  })
    .then((res) => {
      dispatch({
        type: types.NEW_AZ_CONCERT,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ERROR,
        payload: err.response ? err.response : {},
      });
    });
};

export const selectedRecord = (data) => (dispatch) => {
  dispatch({
    type: types.SELECTED_RECORD,
    payload: data,
  });
};

export const receiveSelectedRecord = () => (dispatch) => {
  dispatch({
    type: types.RECEIVE_SELECTED_RECORD,
    payload: {},
  });
};

export const selectedItem = (data) => (dispatch) => {
  dispatch({
    type: types.SELECTED_ITEM,
    payload: data,
  });
};

export const receiveSelectedItem = (data) => (dispatch) => {
  dispatch({
    type: types.RECEIVE_SELECTED_ITEM,
    payload: {},
  });
};
