import React, { Component } from "react";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import { Grid } from "@material-ui/core";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { newAzConcert } from "../../../actions/azActions";
import Loading from "../../../components/common/Loading";
import AzConcertInstrumentForm from "./AzConcertInstrumentForm";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";

const FormDataa = require("form-data");

const theme = createMuiTheme({
  palette: {},
});

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "releative",
    up: theme.spacing(2),
    right: theme.spacing(3),
  },
});

const initialState = {
  title: "",
  videoClip: "",
  poster: [],
  createdAt: Date(),
  open: false,
  loading: false,
  notify: {
    isOpen: false,
    message: "",
    type: "",
  },
  confirmDialog: {
    isOpen: false,
    title: "",
    subTitle: "",
  },
  errors: {},
};

const AddItem = () => <AzConcertInstrumentForm />;

class AzConcertForm extends Component {
  constructor() {
    super();
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.reset = this.reset.bind(this);
    this.validate = this.validate.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.onChangePoster = this.onChangePoster.bind(this);
    this.setConfirmDialogue = this.setConfirmDialogue.bind(this);
    this.setNotify = this.setNotify.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.azCon.new) {
      nextProps.azCon.new = false;
      this.setNotify({
        isOpen: true,
        message: "آزمون-کنسرت  با موفقیت ذخیره شد",
        type: "success",
      });
      this.setState({ loading: false });
    }

    if (nextProps.errors.errors) {
      nextProps.azCon.loading = true;
      this.setState({ errors: nextProps.errors.errors });
      let err =
        typeof nextProps.errors.data.error !== "undefined"
          ? nextProps.errors.data.error
          : nextProps.errors.data;
      this.setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      nextProps.errors.errors = null;
      this.setState({ loading: false });
      let errorTimeout = setTimeout(() => {
        this.setState({ errors: {} });
        clearTimeout(errorTimeout);
      }, 4000);
    }
  }

  setConfirmDialogue(data) {
    if (this.validate()) {
      const conDialogue = {
        isOpen: data.isOpen,
        title: data.title,
        subTitle: data.subTitle,
      };
      this.setState({ confirmDialog: conDialogue });
    }
  }

  setNotify(data) {
    const newNotify = {
      isOpen: data.isOpen,
      message: data.message,
      type: data.type,
    };
    this.setState({ notify: newNotify });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangePoster(e) {
    //this.setState({ poster: e.target.files[0] });
  }

  setOpen() {
    this.setState({ open: !this.state.open });
  }

  reset() {
    this.setState(initialState);
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, isOpen: false },
    });

    const formData = new FormDataa();
    formData.append("poster", JSON.stringify(this.state.poster[0]));
    formData.append("title", this.state.title);
    formData.append("videoClip", this.state.videoClip);
    this.props.newAzConcert(formData);
    this.reset();
    this.setState({ loading: true });
  }

  validate() {
    let temp = { ...this.state.errors };
    if ("title" in this.state)
      temp.title = this.state.title
        ? ""
        : "لطفا عنوان آزمون کنسرت را وارد کنید";
    if ("videoClip" in this.state)
      temp.videoClip = this.state.videoClip
        ? ""
        : "لطفا آدرس  فیلم این آزمون را وارد کنید";
    if ("poster" in this.state)
      temp.poster =
        this.state.poster.length > 0
          ? ""
          : this.setNotify({
              isOpen: true,
              message: "لطفا یک عکس به عنوان پوستر این آزمون-کنسرت انتخاب کنید",
              type: "error",
            });
    this.setState({ errors: { ...temp } });

    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  }

  renderItems() {
    const newItems = [...this.state.items, AddItem];
    this.setState({ items: newItems });
  }

  render() {
    //const { classes } = this.props;
    let content;
    content = !this.state.loading ? (
      <>
        <Form
          classes={{
            root: {
              "& .MuiFormControl-root": {
                width: "80%",
                margin: theme.spacing(1),
              },
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} md={12}>
              <Controls.Input
                variant="outlined"
                name="title"
                label="عنوان آزمون کنسرت"
                value={this.state.title}
                onChange={this.onChange}
                error={this.state.errors.title}
              />
              <Controls.Input
                variant="outlined"
                name="videoClip"
                label="آدرس آپارات ویدئو"
                value={this.state.videoClip}
                onChange={this.onChange}
                error={this.state.errors.videoClip}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Controls.UploadImage
                text="انتخاب پوستر"
                name="uploadImage"
                label="آپلود پوستر"
                dialogTitle="آپلود پوستر"
                value={this.state.poster}
                onChange={this.onChange}
                acceptedFiles={["image/*"]}
                fileObjects={this.state.poster}
                cancelButtonText="انصراف"
                submitButtonText="تایید"
                maxFileSize={50000000}
                open={this.state.open}
                onClose={this.setOpen}
                onSave={this.setOpen}
                onClick={this.setOpen}
                startIcon={<Controls.Icons.CloudUploadIcon />}
                onAdd={(newFileObj) => {
                  this.setState({
                    poster: this.state.poster.concat(newFileObj[0]),
                  });
                }}
                onDelete={(deleteFileObj) => {
                  const index = this.state.poster.indexOf(deleteFileObj);
                  this.setState({
                    poster: this.state.poster.filter(
                      (pic) => this.state.poster.indexOf(pic) !== index
                    ),
                  });
                }}
                filesLimit={1}
                showPreviews={true}
                showFileNamesInPreview={true}
                clearOnUnmount={false}
              />
            </Grid>
            <Grid item>
              <Controls.Button
                onClick={() =>
                  this.setConfirmDialogue({
                    isOpen: true,
                    title: "آیا از ذخیره این آزمون-کنسرت اطمینان دارید؟",
                    subTitle: "",
                  })
                }
                text="ذخیره این آزمون-کنسرت"
                color="primary"
                startIcon={<Controls.Icons.SaveIcon />}
              />
              <Controls.Button
                text="پاک کردن همه مقادیر"
                color="secondary"
                onClick={this.reset}
                startIcon={<Controls.Icons.DeleteIcon />}
              />
            </Grid>
          </Grid>
        </Form>
        <Notification notify={this.state.notify} setNotify={this.setNotify} />
        <ConfirmDialogue
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialogue}
          onConfirm={this.onSubmit}
        />
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="secondary" />
      </div>
    );

    return <>{content}</>;
  }
}

AzConcertForm.propTypes = {
  azCon: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  newAzConcert: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  azCon: state.azCon,
  errors: state.errors,
});

export default connect(mapStateToProps, { newAzConcert })(
  withStyles(styles)(AzConcertForm)
);
