import React, { useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Fab,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import {
  editWorkshop,
  getAWorkshopById,
} from "../../../actions/workshopActions";
import Editor from "../Editor";
import { useLocation } from "react-router-dom";
const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function WorkshopEdit() {
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [_id, setId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [videoClip, setVideoClip] = React.useState([]);
  const [poster, setPoster] = React.useState([]);
  const [newPoster, setNewPoster] = React.useState([]);
  const [pictures, setPictures] = React.useState([]);
  const [newPictures, setNewPictures] = React.useState([]);
  const [openPictures, setOpenPictures] = React.useState(false);
  const [openPoster, setOpenPoster] = React.useState(false);
  const [santourHouse, setSantourHouse] = React.useState(false);
  const [sazshenasi, setSazshenasi] = React.useState(false);
  const [tarbiat, setTarbiat] = React.useState(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const workshopReducer = useSelector((state) => state.workshopReducer);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    let id = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    dispatch(getAWorkshopById({ workshop_id: id }));
  }, []);

  useEffect(() => {
    if (workshopReducer.new) {
      workshopReducer.new = false;
      setNotify({
        isOpen: true,
        message: "کارگاه  با موفقیت ویرایش شد",
        type: "success",
      });
      setLoading(false);
    }

    if (workshopReducer.workshop) {
      let workshop = workshopReducer.workshop;
      setId(workshop._id);
      setTitle(workshop.title);
      setDescription(workshop.description);
      setSantourHouse(workshop.santourHouse);
      setSazshenasi(workshop.sazshenasi);
      setTarbiat(workshop.tarbiat);
      const _poster = [];
      _poster.push(workshop.poster);
      setPoster(_poster);
      if (workshop.videoClip !== "") {
        let clip = [];
        clip.push(workshop.videoClip);
        setVideoClip(clip);
      }
      var _pictures = [];
      for (let i = 0; i < workshop.pictures.length; i++) {
        const pic = { address: workshop.pictures[i].address };
        _pictures.push(pic);
      }
      if (_pictures.length !== 0) setPictures(_pictures);
    }
    workshopReducer.workshop = null;
    setLoading(false);
  }, [workshopReducer]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.title = title ? "" : "لطفا عنوان کارگاه را وارد کنید";
    temp.description = description
      ? ""
      : "لطفا توضیحات این کارگاه را وارد کنید";
    temp.poster =
      poster.length === 0 && newPoster.length === 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک عکس به عنوان پوستر این کارگاه انتخاب کنید",
            type: "error",
          })
        : "";
    temp.videoClip = "";
    if (videoClip.length > 0) {
      videoClip.forEach((v) => {
        if (v === "") {
          temp.videoClip = setNotify({
            isOpen: true,
            message: "لطفا آدرس  کلیپی که اضافه شده‌است را وارد کنید",
            type: "error",
          });
        }
      });
    }
    setErrors({ ...temp });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    formData.append(
      "poster",
      newPoster.length > 0 ? JSON.stringify(newPoster[0]) : "empty"
    );
    formData.append(
      "currentPoster",
      poster.length > 0 ? JSON.stringify(poster[0]) : "empty"
    );
    if (newPictures.length > 0) {
      for (let i = 0; i < newPictures.length; i++) {
        formData.append("newPictures", JSON.stringify(newPictures[i]));
      }
    } else {
      formData.append("newPictures", "empty");
    }

    formData.append("videoClip", videoClip.length > 0 ? videoClip[0] : "empty");

    formData.append(
      "currentPictures",
      pictures.length > 0 ? JSON.stringify(pictures) : "empty"
    );
    santourHouse
      ? formData.append("santourHouse", "santourHouse")
      : formData.append("santourHouse", "other");

    sazshenasi
      ? formData.append("sazshenasi", "sazshenasi")
      : formData.append("sazshenasi", "other");
    tarbiat
      ? formData.append("tarbiat", "tarbiat")
      : formData.append("tarbiat", "other");
    !tarbiat && !sazshenasi && !santourHouse
      ? formData.append("other", "other")
      : formData.append("other", "none");
    formData.append("workshop_id", _id);
    dispatch(editWorkshop(formData));
    //reset();
    setLoading(true);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...videoClip];
    list[index] = value;
    setVideoClip(list);
  };

  const handleRemoveClick = (index) => {
    if (videoClip.length > 0) {
      const list = [...videoClip];
      list.splice(index, 1);
      setVideoClip(list);
    }
  };

  const handleAddClick = () => {
    setVideoClip([...videoClip, ""]);
  };

  const classes = useStyles();

  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              پوستر
            </h3>
          </Grid>
          {poster.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(poster[0].address);
                      setOpen(true);
                    }}
                    src={poster[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setPoster([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Controls.UploadImage
              text="انتخاب پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر جدید"
              dialogTitle="آپلود پوستر جدید"
              value={newPoster}
              acceptedFiles={["image/*"]}
              fileObjects={newPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPoster}
              onClose={() => setOpenPoster(!openPoster)}
              onSave={() => setOpenPoster(!openPoster)}
              onClick={() => setOpenPoster(!openPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPoster(newPoster.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPoster.indexOf(deleteFileObj);
                setNewPoster(
                  newPoster.filter((pic) => newPoster.indexOf(pic) !== index)
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              اطلاعات کلی
            </h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Controls.Input
              variant="outlined"
              name="title"
              label="عنوان کارگاه"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              error={errors.title}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Editor
              value={description}
              onEditorChange={(e) => setDescription(e)}
              placeholder="توضیحات مربوط به این کارگاه"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={sazshenasi || tarbiat}
                  checked={santourHouse}
                  onChange={() => setSantourHouse(!santourHouse)}
                  name="santourHouse"
                />
              }
              label="کارگاه خانه سنتور"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={santourHouse || tarbiat}
                  checked={sazshenasi}
                  onChange={() => setSazshenasi(!sazshenasi)}
                  name="sazshenasi"
                />
              }
              label="کارگاه سازشناسی برای کودکان"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={santourHouse || sazshenasi}
                  checked={tarbiat}
                  onChange={() => setTarbiat(!tarbiat)}
                  name="sazshenasi"
                />
              }
              label="کارگاه دوره تربیت مربی موسیقی کودک"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              تصاویر
            </h3>
          </Grid>
          {pictures.length !== 0 && (
            <>
              {pictures.map((x, i) => (
                <Grid
                  item
                  xs={2}
                  sm={1}
                  md={1}
                  style={{ marginRight: "8px" }}
                  key={i}
                >
                  <Card>
                    <Image
                      onClick={() => {
                        setAddress(x.address);
                        setOpen(true);
                      }}
                      src={x.address}
                    />
                    <Controls.ActionButton
                      onClick={() => {
                        if (pictures.length > 0) {
                          const list = [...pictures];
                          list.splice(i, 1);
                          setPictures(list);
                        }
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Card>
                </Grid>
              ))}
            </>
          )}
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          >
            <Controls.UploadImage
              text="انتخاب تصاویر جدید"
              name="newPictures"
              label="آپلود تصاویر جدید"
              dialogTitle="آپلود تصاویر جدید"
              value={newPictures}
              acceptedFiles={["image/*"]}
              fileObjects={newPictures}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openPictures}
              onClose={() => setOpenPictures(!openPictures)}
              onSave={() => setOpenPictures(!openPictures)}
              onClick={() => setOpenPictures(!openPictures)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewPictures(newPictures.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newPictures.indexOf(deleteFileObj);
                setNewPictures(
                  newPictures.filter(
                    (pic) => newPictures.indexOf(pic) !== index
                  )
                );
              }}
              filesLimit={100}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              error={errors.newPictures}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginBottom: "8px", marginTop: "32px" }}
          ></Grid>
          {videoClip.length !== 0 &&
            videoClip.map((x, i) => (
              <Grid item xs={12} md={12} key={i}>
                <Controls.Input
                  variant="outlined"
                  name="videoClip"
                  label="آدرس آپارات ویدئو"
                  value={x}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errors.videoClip}
                />
                {videoClip.length > 0 && (
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Controls.ActionButton>
                )}
              </Grid>
            ))}
          {videoClip.length === 0 && (
            <Grid style={{ marginBottom: "8px", marginTop: "32px" }}>
              <Tooltip
                title="اضافه کردن یک ویدئو جدید"
                aria-label="add"
                onClick={handleAddClick}
                placement="top"
              >
                <Fab color="primary">
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} md={12} style={{ marginTop: "128px" }}>
            <Controls.Button
              onClick={(e) =>
                setConfirmDialogue({
                  isOpen: true,
                  title: "آیا این کارگاه ذخیره شود؟",
                  subTitle: "",
                })
              }
              text="ذخیره این کارگاه"
              color="primary"
              startIcon={<Controls.Icons.SaveIcon />}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default WorkshopEdit;
