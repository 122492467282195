import types from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  currentAdmin: {},
  admin: null,
  admins: [],
  news: [],
  pictures: [],
  santourHouse: null,
  newNews: false,
  newAdmin: false,
  updateSantourHouse: false,
  added: false,
  landingData: null,
  footerData: null,
  aNews: null,
  sliderImages: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        currentAdmin: action.payload,
      };
    case types.GET_LANDING_DATA:
      return {
        ...state,
        landingData: action.payload,
      };
    case types.GET_FOOTER_DATA:
      return {
        ...state,
        footerData: action.payload,
      };
    case types.CLEAR_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        admin: null,
      };
    case types.NEW_TEXT_HEADER:
      return {
        ...state,
        added: true,
      };
    case types.NEW_TITLE:
      return {
        ...state,
        added: true,
      };
    case types.GET_TEXT_HEADER:
      return {
        ...state,
        text_header: action.payload,
      };
    case types.GET_ALL_TEXT_HEADER:
      return {
        ...state,
        text_headers: action.payload,
      };
    case types.GET_ALL_TITLES:
      return {
        ...state,
        titles: action.payload,
      };
    case types.GET_SLIDER_HEADER:
      return {
        ...state,
        sliderImages: action.payload,
      };
    case types.NEW_NEWS:
      return {
        ...state,
        newNews: true,
      };
    case types.GET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case types.GET_A_NEWS:
      return {
        ...state,
        aNews: action.payload,
      };
    case types.NEW_ADMIN:
      return {
        ...state,
        newAdmin: true,
      };
    case types.GET_ADMINS:
      return {
        ...state,
        admins: action.payload,
      };
    case types.GET_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case types.GET_PICTURES:
      return {
        ...state,
        pictures: action.payload,
      };
    case types.SET_SANTOURHOUSE:
      return {
        ...state,
        updateSantourHouse: true,
      };
    case types.GET_SANTOURHOUSE:
      return {
        ...state,
        santourHouse: action.payload,
      };
    case types.DELETE_NEWS:
      return {
        ...state,
        deleteANews: true,
        _id: action.payload,
      };
    case types.DELETE_ADMIN:
      return {
        ...state,
        deleteAdmin: true,
        _id: action.payload,
      };
    case types.DELETE_A_PICTURE:
      return {
        ...state,
        deletePicture: true,
        _id: action.payload,
      };
    default:
      return state;
  }
}
