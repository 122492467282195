import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// core components
import GridContainer from "../../../../components/Main Website/Grid/GridContainer.js";
import GridItem from "../../../../components/Main Website/Grid/GridItem.js";
import Card from "../../../../components/Main Website/Card/Card.js";
import CardBody from "../../../../components/Main Website/Card/CardBody.js";
import styles from "../../../../assets/jss/views/landingPageSections/teamStyle";
import santourHouseImage from "../../../../images/santourHouse.jpg";
import departmentImage from "../../../../images/logotype copy.jpg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

const myStyles = makeStyles((theme) => ({
  noDecoration: {
    textDecoration: "none !important",
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  image: {
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      height: "100%",
    },
  },
}));

function Introduction(props) {
  const {
    workshopHeaderUrl,
    workshopDesc,
    festivalHeaderUrl,
    festivalDesc,
    azHeaderUrl,
    azDesc,
    landingDesc,
    titles,
  } = props;

  const classes = useStyles();
  const Styles = myStyles();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    //classes.imgFluid2,
    classes.ingCardTop
    //Styles.image
  );
  return (
    <>
      <div className={classes.section}>
        <GridContainer justify="center" data-aos="zoom-in-up">
          <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه لندینگ").length !==
              0 && (
              <h2 className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه لندینگ")[0]
                    .title
                }
              </h2>
            )}
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={3}
            style={{
              marginTop: "32px",
            }}
          >
            <Link to="/خانه سنتور">
              <img
                className={imageClasses}
                src={santourHouseImage}
                alt="..."
                style={{
                  position: "inherit",
                  maxWidth: "70%",
                  maxHeight: "70%",
                }}
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h5 style={{ marginTop: "64px" }} className={classes.description}>
              {parse(`${landingDesc}`)}
            </h5>
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={3}
            style={{
              marginTop: "32px",
            }}
          >
            <Link to="/دپارتمان">
              <img
                className={imageClasses}
                src={departmentImage}
                alt="..."
                style={{
                  position: "inherit",
                  maxWidth: "70%",
                  maxHeight: "70%",
                }}
              />
            </Link>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="center"></GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} data-aos="zoom-in-up">
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img
                    src={azHeaderUrl}
                    alt="..."
                    className={imageClasses}
                    style={{ width: "100%", maxHeight: "100%" }}
                  />
                </GridItem>
                {titles.filter((t) => t.tag === "تیتر بخش معرفی آزمون")
                  .length !== 0 && (
                  <h4 className={classes.cardTitle}>
                    {
                      titles.filter((t) => t.tag === "تیتر بخش معرفی آزمون")[0]
                        .title
                    }
                    <br />
                  </h4>
                )}
                <CardBody>
                  <div className={classes.description}>
                    {parse(`${azDesc}`)}
                    <Link
                      to={`/آزمون`}
                      className={Styles.noDecoration}
                      tabIndex={-1}
                    >
                      <span className={Styles.link}> ادامه مطلب ...</span>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={4}
              data-aos="zoom-in-up"
              className={classes.itemGrid}
            >
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img
                    src={workshopHeaderUrl}
                    alt="..."
                    className={imageClasses}
                    style={{ width: "100%", maxHeight: "100%" }}
                  />
                </GridItem>
                {titles.filter((t) => t.tag === "تیتر بخش معرفی کارگاه")
                  .length !== 0 && (
                  <h4 className={classes.cardTitle}>
                    {
                      titles.filter((t) => t.tag === "تیتر بخش معرفی کارگاه")[0]
                        .title
                    }
                    <br />
                  </h4>
                )}
                <CardBody>
                  <div className={classes.description}>
                    {parse(`${workshopDesc}`)}
                    <Link
                      to={`/کارگاه ها/همه`}
                      className={Styles.noDecoration}
                      tabIndex={-1}
                    >
                      <span className={Styles.link}> ادامه مطلب ...</span>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={4}
              data-aos="zoom-in-up"
              className={classes.itemGrid}
            >
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img
                    src={festivalHeaderUrl}
                    alt="..."
                    className={imageClasses}
                    style={{ width: "100%", maxHeight: "100%" }}
                  />
                </GridItem>
                {titles.filter((t) => t.tag === "تیتر بخش معرفی فستیوال")
                  .length !== 0 && (
                  <h4 className={classes.cardTitle}>
                    {
                      titles.filter(
                        (t) => t.tag === "تیتر بخش معرفی فستیوال"
                      )[0].title
                    }
                    <br />
                  </h4>
                )}
                <CardBody>
                  <div className={classes.description}>
                    {parse(`${festivalDesc}`)}
                    <Link
                      to={`/فستیوال`}
                      className={Styles.noDecoration}
                      tabIndex={-1}
                    >
                      <span className={Styles.link}> ادامه مطلب ...</span>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}

export default Introduction;
