import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Collapse as MUICollapse } from "@material-ui/core";
import List from "@material-ui/core/List";
import Icons from "../controls/Icons";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";

function Collapse(props) {
  const {
    _in,
    timeout,
    unmountOnExit,
    component,
    disablePadding,
    button,
    classes,
    primary,
    icon,
    to,
  } = props;
  return (
    <MUICollapse in={_in} timeout={timeout} unmountOnExit={unmountOnExit}>
      <List component={component} disablePadding={disablePadding}>
        <NavLink to={to} style={{ textDecoration: "none" }}>
          <ListItem button={button}>
            <ListItemText primary={primary} />
            <Icons.ListItemIcon className={classes}>
              {icon ? icon : <></>}
            </Icons.ListItemIcon>
          </ListItem>
        </NavLink>
      </List>
    </MUICollapse>
  );
}

export default Collapse;
