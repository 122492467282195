import React, { useEffect } from "react";
import {
  Fab,
  Grid,
  Paper,
  Tooltip,
  Card as MaterialCard,
  CardContent,
  CardMedia,
  Collapse,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import Editor from "../Editor";
import Card from "../../../components/Main Website/Card/Card";
import CardHeader from "../../../components/Main Website/Card/CardHeader";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import ReactAudioPlayer from "react-audio-player";
import {
  getSantourHouse,
  postSantourHouse,
} from "../../../actions/adminActions";
const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: "32px",
  },
});

const useCollapseStyles = makeStyles({
  root: {
    width: "100%",
  },
  hidden: {
    padding: "0 !important",
  },
});

const GridItemXs12 = (props) => <Grid item xs={12} {...props} />;

const MyCollapse = (props) => {
  const classes = useCollapseStyles();
  return (
    <Collapse
      component={GridItemXs12}
      classes={{
        hidden: classes.hidden,
        root: classes.root,
      }}
      {...props}
    >
      <Grid container spacing={3}>
        {props.children}
      </Grid>
    </Collapse>
  );
};

export default function SantourHouse() {
  const [address, setAddress] = React.useState("");
  const [sahamiVideoCollapse, setsahamiVideoCollapse] = React.useState(false);
  const [sahamiMusicCollapse, setsahamiMusicCollapse] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [sahamiPoster, setSahamiPoster] = React.useState([]);
  const [newSahamiPoster, setNewSahamiPoster] = React.useState([]);
  const [openSahamiPoster, setOpenSahamiPoster] = React.useState(false);
  const [sahamiDescription, setSahamiDescription] = React.useState("");
  const [sahamiVideoClips, setSahamiVideoClips] = React.useState([]);
  const [sahamiMusics, setSahamiMusics] = React.useState([]);
  const [newSahamiMusics, setNewSahamiMusics] = React.useState([]);
  const [openSahamiMusics, setOpenSahamiMusics] = React.useState([]);
  const [openSahamiMusicsPoster, setOpenSahamiMusicsPoster] = React.useState(
    []
  );

  const [studentsVideoCollapse, setStudentsVideoCollapse] =
    React.useState(false);
  const [studentsMusicCollapse, setStudentsMusicCollapse] =
    React.useState(false);
  const [studentPoster, setStudentsPoster] = React.useState([]);
  const [newStudentsPoster, setNewStudentsPoster] = React.useState([]);
  const [openStudentsPoster, setOpenStudentsPoster] = React.useState(false);
  const [studentsDescription, setStudentDescription] = React.useState("");
  const [studentsVideoClips, setStudentsVideoClips] = React.useState([]);
  const [studentsMusics, setStudentsMusics] = React.useState([]);
  const [newStudentsMusics, setNewStudentsMusics] = React.useState([]);
  const [openStudentsMusics, setOpenStudentMusics] = React.useState([]);
  const [openStudentsMusicsPoster, setOpenStudentMusicsPoster] = React.useState(
    []
  );

  const [notesPoster, setNotesPoster] = React.useState([]);
  const [newNotesPoster, setNewNotesPoster] = React.useState([]);
  const [openNotesPoster, setOpenNotesPoster] = React.useState(false);
  const [notesDescription, setNotesDescription] = React.useState("");
  const [notesFiles, setNotesFiles] = React.useState([]);
  const [newNotesFiles, setNewNotesFiles] = React.useState([]);
  const [openNewNotes, setOpenNewNotes] = React.useState([]);
  const [openNewNotesPosters, setOpenNewNotesPosters] = React.useState([]);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [_errors, setErrors] = React.useState({});

  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSantourHouse());
  }, []);

  useEffect(() => {
    if (auth.santourHouse) {
      const santourHouse = auth.santourHouse;
      auth.santourHouse = null;

      setSahami(santourHouse.sahami);
      setStudents(santourHouse.topStudents);
      setNotes(santourHouse.notes);
      setLoading(false);
    }

    if (auth.updateSantourHouse) {
      auth.updateSantourHouse = false;
      setNotify({
        isOpen: true,
        message: "اطلاعات  با موفقیت ذخیره شد",
        type: "success",
      });
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      setErrors(errors.errors);
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);

      let resetTimeout = setTimeout(() => {
        clearTimeout(resetTimeout);
        setErrors({});
      }, 3000);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    if (validate()) {
      setConfirmDialog(data);
    }
  };

  const validate = () => {
    let temp = { ..._errors };
    temp.sahamiPoster =
      !sahamiPoster.length > 0 && !newSahamiPoster.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک پوستر برای استاد سهامی انتخاب کنید",
            type: "error",
          })
        : "";
    temp.sahamiDescription = sahamiDescription
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن توضیحات آثار استاد سهامی را وارد کنید",
          type: "error",
        });
    temp.studentPoster =
      !studentPoster.length > 0 && !newStudentsPoster.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک پوستر برای هنرجویان برتر انتخاب کنید",
            type: "error",
          })
        : "";
    temp.studentsDescription = studentsDescription
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن توضیحات آثار هنرجویان را وارد کنید",
          type: "error",
        });
    temp.notesPoster =
      !notesPoster.length > 0 && !newNotesPoster.length > 0
        ? setNotify({
            isOpen: true,
            message: "لطفا یک پوستر برای نت‌ها انتخاب کنید",
            type: "error",
          })
        : "";
    temp.notesDescription = notesDescription
      ? ""
      : setNotify({
          isOpen: true,
          message: "لطفا متن توضیح نت‌ها را وارد کنید",
          type: "error",
        });

    temp.sahamiValidation = validateSahami();
    temp.studentsValidation = validateStudents();
    temp.notesValidation = validateNotes();
    setErrors({ ...temp });
    const errors = Object.values(temp).filter((x) => x !== "");
    if (errors.length === 0) {
      return true;
    }
    return false;
  };

  const validateSahami = () => {
    if (newSahamiMusics.length > 0) {
      for (let i = 0; i < newSahamiMusics.length; i++) {
        if (
          newSahamiMusics[i].file.length === 0 ||
          newSahamiMusics[i].artist.length === 0 ||
          newSahamiMusics[i].name.length === 0 ||
          newSahamiMusics[i].poster.length === 0
        ) {
          setNotify({
            isOpen: true,
            message:
              "لطفا تمامی فیلدهای آثار صوتی جدید استاد سهامی را وارد کنید",
            type: "error",
          });
          return "error";
        }
      }
      for (let i = 0; i < sahamiVideoClips.length; i++) {
        if (sahamiVideoClips[i].length === 0) {
          setNotify({
            isOpen: true,
            message: "لطفا آدرس تمامی آثار ویدوئی استاد سهامی را وارد کنید",
            type: "error",
          });
          return "error";
        }
      }
    }
    return "";
  };

  const validateStudents = () => {
    if (newStudentsMusics.length > 0) {
      for (let i = 0; i < newStudentsMusics.length; i++) {
        if (
          newStudentsMusics[i].file.length === 0 ||
          newStudentsMusics[i].artist.length === 0 ||
          newStudentsMusics[i].name.length === 0 ||
          newStudentsMusics[i].poster.length === 0
        ) {
          setNotify({
            isOpen: true,
            message: "لطفا تمامی فیلدهای آثار صوتی جدید هنرجویان را وارد کنید",
            type: "error",
          });
          return "error";
        }
      }
      for (let i = 0; i < studentsVideoClips.length; i++) {
        if (studentsVideoClips[i].length === 0) {
          setNotify({
            isOpen: true,
            message: "لطفا آدرس تمامی آثار ویدوئی هنرجویان را وارد کنید",
            type: "error",
          });
          return "error";
        }
      }
    }
    return "";
  };

  const validateNotes = () => {
    if (newNotesFiles.length > 0) {
      for (let i = 0; i < newNotesFiles.length; i++) {
        if (
          newNotesFiles[i].file.length === 0 ||
          newNotesFiles[i].artist.length === 0 ||
          newNotesFiles[i].name.length === 0 ||
          newNotesFiles[i].poster.length === 0
        ) {
          setNotify({
            isOpen: true,
            message: "لطفا تمامی فیلدهای نوت‌های جدید را وارد کنید",
            type: "error",
          });
          return "error";
        }
      }
    }
    return "";
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();
    //const f = new FormData();)
    formData.append("sahamiDescription", sahamiDescription);
    formData.append(
      "sahamiNewPoster",
      newSahamiPoster.length > 0 ? JSON.stringify(newSahamiPoster[0]) : "empty"
    );
    formData.append(
      "sahamiCurrentPoster",
      sahamiPoster.length > 0 ? JSON.stringify(sahamiPoster[0]) : "empty"
    );
    formData.append(
      "sahamiVideoClips",
      sahamiVideoClips ? JSON.stringify(sahamiVideoClips) : "empty"
    );
    formData.append(
      "sahamiCurrentMusics",
      sahamiMusics.length > 0 ? JSON.stringify(sahamiMusics) : "empty"
    );
    formData.append(
      "sahamiNewMusics",
      newSahamiMusics.length > 0 ? JSON.stringify(newSahamiMusics) : "empty"
    );

    //
    formData.append("studentsDescription", studentsDescription);
    formData.append(
      "studentsNewPoster",
      newStudentsPoster.length > 0
        ? JSON.stringify(newStudentsPoster[0])
        : "empty"
    );
    formData.append(
      "studentsCurrentPoster",
      studentPoster.length > 0 ? JSON.stringify(studentPoster[0]) : "empty"
    );
    formData.append(
      "studentsVideoClips",
      studentsVideoClips ? JSON.stringify(studentsVideoClips) : "empty"
    );
    formData.append(
      "studentsCurrentMusics",
      studentsMusics.length > 0 ? JSON.stringify(studentsMusics) : "empty"
    );
    formData.append(
      "studentsNewMusics",
      newStudentsMusics.length > 0 ? JSON.stringify(newStudentsMusics) : "empty"
    );
    //
    formData.append("notesDescription", notesDescription);
    formData.append(
      "notesNewPoster",
      newNotesPoster.length > 0 ? JSON.stringify(newNotesPoster[0]) : "empty"
    );
    formData.append(
      "notesCurrentPoster",
      notesPoster.length > 0 ? JSON.stringify(notesPoster[0]) : "empty"
    );
    formData.append(
      "notesCurrentFiles",
      notesFiles.length > 0 ? JSON.stringify(notesFiles) : "empty"
    );
    formData.append(
      "notesNewFiles",
      newNotesFiles.length > 0 ? JSON.stringify(newNotesFiles) : "empty"
    );
    dispatch(postSantourHouse(formData));
    setLoading(true);
  };

  const setSahami = (sahami) => {
    let poster = [];
    poster.push(sahami.poster);
    if (sahami) {
      sahami.poster ? setSahamiPoster(poster) : setSahamiPoster([]);
      setSahamiDescription(sahami.description);

      var videoClips = [];
      var musics = [];
      for (let i = 0; i < sahami.videoClips.length; i++) {
        videoClips.push(sahami.videoClips[i]);
      }
      if (videoClips.length !== 0) setSahamiVideoClips(videoClips);

      for (let i = 0; i < sahami.musics.length; i++) {
        musics.push(sahami.musics[i]);
      }
      if (musics.length !== 0) setSahamiMusics(musics);
    }
  };

  const setStudents = (students) => {
    let poster = [];
    poster.push(students.poster);
    if (students) {
      students.poster ? setStudentsPoster(poster) : setStudentsPoster([]);
      setStudentDescription(students.description);
      var videoClips = [];
      var musics = [];
      for (let i = 0; i < students.videoClips.length; i++) {
        videoClips.push(students.videoClips[i]);
      }
      if (videoClips.length !== 0) setStudentsVideoClips(videoClips);

      for (let i = 0; i < students.musics.length; i++) {
        musics.push(students.musics[i]);
      }
      if (musics.length !== 0) setStudentsMusics(musics);
    }
  };

  const setNotes = (notes) => {
    let poster = [];
    poster.push(notes.poster);
    if (notes) {
      notes.poster ? setNotesPoster(poster) : setNotesPoster([]);
      setNotesDescription(notes.description);
      var files = [];
      for (let i = 0; i < notes.files.length; i++) {
        files.push(notes.files[i]);
      }
      if (files.length !== 0) setNotesFiles(files);
    }
  };

  const videosHandleRemoveClick = (index, target) => {
    if (target === "sahami") {
      if (sahamiVideoClips.length > 0) {
        const list = [...sahamiVideoClips];
        list.splice(index, 1);
        setSahamiVideoClips(list);
      }
    } else if (target === "students") {
      if (studentsVideoClips.length > 0) {
        const list = [...studentsVideoClips];
        list.splice(index, 1);
        setStudentsVideoClips(list);
      }
    } else {
      if (notesFiles.length > 0) {
        const list = [...notesFiles];
        list.splice(index, 1);
        setNotesFiles(list);
      }
    }
  };

  const musicHandleRemoveClick = (index, target) => {
    if (target === "sahami") {
      if (sahamiMusics.length > 0) {
        const list = [...sahamiMusics];
        list.splice(index, 1);
        setSahamiMusics(list);
      }
    } else {
      if (studentsMusics.length > 0) {
        const list = [...studentsMusics];
        list.splice(index, 1);
        setStudentsMusics(list);
      }
    }
  };

  const videosHandleAddClick = (target) => {
    if (target === "sahami") {
      setSahamiVideoClips([
        ...sahamiVideoClips,
        { address: "", artist: "", name: "" },
      ]);
    } else {
      setStudentsVideoClips([
        ...studentsVideoClips,
        { address: "", artist: "", name: "" },
      ]);
    }
  };

  const musicHandleAddClick = (target) => {
    if (target === "sahami") {
      setNewSahamiMusics([
        ...newSahamiMusics,
        { file: [], artist: "", name: "", poster: [] },
      ]);
    } else if (target === "students") {
      setNewStudentsMusics([
        ...newStudentsMusics,
        { file: [], artist: "", name: "", poster: [] },
      ]);
    } else {
      setNewNotesFiles([
        ...newNotesFiles,
        { file: [], poster: [], artist: "", name: "" },
      ]);
    }
  };

  const handleSahamiOpenNewMusics = (i, type) => {
    if (type === "poster") {
      const openMusics = [...openSahamiMusicsPoster];
      openMusics[i] = !openMusics[i];
      return setOpenSahamiMusicsPoster(openMusics);
    }
    const openMusics = [...openSahamiMusics];
    openMusics[i] = !openMusics[i];
    setOpenSahamiMusics(openMusics);
  };

  const handleStudentsOpenNewMusics = (i, type) => {
    if (type === "poster") {
      const openMusics = [...openStudentsMusicsPoster];
      openMusics[i] = !openMusics[i];
      return setOpenStudentMusicsPoster(openMusics);
    }

    const openMusics = [...openStudentsMusics];
    openMusics[i] = !openMusics[i];
    setOpenStudentMusics(openMusics);
  };

  const handleNotesOpenNewFiles = (i, type) => {
    if (type === "poster") {
      const openFiles = [...openNewNotesPosters];
      openFiles[i] = !openFiles[i];
      return setOpenNewNotesPosters(openFiles);
    }

    const openFiles = [...openNewNotes];
    openFiles[i] = !openFiles[i];
    setOpenNewNotes(openFiles);
  };

  const classes = useStyles();

  const sahami = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ backgroundColor: "#f0f8ff" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "30px",
              }}
            >
              آثار استاد سهامی
            </h3>
          </Grid>
          {sahamiPoster.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(sahamiPoster[0].address);
                      setOpen(true);
                    }}
                    src={sahamiPoster[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setSahamiPoster([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
            <Controls.UploadImage
              text="انتخاب پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر جدید"
              dialogTitle="آپلود پوستر جدید"
              value={newSahamiPoster}
              acceptedFiles={["image/*"]}
              fileObjects={newSahamiPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openSahamiPoster}
              onClose={() => setOpenSahamiPoster(!openSahamiPoster)}
              onSave={() => setOpenSahamiPoster(!openSahamiPoster)}
              onClick={() => setOpenSahamiPoster(!openSahamiPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewSahamiPoster(newSahamiPoster.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newSahamiPoster.indexOf(deleteFileObj);
                setNewSahamiPoster(
                  newSahamiPoster.filter(
                    (pic) => newSahamiPoster.indexOf(pic) !== index
                  )
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "32px", marginTop: "32px" }}
          >
            <Editor
              value={sahamiDescription}
              onEditorChange={(e) => setSahamiDescription(e)}
              placeholder="توضیحات مربوط به آثار استاد سهامی"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={sahamiVideoCollapse}
                  onChange={() => setsahamiVideoCollapse(!sahamiVideoCollapse)}
                />
              }
              label="نمایش آثار ویدئویی"
            />
            <Collapse in={sahamiVideoCollapse}>
              <Grid item xs={12} sm={12} md={12}>
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "64px",
                    marginTop: "32px",
                    fontSize: "15px",
                  }}
                >
                  آثار ویدئویی استاد سهامی
                </h3>
              </Grid>
              {sahamiVideoClips.length !== 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  {sahamiVideoClips.map((x, i) => (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <Controls.Input
                        variant="outlined"
                        name="name"
                        label="نام اثر"
                        multiline
                        value={sahamiVideoClips[i].name}
                        onChange={(e) => {
                          const videos = [...sahamiVideoClips];
                          videos[i].name = e.target.value;
                          setSahamiVideoClips(videos);
                        }}
                        error={_errors.videoClips}
                      />
                      <Controls.Input
                        variant="outlined"
                        name="artist"
                        label="نام هنرمند اثر"
                        multiline
                        value={sahamiVideoClips[i].artist}
                        onChange={(e) => {
                          const videos = [...sahamiVideoClips];
                          videos[i].artist = e.target.value;
                          setSahamiVideoClips(videos);
                        }}
                        error={_errors.videoClips}
                      />
                      <Controls.Input
                        variant="outlined"
                        name="address"
                        label="آدرس اثر"
                        multiline
                        value={sahamiVideoClips[i].address}
                        onChange={(e) => {
                          const videos = [...sahamiVideoClips];
                          videos[i].address = e.target.value;
                          setSahamiVideoClips(videos);
                        }}
                        error={_errors.videoClips}
                      />
                      <Controls.Button
                        text="حذف"
                        color="secondary"
                        onClick={() => videosHandleRemoveClick(i, "sahami")}
                      />
                      <hr
                        style={{
                          color: "black",
                          width: "100%",
                          height: "2px",
                          backgroundColor: "black",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid item xs={12} md={10} style={{ marginBottom: "64px" }}>
                <Tooltip
                  title="اضافه کردن یک اثر ویدئویی "
                  aria-label="add"
                  onClick={() => {
                    videosHandleAddClick("sahami");
                  }}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Collapse>
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sahamiMusicCollapse}
                    onChange={() =>
                      setsahamiMusicCollapse(!sahamiMusicCollapse)
                    }
                  />
                }
                label="نمایش آثار صوتی"
              />
            </Grid>

            <MyCollapse in={sahamiMusicCollapse} style={{ width: "100%" }}>
              <Grid item xs={12} sm={12} md={12}>
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "64px",
                    marginTop: "32px",
                    fontSize: "15px",
                  }}
                >
                  آثار صوتی استاد سهامی
                </h3>
              </Grid>
              {sahamiMusics.length !== 0 &&
                sahamiMusics.map((x, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginBottom: "4px" }}
                    key={i}
                  >
                    <MaterialCard>
                      <CardHeader>
                        <h3>{x.name}</h3>
                      </CardHeader>
                      <CardMedia>
                        <Image src={x.poster.address} />
                      </CardMedia>
                      <CardContent>
                        <ReactAudioPlayer
                          src={x.address}
                          controls
                          style={{ width: "100%" }}
                        />
                        <Controls.ActionButton
                          onClick={() => musicHandleRemoveClick(i, "sahami")}
                        >
                          <DeleteIcon color="secondary" />
                        </Controls.ActionButton>
                      </CardContent>
                    </MaterialCard>
                  </Grid>
                ))}
              {newSahamiMusics.length !== 0 &&
                newSahamiMusics.map((x, i) => (
                  <Grid item xs={12} sm={12} md={12} key={i}>
                    <Controls.Input
                      variant="outlined"
                      name="name"
                      label="نام اثر"
                      multiline
                      value={newSahamiMusics[i].name}
                      onChange={(e) => {
                        const musics = [...newSahamiMusics];
                        musics[i].name = e.target.value;
                        setNewSahamiMusics(musics);
                      }}
                      error={_errors.musics}
                    />
                    <Controls.Input
                      variant="outlined"
                      name="artist"
                      label="نام هنرمند اثر"
                      multiline
                      value={newSahamiMusics[i].artist}
                      onChange={(e) => {
                        const musics = [...newSahamiMusics];
                        musics[i].artist = e.target.value;
                        setNewSahamiMusics(musics);
                      }}
                      error={_errors.musics}
                    />
                    <Controls.UploadImage
                      text="انتخاب اثر صوتی جدید"
                      name="uploadAudio"
                      label="آپلود اثر صوتی جدید"
                      dialogTitle="آپلود اثر صوتی جدید"
                      value={newSahamiMusics[i].file}
                      acceptedFiles={["audio/*"]}
                      fileObjects={newSahamiMusics[i].file}
                      cancelButtonText="انصراف"
                      submitButtonText="تایید"
                      maxFileSize={50000000}
                      open={openSahamiMusics[i]}
                      onClose={() => handleSahamiOpenNewMusics(i, "file")}
                      onSave={() => handleSahamiOpenNewMusics(i, "file")}
                      onClick={() => handleSahamiOpenNewMusics(i, "file")}
                      startIcon={<Controls.Icons.CloudUploadIcon />}
                      onAdd={(newFileObjs) => {
                        var list = [...newSahamiMusics];
                        list[i].file.push(newFileObjs[0]);
                        setNewSahamiMusics(list);
                      }}
                      onDelete={(deleteFileObj) => {
                        const index =
                          newSahamiMusics[i].file.indexOf(deleteFileObj);
                        var list = [...newSahamiMusics];
                        list[i].file = list[i].file.filter(
                          (pic) => list[i].file.indexOf(pic) !== index
                        );
                        setNewSahamiMusics(list);
                      }}
                      filesLimit={1}
                      showPreviews={true}
                      showFileNamesInPreview={true}
                      clearOnUnmount={false}
                      //error={errors.pictures}
                    />
                    <Controls.UploadImage
                      text="انتخاب پوستر اثر صوتی جدید"
                      name="uploadImage"
                      label="آپلود پوستر اثر صوتی جدید"
                      dialogTitle="آپلود پوستر اثر صوتی جدید"
                      value={newSahamiMusics[i].poster}
                      acceptedFiles={["image/*"]}
                      fileObjects={newSahamiMusics[i].poster}
                      cancelButtonText="انصراف"
                      submitButtonText="تایید"
                      maxFileSize={50000000}
                      open={openSahamiMusicsPoster[i]}
                      onClose={() => handleSahamiOpenNewMusics(i, "poster")}
                      onSave={() => handleSahamiOpenNewMusics(i, "poster")}
                      onClick={() => handleSahamiOpenNewMusics(i, "poster")}
                      startIcon={<Controls.Icons.CloudUploadIcon />}
                      onAdd={(newFileObjs) => {
                        var list = [...newSahamiMusics];
                        list[i].poster.push(newFileObjs[0]);
                        setNewSahamiMusics(list);
                      }}
                      onDelete={(deleteFileObj) => {
                        const index =
                          newSahamiMusics[i].poster.indexOf(deleteFileObj);
                        var list = [...newSahamiMusics];
                        list[i].poster = list[i].poster.filter(
                          (pic) => list[i].poster.indexOf(pic) !== index
                        );
                        setNewSahamiMusics(list);
                      }}
                      filesLimit={1}
                      showPreviews={true}
                      showFileNamesInPreview={true}
                      clearOnUnmount={false}
                      //error={errors.pictures}
                    />
                    <Controls.ActionButton
                      onClick={() => {
                        const list = [...newSahamiMusics];
                        list.splice(i, 1);
                        setNewSahamiMusics(list);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Grid>
                ))}
              <Grid item xs={12} md={10} style={{ marginBottom: "64px" }}>
                <Tooltip
                  title="اضافه کردن یک اثر صوتی "
                  aria-label="add"
                  onClick={() => {
                    musicHandleAddClick("sahami");
                  }}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </MyCollapse>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  );

  const _students = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ backgroundColor: "#f0f8ff" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "30px",
              }}
            >
              آثار هنرجویان برتر
            </h3>
          </Grid>
          {studentPoster.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(studentPoster[0].address);
                      setOpen(true);
                    }}
                    src={studentPoster[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setStudentsPoster([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
            <Controls.UploadImage
              text="انتخاب پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر جدید"
              dialogTitle="آپلود پوستر جدید"
              value={newStudentsPoster}
              acceptedFiles={["image/*"]}
              fileObjects={newStudentsPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openStudentsPoster}
              onClose={() => setOpenStudentsPoster(!openStudentsPoster)}
              onSave={() => setOpenStudentsPoster(!openStudentsPoster)}
              onClick={() => setOpenStudentsPoster(!openStudentsPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewStudentsPoster(newStudentsPoster.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newStudentsPoster.indexOf(deleteFileObj);
                setNewStudentsPoster(
                  newStudentsPoster.filter(
                    (pic) => newStudentsPoster.indexOf(pic) !== index
                  )
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          {/* <Controls.Input
          variant="outlined"
          name="studentsDescription"
          label="توضیحات مربوط به آثار هنرجویان برتر"
          multiline
          value={studentsDescription}
          onChange={(e) => {
            setStudentDescription(e.target.value);
          }}
          error={_errors.studentsDescription}
        /> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "32px", marginTop: "32px" }}
          >
            <Editor
              value={studentsDescription}
              onEditorChange={(e) => setStudentDescription(e)}
              placeholder="توضیحات مربوط به آثار هنرجویان برتر"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={studentsVideoCollapse}
                  onChange={() =>
                    setStudentsVideoCollapse(!studentsVideoCollapse)
                  }
                />
              }
              label="نمایش آثار ویدئویی"
            />
            <Collapse in={studentsVideoCollapse}>
              <Grid item xs={12} sm={12} md={12}>
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "64px",
                    marginTop: "32px",
                    fontSize: "15px",
                  }}
                >
                  آثار ویدئویی هنرجویان برتر
                </h3>
              </Grid>
              {studentsVideoClips.length !== 0 &&
                studentsVideoClips.map((x, i) => (
                  <Grid item xs={12} sm={12} md={12} key={i}>
                    <Controls.Input
                      variant="outlined"
                      name="name"
                      label="نام اثر"
                      multiline
                      value={studentsVideoClips[i].name}
                      onChange={(e) => {
                        const videos = [...studentsVideoClips];
                        videos[i].name = e.target.value;
                        setStudentsVideoClips(videos);
                      }}
                      error={_errors.videoClips}
                    />
                    <Controls.Input
                      variant="outlined"
                      name="artist"
                      label="نام هنرمند اثر"
                      multiline
                      value={studentsVideoClips[i].artist}
                      onChange={(e) => {
                        const videos = [...studentsVideoClips];
                        videos[i].artist = e.target.value;
                        setStudentsVideoClips(videos);
                      }}
                      error={_errors.videoClips}
                    />
                    <Controls.Input
                      variant="outlined"
                      name="address"
                      label="آدرس اثر"
                      multiline
                      value={studentsVideoClips[i].address}
                      onChange={(e) => {
                        const videos = [...studentsVideoClips];
                        videos[i].address = e.target.value;
                        setStudentsVideoClips(videos);
                      }}
                      error={_errors.videoClips}
                    />
                    <Controls.Button
                      text="حذف"
                      color="secondary"
                      onClick={() => videosHandleRemoveClick(i, "students")}
                    />
                    <hr
                      style={{
                        color: "black",
                        width: "100%",
                        height: "2px",
                        backgroundColor: "black",
                      }}
                    />
                  </Grid>
                ))}
              <Grid item xs={12} md={10} style={{ marginBottom: "64px" }}>
                <Tooltip
                  title="اضافه کردن یک اثر ویدئویی "
                  aria-label="add"
                  onClick={() => {
                    videosHandleAddClick("students");
                  }}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={studentsMusicCollapse}
                  onChange={() =>
                    setStudentsMusicCollapse(!studentsMusicCollapse)
                  }
                />
              }
              label="نمایش آثار صوتی"
            />
            <MyCollapse in={studentsMusicCollapse}>
              <Grid item xs={12} sm={12} md={12}>
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "64px",
                    marginTop: "32px",
                    fontSize: "15px",
                  }}
                >
                  آثار صوتی هنرجویان برتر
                </h3>
              </Grid>
              {studentsMusics.length !== 0 &&
                studentsMusics.map((x, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginBottom: "4px" }}
                    key={i}
                  >
                    <MaterialCard style={{ width: "250px" }}>
                      <CardHeader>
                        <h3>{x.name}</h3>
                      </CardHeader>
                      <CardMedia>
                        <Image src={x.poster.address} />
                      </CardMedia>
                      <CardContent>
                        <ReactAudioPlayer
                          src={x.address}
                          controls
                          style={{ width: "100%" }}
                        />
                        <Controls.ActionButton
                          onClick={() => musicHandleRemoveClick(i, "students")}
                        >
                          <DeleteIcon color="secondary" />
                        </Controls.ActionButton>
                      </CardContent>
                    </MaterialCard>
                  </Grid>
                ))}
              {newStudentsMusics.length !== 0 &&
                newStudentsMusics.map((x, i) => (
                  <Grid item xs={12} sm={12} md={12} key={i}>
                    <Controls.Input
                      variant="outlined"
                      name="name"
                      label="نام اثر"
                      multiline
                      value={newStudentsMusics[i].name}
                      onChange={(e) => {
                        const musics = [...newStudentsMusics];
                        musics[i].name = e.target.value;
                        setNewStudentsMusics(musics);
                      }}
                      error={_errors.musics}
                    />
                    <Controls.Input
                      variant="outlined"
                      name="artist"
                      label="نام هنرمند اثر"
                      multiline
                      value={newStudentsMusics[i].artist}
                      onChange={(e) => {
                        const musics = [...newStudentsMusics];
                        musics[i].artist = e.target.value;
                        setNewStudentsMusics(musics);
                      }}
                      error={_errors.musics}
                    />
                    <Controls.UploadImage
                      text="انتخاب اثر صوتی جدید"
                      name="uploadImage"
                      label="آپلود اثر صوتی جدید"
                      dialogTitle="آپلود اثر صوتی جدید"
                      value={newStudentsMusics[i].file}
                      acceptedFiles={["audio/*"]}
                      fileObjects={newStudentsMusics[i].file}
                      cancelButtonText="انصراف"
                      submitButtonText="تایید"
                      maxFileSize={50000000}
                      open={openStudentsMusics[i]}
                      onClose={() => handleStudentsOpenNewMusics(i, "file")}
                      onSave={() => handleStudentsOpenNewMusics(i, "file")}
                      onClick={() => handleStudentsOpenNewMusics(i, "file")}
                      startIcon={<Controls.Icons.CloudUploadIcon />}
                      onAdd={(newFileObjs) => {
                        var list = [...newStudentsMusics];
                        list[i].file.push(newFileObjs[0]);
                        setNewStudentsMusics(list);
                      }}
                      onDelete={(deleteFileObj) => {
                        const index =
                          newStudentsMusics[i].file.indexOf(deleteFileObj);
                        var list = [...newStudentsMusics];
                        list[i].file = list[i].file.filter(
                          (pic) => list[i].file.indexOf(pic) !== index
                        );
                        setNewStudentsMusics(list);
                      }}
                      filesLimit={1}
                      showPreviews={true}
                      showFileNamesInPreview={true}
                      clearOnUnmount={false}
                      //error={errors.pictures}
                    />
                    <Controls.UploadImage
                      text="انتخاب پوستر اثر صوتی جدید"
                      name="uploadImage"
                      label="آپلود پوستر اثر صوتی جدید"
                      dialogTitle="آپلود پوستر اثر صوتی جدید"
                      value={newStudentsMusics[i].poster}
                      acceptedFiles={["image/*"]}
                      fileObjects={newStudentsMusics[i].poster}
                      cancelButtonText="انصراف"
                      submitButtonText="تایید"
                      maxFileSize={50000000}
                      open={openStudentsMusicsPoster[i]}
                      onClose={() => handleStudentsOpenNewMusics(i, "poster")}
                      onSave={() => handleStudentsOpenNewMusics(i, "poster")}
                      onClick={() => handleStudentsOpenNewMusics(i, "poster")}
                      startIcon={<Controls.Icons.CloudUploadIcon />}
                      onAdd={(newFileObjs) => {
                        var list = [...newStudentsMusics];
                        list[i].poster.push(newFileObjs[0]);
                        setNewStudentsMusics(list);
                      }}
                      onDelete={(deleteFileObj) => {
                        const index =
                          newStudentsMusics[i].poster.indexOf(deleteFileObj);
                        var list = [...newStudentsMusics];
                        list[i].poster = list[i].poster.filter(
                          (pic) => list[i].poster.indexOf(pic) !== index
                        );
                        setNewStudentsMusics(list);
                      }}
                      filesLimit={1}
                      showPreviews={true}
                      showFileNamesInPreview={true}
                      clearOnUnmount={false}
                      //error={errors.pictures}
                    />
                    <Controls.ActionButton
                      onClick={() => {
                        const list = [...newStudentsMusics];
                        list.splice(i, 1);
                        setNewStudentsMusics(list);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Grid>
                ))}
              <Grid item xs={12} md={10} style={{ marginBottom: "64px" }}>
                <Tooltip
                  title="اضافه کردن یک اثر صوتی "
                  aria-label="add"
                  onClick={() => {
                    musicHandleAddClick("students");
                  }}
                  placement="top"
                >
                  <Fab color="primary">
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </MyCollapse>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  );

  const _notes = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ backgroundColor: "#f0f8ff" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "30px",
              }}
            >
              نت‌ها
            </h3>
          </Grid>
          {notesPoster.length !== 0 && (
            <>
              <Grid item xs={4} sm={4} md={4} />
              <Grid item xs={4} sm={4} md={4}>
                <Card>
                  <Image
                    onClick={() => {
                      setAddress(notesPoster[0].address);
                      setOpen(true);
                    }}
                    src={notesPoster[0].address}
                  />
                  <Controls.ActionButton
                    onClick={() => {
                      setNotesPoster([]);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "32px" }}>
            <Controls.UploadImage
              text="انتخاب پوستر جدید"
              name="uploadImage"
              label="آپلود پوستر جدید"
              dialogTitle="آپلود پوستر جدید"
              value={newNotesPoster}
              acceptedFiles={["image/*"]}
              fileObjects={newNotesPoster}
              cancelButtonText="انصراف"
              submitButtonText="تایید"
              maxFileSize={50000000}
              open={openNotesPoster}
              onClose={() => setOpenNotesPoster(!openNotesPoster)}
              onSave={() => setOpenNotesPoster(!openNotesPoster)}
              onClick={() => setOpenNotesPoster(!openNotesPoster)}
              startIcon={<Controls.Icons.CloudUploadIcon />}
              onAdd={(newFileObjs) => {
                setNewNotesPoster(newNotesPoster.concat(newFileObjs));
              }}
              onDelete={(deleteFileObj) => {
                const index = newNotesPoster.indexOf(deleteFileObj);
                setNewNotesPoster(
                  newNotesPoster.filter(
                    (pic) => newNotesPoster.indexOf(pic) !== index
                  )
                );
              }}
              filesLimit={1}
              showPreviews={true}
              showFileNamesInPreview={true}
              clearOnUnmount={false}
              //error={errors.pictures}
            />
          </Grid>
          {/* <Controls.Input
          variant="outlined"
          name="notesDescription"
          label=
          multiline
          value={notesDescription}
          onChange={(e) => {
            setNotesDescription(e.target.value);
          }}
          error={_errors.notesDescription}
        /> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginBottom: "32px", marginTop: "32px" }}
          >
            <Editor
              value={notesDescription}
              onEditorChange={(e) => setNotesDescription(e)}
              placeholder="توضیحات مربوط به نت ها"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                marginTop: "32px",
                fontSize: "15px",
              }}
            >
              نت ها
            </h3>
          </Grid>
          {notesFiles.length !== 0 &&
            notesFiles.map((x, i) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={2}
                style={{ marginBottom: "4px" }}
                key={i}
              >
                <MaterialCard>
                  <CardMedia>
                    <Image src={x.poster.address} />
                  </CardMedia>
                  <CardHeader>
                    <h3>{x.name}</h3>
                  </CardHeader>
                  <Controls.ActionButton
                    onClick={() => videosHandleRemoveClick(i, "other")}
                  >
                    <DeleteIcon color="secondary" />
                  </Controls.ActionButton>
                </MaterialCard>
              </Grid>
            ))}

          {newNotesFiles.length !== 0 &&
            newNotesFiles.map((x, i) => (
              <Grid item xs={12} sm={12} md={12} key={i}>
                <Controls.Input
                  variant="outlined"
                  name="name"
                  label="نام نوت"
                  multiline
                  value={newNotesFiles[i].name}
                  onChange={(e) => {
                    const files = [...newNotesFiles];
                    files[i].name = e.target.value;
                    setNewNotesFiles(files);
                  }}
                  error={_errors.files}
                />
                <Controls.Input
                  variant="outlined"
                  name="artist"
                  label="نام هنرمند اثر"
                  multiline
                  value={newNotesFiles[i].artist}
                  onChange={(e) => {
                    const files = [...newNotesFiles];
                    files[i].artist = e.target.value;
                    setNewNotesFiles(files);
                  }}
                  error={_errors.files}
                />
                <Controls.UploadImage
                  text="انتخاب نوت جدید"
                  name="uploadImage"
                  label="آپلود نوت جدید"
                  dialogTitle="آپلود نوت جدید"
                  value={newNotesFiles[i].file}
                  acceptedFiles={[".pdf"]}
                  fileObjects={newNotesFiles[i].file}
                  cancelButtonText="انصراف"
                  submitButtonText="تایید"
                  maxFileSize={50000000}
                  open={openNewNotes[i]}
                  onClose={() => handleNotesOpenNewFiles(i)}
                  onSave={() => handleNotesOpenNewFiles(i)}
                  onClick={() => handleNotesOpenNewFiles(i)}
                  startIcon={<Controls.Icons.CloudUploadIcon />}
                  onAdd={(newFileObjs) => {
                    var list = [...newNotesFiles];
                    list[i].file.push(newFileObjs[0]);
                    setNewNotesFiles(list);
                  }}
                  onDelete={(deleteFileObj) => {
                    const index = newNotesFiles[i].file.indexOf(deleteFileObj);
                    var list = [...newNotesFiles];
                    list[i].file = list[i].file.filter(
                      (pic) => list[i].file.indexOf(pic) !== index
                    );
                    setNewNotesFiles(list);
                  }}
                  filesLimit={1}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                  clearOnUnmount={false}
                  //error={errors.pictures}
                />
                <Controls.UploadImage
                  text="انتخاب پوستر نوت جدید"
                  name="uploadImage"
                  label="آپلود پوستر نوت جدید"
                  dialogTitle="آپلود پوستر نوت جدید"
                  value={newNotesFiles[i].poster}
                  acceptedFiles={["image/*"]}
                  fileObjects={newNotesFiles[i].poster}
                  cancelButtonText="انصراف"
                  submitButtonText="تایید"
                  maxFileSize={50000000}
                  open={openNewNotesPosters[i]}
                  onClose={() => handleNotesOpenNewFiles(i, "poster")}
                  onSave={() => handleNotesOpenNewFiles(i, "poster")}
                  onClick={() => handleNotesOpenNewFiles(i, "poster")}
                  startIcon={<Controls.Icons.CloudUploadIcon />}
                  onAdd={(newFileObjs) => {
                    var list = [...newNotesFiles];
                    list[i].poster.push(newFileObjs[0]);
                    setNewNotesFiles(list);
                  }}
                  onDelete={(deleteFileObj) => {
                    const index =
                      newNotesFiles[i].poster.indexOf(deleteFileObj);
                    var list = [...newNotesFiles];
                    list[i].poster = list[i].poster.filter(
                      (pic) => list[i].poster.indexOf(pic) !== index
                    );
                    setNewNotesFiles(list);
                  }}
                  filesLimit={1}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                  clearOnUnmount={false}
                  //error={errors.pictures}
                />
                <Controls.ActionButton
                  onClick={() => {
                    const list = [...newNotesFiles];
                    list.splice(i, 1);
                    setNewNotesFiles(list);
                  }}
                >
                  <DeleteIcon color="secondary" />
                </Controls.ActionButton>
              </Grid>
            ))}
          <Grid
            item
            xs={12}
            md={10}
            style={{ marginBottom: "64px", marginTop: "32px" }}
          >
            <Tooltip
              title="اضافه کردن یک نوت جدید "
              aria-label="add"
              onClick={() => {
                musicHandleAddClick("other");
              }}
              placement="top"
            >
              <Fab color="primary">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: "128px" }}>
          <Controls.Button
            onClick={(e) =>
              setConfirmDialogue({
                isOpen: true,
                title: "اطلاعات ذخیره شود؟",
                subTitle: "",
              })
            }
            text="ذخیره اطلاعات"
            color="primary"
            startIcon={<Controls.Icons.SaveIcon />}
          />
        </Grid>
      </Form>
    </Paper>
  );

  const content = (
    <>
      {sahami}
      {_students}
      {_notes}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}
