import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "../../../../components/Main Website/Grid/GridContainer.js";
import GridItem from "../../../../components/Main Website/Grid/GridItem.js";
import Carousel from "../../../../components/Main Website/Carousel/Carousel";
import Section from "../../../../components/Main Website/Section/Section";
import styles from "../../../../assets/jss/views/landingPageSections/teamStyle";

const useStyles = makeStyles(styles);

function All(props) {
  const { news, festival, workshop, azmoon } = props;
  const classes = useStyles();

  return (
    <div className={classes.section}>
      {news && festival && workshop && azmoon && (
        <>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
              <h2 className={classes.title}>آخرین مطالب</h2>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
                <Carousel arrows={true}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                    data-aos="fade-left"
                  >
                    <Section
                      title={`${azmoon.title}`}
                      link={`/آزمون/ساز/${azmoon._id}`}
                      description=""
                      src={azmoon.poster.address}
                      onClick={() => {}}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                    data-aos="fade-left"
                  >
                    <Section
                      title={`${festival.title}`}
                      link={`/فستیوال/${festival._id}`}
                      description={festival.description.substring(
                        0,
                        festival.description.indexOf(".") + 1
                      )}
                      src={festival.poster.address}
                      onClick={() => {}}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                    data-aos="fade-left"
                  >
                    <Section
                      title={`${workshop.title}`}
                      link={`/کارگاه/${workshop._id}`}
                      description={workshop.description.substring(
                        0,
                        workshop.description.indexOf(".") + 1
                      )}
                      src={workshop.poster.address}
                      onClick={() => {}}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                    data-aos="fade-left"
                  >
                    <Section
                      title={`${news.title}`}
                      link={`/اخبار/${news._id}`}
                      description={news.description.substring(
                        0,
                        news.description.indexOf(".") + 1
                      )}
                      src={news.picture.address}
                      onClick={() => {}}
                    />
                  </GridItem>
                </Carousel>
              </GridItem>
            </GridContainer>
          </div>
        </>
      )}
    </div>
  );
}

export default All;
