import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "../../../components/dashboard related/useForms";
import Controls from "../../../components/dashboard related/controls/Controls";
import Card from "../../../components/Main Website/Card/Card";
import Loading from "../../../components/common/Loading";
import Notification from "../../../components/common/Notification";
import ConfirmDialogue from "../../../components/common/ConfirmDialogue";
import { Lightbox } from "react-modal-image";
import Image from "material-ui-image";
import {
  deletePicture,
  getAllPicturesDashboard,
} from "../../../actions/adminActions";
const theme = createMuiTheme({});

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  section: {
    marginBottom: "64px",
  },
});

function GalleryEdit() {
  const [address, setAddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [_id, setId] = React.useState("");
  const [pictures, setPictures] = React.useState([]);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPicturesDashboard());
  }, []);

  useEffect(() => {
    if (auth.deletePicture) {
      auth.deletePicture = false;
      let _pictures = pictures.filter((pic) => pic._id !== auth._id);
      setPictures(_pictures);
      setNotify({
        isOpen: true,
        message: "تصویر با موفقیت حذف شد",
        type: "success",
      });
      setLoading(false);
    }
    if (auth.pictures.length !== 0) {
      let pictures = auth.pictures.slice();
      setPictures(pictures);
      auth.pictures = [];

      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      let err =
        typeof errors.errors.data.error !== "undefined"
          ? errors.errors.data.error
          : errors.errors.data;
      setNotify({
        isOpen: true,
        message: err,
        type: "error",
      });
      errors.errors = null;
      setLoading(false);
    }
  }, [errors]);

  const setConfirmDialogue = (data) => {
    setConfirmDialog(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const formData = new FormData();
    formData.append("picture_id", _id);
    dispatch(deletePicture(formData));
    setLoading(true);
  };

  const classes = useStyles();

  const content = (
    <Paper className={classes.paper}>
      <Form
        classes={{
          root: {
            "& .MuiFormControl-root": {
              width: "80%",
              margin: theme.spacing(1),
            },
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "64px",
                fontSize: "25px",
              }}
            >
              گالری
            </h3>
          </Grid>
          {pictures.length !== 0 && (
            <>
              {pictures.map((x, i) => (
                <Grid
                  item
                  xs={2}
                  sm={1}
                  md={1}
                  style={{ marginRight: "8px" }}
                  key={i}
                >
                  <Card>
                    <Image
                      onClick={() => {
                        setAddress(x.address);
                        setOpen(true);
                      }}
                      src={x.address}
                    />
                    <Controls.ActionButton
                      onClick={(e) => {
                        setId(x._id);
                        setConfirmDialogue({
                          isOpen: true,
                          title: "آیا تصویر حذف شود؟",
                          subTitle: "",
                        });
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </Controls.ActionButton>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialogue
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialogue}
        onConfirm={onSubmit}
      />
      {open && <Lightbox medium={address} onClose={() => setOpen(false)} />}
    </Paper>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  return (
    <Grid item xs={12} md={8} lg={10}>
      {loading ? _loading : content}
    </Grid>
  );
}

export default GalleryEdit;
