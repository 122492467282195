import moment from "moment-jalaali";

const calculateDate = (createdAt) => {
  let newsDate = {
    year: new Date(createdAt).getFullYear(),
    month: Number(
      createdAt.slice(createdAt.indexOf("-") + 1, createdAt.lastIndexOf("-"))
    ),
    day: new Date(createdAt).getDate(),
  };
  newsDate = `${newsDate.year}/${newsDate.month}/${newsDate.day}`;
  const date = moment(newsDate, "YYYY/M/D").format("jYYYY/jM/jD");
  return date;
};

export default calculateDate;
